import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { billingURL } from '../config';

export const getCurrentSubscription = createAsyncThunk('subscription/getCurrentSubscription', async () => {
  const response = await crudData(billingURL, '/subscriptions', 'GET');
  const { data } = await response.json();
  return data;
});

export const updateSubscription = createAsyncThunk('subscription/updateSubscription', async (updationObject) => {
  const response = await crudData(billingURL, '/subscriptions', 'PUT', {}, {}, { ...updationObject });
  const { data } = await response.json();
  return data;
});

export const downgradeSubscription = createAsyncThunk('subscription/downgradeSubscription', async ({ productId, period }) => {
  const response = await crudData(billingURL, '/subscriptions/downgrade', 'PUT', {}, {}, { productId, period }).then((res) => res.json());
  if (!response.success) {
    return message.error(response.message);
  }
  message.success(response.message);
  return response.data;
});

const initialState = {
  data: {},
  status: requestStatuses.IDLE,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentSubscription.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getCurrentSubscription.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getCurrentSubscription.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(updateSubscription.pending, (state) => {
        state.updateSubscriptionStatus = requestStatuses.LOADING;
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.updateSubscriptionStatus = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(updateSubscription.rejected, (state) => {
        state.updateSubscriptionStatus = requestStatuses.FAILED;
      })
      .addCase(downgradeSubscription.pending, (state) => {
        state.updateSubscriptionStatus = requestStatuses.LOADING;
      })
      .addCase(downgradeSubscription.fulfilled, (state) => {
        state.updateSubscriptionStatus = requestStatuses.SUCCESS;
      })
      .addCase(downgradeSubscription.rejected, (state) => {
        state.updateSubscriptionStatus = requestStatuses.FAILED;
      });
  },
});

export default subscriptionSlice.reducer;
