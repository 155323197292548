import {
  Button, Modal, Rate, message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { baseURL } from '../../config';
import crudData from '../../utils/apiService';

const desc = ['Bad', 'Fine', 'Good', 'Very Good', 'Excellent'];

function FeedbackModal({ visible, setVisible }) {
  const { data: userData } = useSelector((state) => state.user);
  const { company } = userData;
  const [loading] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);

  const handleFeedbackInput = (e) => {
    const newFeedback = e.target.value;
    if (newFeedback.length <= 2000) {
      setFeedback(e.target.value);
    }
  };
  const handleCancel = () => {
    setVisible(false);
    setFeedback('');
    setRating(0);
  };

  const handleSubmit = async () => {
    try {
      const supportReq = await crudData(baseURL, '/feedbacks', 'POST', {}, {}, { message: feedback, rating })
        .then((res) => res.json())
        .catch((err) => message.error(err));
      if (supportReq.success) {
        message.success(supportReq.message);
      } else {
        message.error(supportReq.message);
      }
    } catch (error) {
      message.error('Error submitting support request');
    }
    setVisible(false);
    setFeedback('');
  };

  const handleSendMail = () => {
    setTimeout(() => {
      setVisible(false);
      message.info('Feedback options opened on mailbox');
    }, 1000);
    const epochTime = Date.now();
    const subject = `TelePilot AI Feedback [${company?.id}/${epochTime}]`;
    const mailtoString = `mailto:feedback@telepilot.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(feedback)}`;
    window.location.href = mailtoString;
  };

  return (
    <Modal
      title="Share Feedback"
      centered
      open={visible}
      onCancel={() => {}}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" disabled={feedback.length === 0 && !rating} loading={loading} onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
      closable={false}
      width={900}
    >
      <div className="flex flex-col">
        <span className="my-2 text-gray-600">
          {`Your feedback matters to us. If you have suggestions, concerns, or even compliments, feel free to share them.
          We value your input in making our service even better. You can also provide your experience rating below.`}
        </span>
        <div className="flex flex-col w-full items-center mt-4">
          <p className="font-bold w-full">How would you rate your experience with TelePilot AI?</p>
          <div className="flex items-center w-full h-fit justify-start ">
            <span className="mr-4 flex items-center w-60">
              <Rate tooltips={desc} onChange={setRating} value={rating} />
              {rating ? <span className="ant-rate-text">{desc[rating - 1]}</span> : ''}
            </span>
          </div>
        </div>
        <TextArea
          value={feedback}
          className="my-4"
          onChange={handleFeedbackInput}
          placeholder="I have feedback regarding ... "
          autoSize={{
            minRows: 7,
            maxRows: 7,
          }}
          maxLength={4000}
        />
        <span className="my-2 text-gray-700">
          Prefer Email? Please feel free to drop us a mail at
          {' '}
          <Button className="mx-0 p-0" type="link" onClick={handleSendMail}>
            feedback@telepilot.ai
          </Button>
        </span>
      </div>
    </Modal>
  );
}

export default FeedbackModal;
