import { Button, Result } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CelebrationConfetti from '../common/CelebrationConfetti';

function CheckoutStatusPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate('/', { replace: true });
  };

  const [status, setStatus] = useState('404');
  const [title, setTitle] = useState('404');
  const [subTitle, setSubTitle] = useState('Sorry, the page you visited does not exist.');

  const handlePaymentStatus = () => {
    const success = queryParams.get('success');
    const canceled = queryParams.get('canceled');
    const desc = queryParams.get('desc');
    const u = queryParams.get('u');
    if (success === 'true') {
      setStatus('success');
      if (desc === 'setup') {
        setTitle('Card Details Saved');
        setSubTitle('Payment method updated successfully, Your new card details are saved.');
      } else {
        let planName = desc.split('-')[0];
        planName = planName.charAt(0).toUpperCase() + planName.slice(1);
        setTitle(`Payment Successful for the ${planName} Plan!`);
        setSubTitle(`You now have access to all the features and benefits of ${u || ''} ${planName} Plan.`);
      }
    } else if (canceled === 'true') {
      setStatus('error');
      if (desc === 'setup') {
        setTitle('Card Save Failed');
        setSubTitle('Apologies for the issue. Please ensure your details are correct and try again.');
      } else {
        setTitle('Payment Failed');
        setSubTitle('Payment for your order failed. Please review your details and try again. Any deducted amount will be refunded.');
      }
    }
  };

  useEffect(() => {
    handlePaymentStatus();
  }, []);

  return (
    <div className="mt-32">
      {status === 'success' && <CelebrationConfetti />}
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        extra={[
          <Button type="primary" key="console" onClick={handleContinue}>
            Continue to App
          </Button>,
        ]}
      />
    </div>
  );
}
export default CheckoutStatusPage;
