import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setNav: (state, action) => action.payload,
  },
});

export const { setNav } = navSlice.actions;
export default navSlice.reducer;
