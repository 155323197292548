import {
  Card, Checkbox, Divider, Tag, Typography,
} from 'antd';
import { messageTimeStampFormat } from '../../../utils/dateTimeFormat';

function SelectDocGridView({ docs, selectedDocs, docsSelector }) {
  const { Text } = Typography;

  const onSelectChange = (e, newDocId) => {
    const state = e.target.checked;
    if (state) {
      docsSelector([...selectedDocs, newDocId]);
    } else {
      docsSelector(selectedDocs.filter((docId) => docId !== newDocId));
    }
  };

  return (
    <div className="flex flex-wrap my-2">
      {docs.map((doc) => (
        <Card
          title={(
            <Text
              style={{ maxWidth: 220 }}
              ellipsis={doc.title ? { tooltip: doc.title } : false}
            >
              {doc.title}
            </Text>
  )}
          className="text-start m-1 shadow-lg"
          style={{ width: 300, height: 190 }}
          extra={<Checkbox checked={selectedDocs.includes(doc.id)} onChange={(state) => onSelectChange(state, doc.id)} />}
          loading={!doc}
        >
          <div className="m-0 flex flex-col justify-between h-20">
            <div>
              <span className="text-gray-600">
                {doc.description}
              </span>
            </div>
            <div className="text-xs">
              <div className="mb-4 text-gray-500">
                {doc.source && !doc.source.includes('pdf') ? (
                  <Tag bordered={false} color="cyan">
                    <Text
                      style={{ maxWidth: 350 }}
                      ellipsis={{ tooltip: doc?.source }}
                    >
                      {doc?.source}
                    </Text>
                  </Tag>
                ) : ''}
              </div>
              <div className="flex items-center text-sm mb-2">
                <div>
                  <span className="text-gray-600">Doc Type:</span>
                  <span className="ml-1 font-semibold text-gray-600">{doc.sourceType}</span>
                </div>
                {/* <div>
                <span>Publicly Visible</span>
                <Switch checked={doc.publiclyVisbile} />
              </div> */}
                <Divider type="vertical" />
              </div>
              <div className="flex items-center">
                <div>
                  <span className="text-gray-500">
                    Last Updated:
                  </span>
                  <span className="text-gray-900 ml-1">
                    {messageTimeStampFormat(doc.updatedAt).currentFormat}
                  </span>
                </div>
                <Divider type="vertical" />
                <div>
                  <Tag bordered={false} color={doc.published ? 'green' : 'gold'}>
                    {doc.published ? 'PUBLISHED' : 'NOT PUBLISHED'}
                  </Tag>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}

export default SelectDocGridView;
