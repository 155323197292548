import { CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import {
  Button, Divider, Form, Input, Result, message,
} from 'antd';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL } from '../../config';
import { getUsageCounter } from '../../store/usageCounterSlice';
import { getAllCoPilots } from '../../store/copilotsSlice';

function OnboardingAddWidget({ widgetIntegrated, setWidgetIntegrated }) {
  const dispatch = useDispatch();
  const { allCoPilots: copilots } = useSelector((state) => state.copilots);
  const { data: usageCounter } = useSelector((state) => state.usageCounter);
  const [tag, setTag] = useState();
  const [mailSent, setMailSent] = useState(false);
  const [developerEmail, setDeveloperEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllCoPilots());
    dispatch(getUsageCounter());
  }, []);

  useEffect(() => {
    if (copilots && copilots.length > 0) {
      const companyWidgetTag = `<script type="text/javascript">window.CP_ID="${copilots[0].id}";(function(){d=document;s=d.createElement("script");s.src="//widgets.scripts-tp.com/main.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();</script>`;
      setTag(companyWidgetTag);
    }
  }, [copilots]);

  const verifyInstallation = async () => {
    await dispatch(getUsageCounter());
    if (usageCounter) {
      if (usageCounter.ai_messages > 0) {
        setWidgetIntegrated(true);
      } else {
        message.error('Did not receive a test message from widget yet.');
      }
    }
  };

  useEffect(() => {
    if (usageCounter.ai_messages > 0) {
      setWidgetIntegrated(true);
    }
  }, [usageCounter]);

  const handleCopy = () => {
    navigator.clipboard.writeText(tag)
      .then(() => {
        message.success('Tag copied to clipboard!');
      })
      .catch(() => {
        message.error('Failed to copy tag. Please manually select and copy.');
      });
  };

  const sendDeveloperMail = () => {
    setLoading(true);
    const postData = {
      email: developerEmail,
    };
    fetch(`${baseURL}/accounts/mail-developer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('accessToken')} `,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success('Mail sent successfully!');
          setMailSent(true);
        } else {
          message.error('Failed to send email. Please try again later.');
        }
      })
      .catch(() => {
        // console.error('Error:', error);
      });
  };

  return (
    <div className="relative">
      <div className="flex flex-col w-full my-4 mt-12">
        <span className="text-gray-800 w-full">Copy the following tag and place it on body of each page where you want the chat widget to be available.</span>
        <div className="flex items-center mt-4 w-fit h-fit">
          {tag ? (
            <span className="p-4 border rounded bg-gray-200 overflow-wrap w-full">
              {tag}
            </span>
          ) : (
            <LoadingOutlined
              style={{
                fontSize: 24,
                color: '#1890ff',
              }}
              spin
            />
          )}
          <Button type="primary" className="mx-2" onClick={handleCopy} disabled={!tag}>
            Copy Tag
          </Button>
        </div>
        <div className="flex mt-4 items-center">
          <span className="text-gray-800 font-bold bg-yellow-50 p-2 w-fit">This step will be complete once you send a test message from your chat widget.</span>
          <Button onClick={verifyInstallation}>Verify Installation</Button>
        </div>
        <Divider />
        <div>
          <p className="font-semibold">Send it to developer?</p>
          <p className="text-gray-800 w-full">
            {`Please provide your developer's email and we'll send 
            the above tag with installation instructions to them.`}
          </p>
          <div className="flex items-center">
            <Form onFinish={sendDeveloperMail} className="flex">
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid email',
                    type: 'email',
                  },
                ]}
                className="mr-2 w-96"
              >
                <Input
                  value={developerEmail}
                  onChange={(e) => {
                    setDeveloperEmail(e.target.value);
                    setMailSent(false);
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" disabled={mailSent} loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
          {mailSent && (
          <div className="flex items-center">
            <CheckCircleTwoTone twoToneColor="#006200" />
            <span className="text-xs my-2 mx-1">Mail was sent to above email with integration instructions. Didn&apos;t recieve email?  Check spam or</span>
            <Button type="link" className="text-xs p-0 m-0" onClick={sendDeveloperMail}>Send Again</Button>
          </div>
          )}
        </div>
      </div>
      {widgetIntegrated && (
      <div className="absolute inset-0 flex items-center justify-center z-10 cursor-default bg-white">
        <Result
          status="success"
          title="Widget installed successfully"
          subTitle="You have installed widget to your site and sent a test message successfully."
        />
      </div>
      )}
    </div>
  );
}

export default OnboardingAddWidget;
