import {
  Card, Row, Col, Typography, Button, Popover,
} from 'antd';
import ReactECharts from 'echarts-for-react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
// import GeoChart from '../dashboard/GeoMap';
// import LinkIcon from '../../assets/link.svg';
// import UploadIcon from '../../assets/upload.svg';
// import WriteIcon from '../../assets/write.svg';
import { setNav } from '../../store/navSlice';
import {
  hourlyUsageOption, monthlyUsageOption,
} from '../../constants/dashboardMetricsHardCoded';
import { getAllContacts } from '../../store/leadsContactsSlice';
import { isInCurrentMonth, isInCurrentWeek } from '../../utils/dateTimeFormat';

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: { agent } } = useSelector((state) => state.user);
  const { data: usageLimit } = useSelector((state) => state.usageLimit);
  const { data: usageCounter } = useSelector((state) => state.usageCounter);
  const { data: contacts } = useSelector((state) => state.contacts);
  const [hasEnoughData] = useState(false);
  const {
    ai_message_limit, ai_message_credits, booster_message_credits, help_docs_limit, help_docs_credits, booster_docs_credits,
  } = usageLimit;

  const totalMessagesLimit = Number(ai_message_limit) + Number(booster_message_credits) + Number(ai_message_credits);
  const totalDocsLimit = Number(booster_docs_credits) + Number(help_docs_limit) + Number(help_docs_credits);
  const remainingMessages = totalMessagesLimit - Number(usageCounter.ai_messages);
  const remainingHelpDocs = totalDocsLimit - Number(usageCounter.help_docs);

  const remainingCoPilots = Number(usageLimit.copilots_limit) - Number(usageCounter.copilots);

  // this month and this week contacts from createdAt date
  const contactsCreatedThisWeek = contacts.filter((contact) => {
    const createdAt = new Date(contact.createdAt);
    return isInCurrentWeek(createdAt);
  });

  const contactsCreatedThisMonth = contacts.filter((contact) => {
    const createdAt = new Date(contact.createdAt);
    return isInCurrentMonth(createdAt);
  });

  useEffect(() => {
    dispatch(setNav({ title: 'Dashboard', about: 'Get insights into your customer support operations. You can track key metrics, monitor incoming requests, and view support trends.' }));
    dispatch(getAllContacts());
    return () => {
      dispatch(setNav());
    };
  }, []);

  const dashboardItemInfo = {
    ai_messages: 'Total credits used for AI tasks',
    copilots: 'Total copilots (chatbots) created',
    help_docs: 'Help Docs are credits used for adding information to train TelePilot AI',
    contacts: 'New leads/contacts created',
    request_timeline: 'Monthwise details for request counts over last one year',
    hourly_usage: 'Usage of service in a hour format, shows average usage per hour',
    request_per_country: 'Country wise support request count for your service',
  };

  return (
    <div className="p-4">
      <Row>
        <Col span={24}>
          <div className="w-full flex font-nunito">
            <span className="font-bold text-2xl text-gray-800 mx-4 text-start">
              Welcome,
              {' '}
              {agent?.firstName}
            </span>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card
            className="my-4 rounded-xl text-start cursor-default text-sky-800"
            bordered={false}
            title="AI Credits"
            style={{ width: 300, height: 250 }}
            loading={!totalMessagesLimit}
            extra={(
              <Popover content={dashboardItemInfo.ai_messages} overlayStyle={{ maxWidth: '40%' }}>
                <InfoCircleOutlined className="opacity-50 hover:opacity-100" />
              </Popover>
            )}
          >
            <div className="flex flex-col items-start">
              <span className="text-gray-800">used</span>
              <span className="font-bold text-7xl text-sky-800 m-0">
                {usageCounter.ai_messages}
              </span>
              <div>
                <span className="text-gray-700">out of </span>
                <span className="text-lg font-semibold text-gray-700">{totalMessagesLimit}</span>
              </div>
              <div className="text-gray-700">
                <span>remaining credits: </span>
                <span className="text-lg font-semibold">{remainingMessages}</span>
                {remainingMessages < (0.20 * totalMessagesLimit) && <Button type="link" onClick={() => navigate('/settings')}>upgrade</Button>}
              </div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="my-4 rounded-xl text-start cursor-default text-sky-800"
            bordered={false}
            title="CoPilots"
            style={{ width: 300, height: 250 }}
            loading={!usageLimit.copilots_limit}
            extra={(
              <Popover content={dashboardItemInfo.copilots} overlayStyle={{ maxWidth: '40%' }}>
                <InfoCircleOutlined className="opacity-50 hover:opacity-100" />
              </Popover>
            )}
          >
            <div className="flex flex-col items-start">
              <span className="text-gray-800">created</span>
              <span className="font-bold text-7xl text-sky-800 m-0">
                {usageCounter.copilots}
              </span>
              <div>
                <span className="text-gray-700">out of </span>
                <span className="text-lg font-semibold text-gray-700">{usageLimit.copilots_limit}</span>
              </div>
              <div className="text-gray-700">
                <span>remaining copilots: </span>
                <span className="text-lg font-semibold">{remainingCoPilots}</span>
                {remainingCoPilots < (0.20 * usageLimit.copilots_limit) && <Button type="link" onClick={() => navigate('/settings')}>upgrade</Button>}
              </div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="my-4 rounded-xl text-start text-sky-700 cursor-default"
            bordered={false}
            title="Documents"
            style={{ width: 300, height: 250 }}
            loading={!totalMessagesLimit}
            extra={(
              <Popover content={dashboardItemInfo.help_docs} overlayStyle={{ maxWidth: '40%' }}>
                <InfoCircleOutlined className="opacity-50 hover:opacity-100" />
              </Popover>
            )}
          >
            <div className="flex flex-col justify-around w-full text-start">
              <span className="text-gray-800">created</span>
              <span className="font-bold text-7xl text-sky-700 m-0">
                {usageCounter.help_docs}
              </span>
              <div>
                <span className="text-gray-700">Limit: </span>
                <span className="text-lg font-semibold text-gray-700">{totalDocsLimit}</span>
              </div>
              <div className="text-gray-700">
                <span>Remaining: </span>
                <span className="text-lg font-semibold">{remainingHelpDocs}</span>
                {remainingHelpDocs < (0.20 * totalDocsLimit) && <Button type="link" onClick={() => navigate('/settings')}>upgrade</Button>}
              </div>
              {/* <div className="w-full flex items-between justify-between text-gray-700">
                <Tooltip title="Docs added via link" placement="bottom">
                  <div className="flex items-center opacity-60 hover:opacity-100">
                    <img alt="add link" src={LinkIcon} className="w-5 h-5 mr-2" />
                    <span className=" text-gray-800">40</span>
                  </div>
                </Tooltip>
                <Tooltip title="Docs added via file upload" placement="bottom">
                  <div className="flex items-center opacity-60 hover:opacity-100">
                    <img alt="add link" src={UploadIcon} className="w-5 h-5 mr-2" />
                    <span className=" text-gray-800">40</span>
                  </div>
                </Tooltip>
                <Tooltip title="Docs added via self written articles" placement="bottom">
                  <div className="flex items-center opacity-60 hover:opacity-100">
                    <img alt="add link" src={WriteIcon} className="w-5 h-5 mr-2" />
                    <span className=" text-gray-800">40</span>
                  </div>
                </Tooltip>
              </div> */}
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="my-4 rounded-xl text-start cursor-default text-sky-800"
            bordered={false}
            title="Contacts"
            style={{ width: 300, height: 250 }}
            loading={!totalMessagesLimit}
            extra={(
              <Popover content={dashboardItemInfo.contacts} overlayStyle={{ maxWidth: '40%' }}>
                <InfoCircleOutlined className="opacity-50 hover:opacity-100" />
              </Popover>
            )}
          >
            <div className="flex flex-col items-start">
              <span className="text-gray-800">Total Leads:</span>
              <span className="font-bold text-7xl text-sky-800 m-0">
                {contacts.length}
              </span>
              <div>
                <span className="text-gray-700">This week: </span>
                <span className="text-lg font-semibold text-gray-700">{contactsCreatedThisWeek.length}</span>
              </div>
              <div className="text-gray-700">
                <span>This month: </span>
                <span className="text-lg font-semibold">{contactsCreatedThisMonth.length}</span>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={9}>
          <div className="relative">
            <Card
              className="my-4 mr-1 text-start"
              title="Request Timeline"
              extra={(
                <Popover content={dashboardItemInfo.request_timeline} overlayStyle={{ maxWidth: '40%' }}>
                  <InfoCircleOutlined className="opacity-50 hover:opacity-100" />
                </Popover>
              )}
            >
              <ReactECharts className={` ${hasEnoughData ? 'opacity-100' : 'opacity-40'}`} option={monthlyUsageOption} style={{ width: '450px', height: '300px' }} />
            </Card>
            {!hasEnoughData && (
            <div className="absolute inset-x-0 bottom-40 flex items-center justify-center z-10 cursor-default">
              <Typography.Text className="bg-white py-4 px-2 rounded shadow-md">
                <ClockCircleOutlined className="mr-2" />
                Not enough data at the moment. Check back again in a few days.
              </Typography.Text>
            </div>
            )}
          </div>
        </Col>

        <Col span={15}>
          <div className="relative">
            <Card
              className="my-4 text-start"
              title="Hourly Usage"
              extra={(
                <Popover content={dashboardItemInfo.hourly_usage} overlayStyle={{ maxWidth: '40%' }}>
                  <InfoCircleOutlined className="opacity-50 hover:opacity-100" />
                </Popover>
              )}
            >
              <ReactECharts option={hourlyUsageOption} style={{ width: '780px', height: '300px' }} className={`${hasEnoughData ? 'opacity-100' : 'opacity-40'}`} />
            </Card>
            {!hasEnoughData && (
            <div className="absolute inset-x-0 bottom-40 flex items-center justify-center z-10 cursor-default">
              <Typography.Text className="bg-white p-4 rounded shadow-md py-8">
                <ClockCircleOutlined className="mr-2" />
                Not enough data at the moment. Check back again in a few days.
              </Typography.Text>
            </div>
            )}
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col className="w-full">
          <div className="relative">
            <Card
              className="my-4 text-start"
              title="Requests Per Country"
              extra={(
                <Popover content={dashboardItemInfo.request_per_country} overlayStyle={{ maxWidth: '40%' }}>
                  <InfoCircleOutlined className="opacity-50 hover:opacity-100" />
                </Popover>
              )}
            >
              <div className={`flex ${hasEnoughData ? 'opacity-100' : 'opacity-30'}`}>
                <div className="w-4/6">
                  <GeoChart requestsPerCountry={requestsPerCountry} />
                </div>
                <div className="w-2/6 flex flex-col items-start">
                  <Table pagination={false} columns={columns} dataSource={data} className="w-full h-full font-nunito" />
                </div>
              </div>
            </Card>
            {!hasEnoughData && (
            <div className="absolute inset-x-0 bottom-40 flex items-center justify-center z-10 cursor-default">
              <Typography.Text className="bg-white p-4 rounded shadow-md py-8">
                <ClockCircleOutlined className="mr-2" />
                Not enough data at the moment. Check back again in a few days.
              </Typography.Text>
            </div>
            )}
          </div>
        </Col>
      </Row> */}
    </div>
  );
}

export default Dashboard;
