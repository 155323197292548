import { useState } from 'react';
import {
  Form, Input, Button, Select, message,
} from 'antd';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { GlobalOutlined, ShopOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import industries from '../../../constants/industries';
import { updateCompanyDetails, updateUserDetails } from '../../../store/userSlice';

const { Option } = Select;

function ProfileSettings() {
  const dispatch = useDispatch();
  const [formPersonal] = Form.useForm();
  const [formCompany] = Form.useForm();
  const [loadingPersonal, setLoadingPersonal] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(false);

  const [hasPersonalChanged, setHasPersonalChanged] = useState(false);
  const [hasCompanyChanged, setHasCompanyChanged] = useState(false);

  const { data: { agent, company } } = useSelector((state) => state.user);

  const onPersonalValuesChange = (changedValues, allValues) => {
    const initial = {
      first_name: agent?.firstName,
      last_name: agent?.lastName,
    };
    setHasPersonalChanged(JSON.stringify(initial) !== JSON.stringify(allValues));
  };

  const onCompanyValuesChange = (changedValues, allValues) => {
    const initial = {
      name: company.name,
      industry: company.industry,
      website: company.website,
    };
    setHasCompanyChanged(JSON.stringify(initial) !== JSON.stringify(allValues));
  };

  const handleCancelPersonal = () => {
    formPersonal.resetFields();
    setHasPersonalChanged(false);
  };

  const handleCancelCompany = () => {
    formCompany.resetFields();
    setHasCompanyChanged(false);
  };

  const handlePersonalSubmit = (values) => {
    try {
      setLoadingPersonal(true);
      dispatch(updateUserDetails(values));
      setLoadingPersonal(false);
      setHasPersonalChanged(false);
    } catch (error) {
      setLoadingPersonal(false);
      message.error('Something went wrong. Please try again later.');
    }
  };

  const handleCompanySubmit = (values) => {
    try {
      setLoadingCompany(true);
      dispatch(updateCompanyDetails(values));
      setLoadingCompany(false);
      setHasCompanyChanged(false);
    } catch (error) {
      setLoadingCompany(false);
      message.error('Something went wrong. Please try again later.');
    }
  };

  // const handleCountrySearch = (value) => {
  //   const filteredOptions = countries.filter(
  //     (option) => option.name.toLowerCase().includes(value.toLowerCase()),
  //   );
  //   setCountryOptions(filteredOptions);
  // };

  return (
    <div className="flex flex-col items-start h-full w-full p-4">
      <div className="flex h-full flex-col w-1/2 items-start ml-2">
        {/* Agent Details */}
        <p className="font-bold mb-5">Personal Details</p>
        {agent && (
        <Form
          layout="vertical"
          form={formPersonal}
          onValuesChange={onPersonalValuesChange}
          onFinish={handlePersonalSubmit}
          className="w-full"
          initialValues={
            {
              first_name: agent?.firstName,
              last_name: agent?.lastName,
            }
          }
        >
          <Form.Item
            name="first_name"
            label="First Name"
                // initialValue={user ? user.agent.firstName : ''}
            rules={[{ required: true, message: 'Please enter your first name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
                // initialValue={user ? user.agent.lastName : ''}
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <Input />
          </Form.Item>
          <div className="flex justify-end mb-8 self-end">
            <Button
              className="mx-2"
              type="primary"
              htmlType="submit"
              loading={loadingPersonal}
              disabled={!hasPersonalChanged}
            >
              Update Personal Details
            </Button>
            <Button onClick={handleCancelPersonal} disabled={!hasPersonalChanged}>
              Cancel
            </Button>
          </div>
        </Form>
        )}

        {/* Company Details */}
        <p className="font-bold mb-5">Company Details</p>
        {company && (
        <Form
          layout="vertical"
          onValuesChange={onCompanyValuesChange}
          form={formCompany}
          onFinish={handleCompanySubmit}
          className="w-full"
          initialValues={{
            name: company?.name,
            industry: company?.industry,
            website: company?.website,
          }}
        >
          <Form.Item
            name="name"
            label="Company Name"
                // initialValue={user ? user.company.name : ''}
            rules={[{ required: true, message: 'Please enter the company name' }]}
          >
            <Input prefix={<ShopOutlined />} />
          </Form.Item>
          <Form.Item
            name="industry"
            label="Company Industry"
                // initialValue={user ? user.company.industry : ''}
            rules={[{ required: true, message: 'Please select the company industry' }]}
            className="text-start"
          >
            <Select
              placeholder="Select your industry"
              prefix={<LocationOnIcon />}
            >
              {
              industries.map((industry) => (
                <Option key={industry.value} value={industry.value}>{industry.label}</Option>
              ))
            }
            </Select>
          </Form.Item>
          {/* <Form.Item
            name="country"
            className='text-start'
            label={(
              <span className="flex">
                Country&nbsp;
              </span>
          )}
            rules={[{ required: true, message: 'Please select your country' }]}
          >
            <Select
              showSearch
              placeholder="Select your country"
              onSearch={handleCountrySearch}
              filterOption={false}
              style={{ textAlign: 'left' }}
            >
              {countryOptions.map((country) => (
                <Option className="text-black" key={country.name} value={country.name}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="website"
            label="Company Website"
                // initialValue={user ? user.company.website : ''}
            rules={[{ required: true, message: 'Please enter the company website' }]}
          >
            <Input prefix={<GlobalOutlined />} />
          </Form.Item>
          <div className="flex justify-end mb-8 self-end">
            <Button
              className="mx-2"
              type="primary"
              htmlType="submit"
              loading={loadingCompany}
              disabled={!hasCompanyChanged}
            >
              Update Company Details
            </Button>
            <Button onClick={handleCancelCompany} disabled={!hasCompanyChanged}>
              Cancel
            </Button>
          </div>
        </Form>
        )}
      </div>
    </div>
  );
}

export default ProfileSettings;
