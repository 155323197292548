import {
  Tooltip, FloatButton, Alert, Button,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddNewDocumentModal from '../helpcms/AddNewDocumentModal';
import AddLinkModal from '../helpcms/AddLinkModal';
import FileUploadModal from '../helpcms/FileUploadModal';
import EmptyPageMessage from '../helpcms/EmptyPageMessage';
import { getAllHelpDocs } from '../../store/helpCMSSlice';
import { setNav } from '../../store/navSlice';
import HelpDocCard from '../helpcms/HelpDocCard';

function HelpCMSPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: usageLimit } = useSelector((state) => state.usageLimit);
  const { helpDocs } = useSelector((state) => state.helpCMS);
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [openAddLinkModal, setOpenAddLinkModal] = useState(false);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);

  const helpCenterInfo = `This is where you put all your help documents and user guides.
  The TelePilot AI chatbot will be able to answer questions from this knowledge base.\n
  Additionally, you choose which documents to be publicly available for customer facing help center(opening soon) as well as which documents
  are meant for your internal purposes only. You can also control which documents
  can AI learn from.`;

  useEffect(() => {
    dispatch(getAllHelpDocs());
    dispatch(setNav({ title: 'Documents', about: helpCenterInfo }));

    return () => {
      dispatch(setNav());
    };
  }, []);

  // const handleSearch = () => {
  //   // Handle the search logic here
  //   // vlaue
  // };

  return (
    <div>
      {(!usageLimit.doc_via_link || !usageLimit.doc_via_upload || !usageLimit.doc_via_writeup) && (
      <Alert
        className="mb-4"
        message="Upgrade your plan to access all document submission options, including file upload and self-written articles."
        banner
        type="info"
        closable
        action={(
          <Button size="small" type="primary" onClick={() => navigate('/settings')}>
            Upgrade
          </Button>
          )}
      />
      )}
      <div className="container mx-auto p-4">
        {/* <div className="flex items-center mb-6">
        <Input.Search
          placeholder="Search for a problem..."
          enterButton={(
            <Button type="primary" icon={<SearchOutlined />} size="large">
              Search
            </Button>
          )}
          size="large"
          onSearch={handleSearch}
        />
      </div> */}

        {helpDocs && helpDocs.length > 0 ? (
          <>
            <div className="text-start">
              <h1 className="text-xl font-bold">All Help Docs Created</h1>
              <div className="flex flex-col h-fit my-8">
                <span className="text-gray-800 mb-2 text-lg">
                  This lists all the help docs created along with their publishing and training status.
                  You can add as many relevant help docs as required to make your chat widget responses better on your site.
                </span>
                <span className="text-gray-600">
                  <strong>Note: </strong>
                  Please be aware that only the directly provided link is parsed by our system.
                  Any sublinks or nested links contained within are not automatically processed.
                  If you wish for those to be read, you will need to provide them separately.
                </span>
              </div>
            </div>
            <div className="flex flex-wrap -mx-4">
              {helpDocs.map((doc) => (
                <HelpDocCard doc={doc} />
              ))}
            </div>
          </>
        )
          : (
            <div className="mt-32">
              <EmptyPageMessage
                setOpenAddNewModal={setOpenAddNewModal}
                setOpenAddLinkModal={setOpenAddLinkModal}
                setOpenFileUploadModal={setOpenFileUploadModal}
              />
            </div>
          )}

        <Tooltip title="Add new document">
          <FloatButton
            type="primary"
            icon={<PlusOutlined />}
            style={{
              right: 80,
            }}
            onClick={() => setOpenAddNewModal(true)}
          />
        </Tooltip>
        <AddNewDocumentModal
          visible={openAddNewModal}
          setVisible={setOpenAddNewModal}
          setOpenAddLinkModal={setOpenAddLinkModal}
          setOpenFileUploadModal={setOpenFileUploadModal}
        />
        {usageLimit.doc_via_link && (
        <AddLinkModal
          visible={openAddLinkModal}
          setVisible={setOpenAddLinkModal}
          setOpenAddNewModal={setOpenAddNewModal}
        />
        )}
        {usageLimit.doc_via_upload && (
        <FileUploadModal
          visible={openFileUploadModal}
          setVisible={setOpenFileUploadModal}
          setOpenAddNewModal={setOpenAddNewModal}
        />
        )}
      </div>
    </div>
  );
}

export default HelpCMSPage;
