import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty, Input } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { setNav } from '../../store/navSlice';
import ChatbotCard from '../copilots/ChatbotCard';
import { getAllCoPilots } from '../../store/copilotsSlice';
import DeleteCoPilotConfirm from '../copilots/DeleteCoPilotConfirm';
import NewCoPilot from '../copilots/NewCoPilot/NewCoPilot';
import EditCoPilot from '../copilots/EditCoPilot/EditCoPilot';
import InstallCoPilotInstructions from '../copilots/InstallCoPilotInstructions';

const { Search } = Input;

function CoPilotsPage() {
  const dispatch = useDispatch();
  const { allCoPilots: copilots } = useSelector((state) => state.copilots);

  const copilotsPageInfo = 'Create and manage all your chatbots from here';

  useEffect(() => {
    dispatch(getAllCoPilots());
    dispatch(setNav({ title: 'CoPilots', about: copilotsPageInfo }));
    return () => {
      dispatch(setNav());
    };
  }, []);

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deletionBot, setDeletionBot] = useState(null);
  const [openNewCoPilotModal, setOpenNewCoPilotModal] = useState(false);
  const [listedBots, setListedBots] = useState(copilots ? [...copilots] : []);
  const [openEditCoPilotModal, setOpenEditCoPilotModal] = useState(false);
  const [openInstallationModal, setOpenInstallationModal] = useState(false);
  const [installationBot, setInstallationBot] = useState(null);

  // copilot edit params
  const [editingCoPilotId, setEditingCoPilotId] = useState('');
  const [editingCoPilotName, setEditingCoPilotName] = useState('');
  const [editingCoPilotDescription, setEditingCoPilotDescription] = useState('');
  const [editingCoPilotDocs, setEditingCoPilotDocs] = useState([]);

  const handleInstallModal = (bot) => {
    setOpenInstallationModal(true);
    setInstallationBot(bot);
  };

  const confirmDeletion = (bot) => {
    setOpenDeleteConfirm(true);
    setDeletionBot(bot);
  };

  const handleEditCoPilotParams = (botId) => {
    const bot = copilots.find((copilot) => copilot.id === botId);
    setOpenEditCoPilotModal(true);
    setEditingCoPilotId(bot.id);
    setEditingCoPilotName(bot.name);
    setEditingCoPilotDescription(bot.description);
    setEditingCoPilotDocs(bot.CoPilotHelpDocMaps.map((doc) => doc.id));
  };

  useEffect(() => {
    setListedBots([...copilots]);
  }, [copilots]);

  const filterBots = (value) => {
    if (value === '') {
      setListedBots([...copilots]);
    } else {
      const filteredBots = copilots.filter((bot) => bot.name.toLowerCase().includes(value.toLowerCase()) || bot.description.toLowerCase().includes(value.toLowerCase()));
      setListedBots(filteredBots);
    }
  };

  return (
    <div>
      {/* Header */}
      <div className="flex justify-between items-center m-2">
        <div className="px-4">
          Active bots:
          {' '}
          {copilots && copilots.length}
        </div>
        <div className="flex items-center">
          <Search
            placeholder="Filter"
            allowClear
            onChange={(e) => filterBots(e.target.value)}
            onSearch={filterBots}
            style={{
              width: 200,
              marginRight: 10,
            }}
          />
          <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => setOpenNewCoPilotModal(true)}>
            New CoPilot
          </Button>
        </div>
      </div>

      {/* CoPilots */}
      <div className="flex p-2 flex-wrap">
        {
          listedBots && listedBots.length > 0 ? listedBots.map((copilot) => (
            <ChatbotCard
              key={copilot.id}
              id={copilot.id}
              name={copilot.name}
              date={copilot.createdAt}
              description={copilot.description}
              confirmDeletion={confirmDeletion}
              handleEditCoPilotParams={handleEditCoPilotParams}
              handleInstallModal={handleInstallModal}
            />
          ))
            : (
              <Empty
                className="flex flex-col justify-center w-full h-full"
                description={(
                  <div className="flex flex-col">
                    <p className="text-sm">
                      No CoPilots found.
                    </p>
                    <p className="text-sm">
                      Create a new one from top right button.
                    </p>
                  </div>
                )}
              />
            )
        }
      </div>
      {/* CoPilot Installation Instructions */}
      <InstallCoPilotInstructions visible={openInstallationModal} setVisible={setOpenInstallationModal} installationBot={installationBot} setInstallationBot={setInstallationBot} />

      {/* Delete CoPilot Confirm */}
      <DeleteCoPilotConfirm
        visible={openDeleteConfirm}
        setVisible={setOpenDeleteConfirm}
        deletionBot={deletionBot}
        setDeletionBot={setDeletionBot}
      />

      {/* New CoPilot Modal */}
      <NewCoPilot
        visible={openNewCoPilotModal}
        setVisible={setOpenNewCoPilotModal}
      />

      {/* Edit CoPilot Modal */}
      <EditCoPilot
        visible={openEditCoPilotModal}
        setVisible={setOpenEditCoPilotModal}
        copilotId={editingCoPilotId}
        copilotName={editingCoPilotName}
        copilotDescription={editingCoPilotDescription}
        copilotDocs={editingCoPilotDocs}
      />
    </div>
  );
}

export default CoPilotsPage;
