/* eslint-disable no-plusplus */
import { useState } from 'react';
import { Table, Tag, Tooltip } from 'antd';
import { messageTimeStampFormat } from '../../../utils/dateTimeFormat';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    width: 300,
    ellipsis: true,
    render: (text) => (
      <Tooltip title={text}>
        <span>{text}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: 300,
    ellipsis: true,
    render: (text) => (
      <Tooltip title={text}>
        <span>{text}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Source',
    dataIndex: 'source',
  },
  {
    title: 'Source Type',
    dataIndex: 'sourceType',
  },
  {
    title: 'Updated At',
    dataIndex: ('updatedAt'),
    render: (updatedAt) => <span>{messageTimeStampFormat(updatedAt).currentFormat}</span>,
  },
  {
    title: 'Published',
    dataIndex: 'published',
    render: (published) => (
      <Tag bordered={false} color={published ? 'green' : 'gold'}>
        {published ? 'PUBLISHED' : 'NOT PUBLISHED'}
      </Tag>
    ),
  },
];

function SelectDocListView({ docs, selectedDocs, docsSelector }) {
  const onSelectChange = (newSelectedRowKeys) => {
    docsSelector(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys: selectedDocs,
    onChange: onSelectChange,
  };
  return (
    <div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={docs}
        rowKey={(record) => record.id}
        scroll={{
          x: 1300,
        }}
        bordered
        pagination={false}
      />
    </div>
  );
}
export default SelectDocListView;
