import { LinkOutlined } from '@ant-design/icons';
import {
  Button, Card, Form, Input, Result, Space, Tooltip,
} from 'antd';
import Meta from 'antd/es/card/Meta';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addHelpLinks } from '../../store/helpCMSSlice';
import { getUsageCounter } from '../../store/usageCounterSlice';

import LinkIcon from '../../assets/link.svg';
import UploadIcon from '../../assets/upload.svg';
import WriteIcon from '../../assets/write.svg';
import AddLinkModal from '../helpcms/AddLinkModal';
import FileUploadModal from '../helpcms/FileUploadModal';

function OnboardingAddDoc({
  helpDocPresent, setHelpDocPresent, setVisible,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: usageCounter } = useSelector((state) => state.usageCounter);
  const [openAddLinkModal, setOpenAddLinkModal] = useState(false);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);

  useEffect(() => {
    if (usageCounter?.help_docs > 0) {
      setHelpDocPresent(true);
    } else {
      setHelpDocPresent(false);
    }
  }, [usageCounter]);

  const handleAddLink = () => {
    setOpenAddLinkModal(true);
  };

  const handleUploadFile = () => {
    setOpenFileUploadModal(true);
  };

  const handleWriteDocument = () => {
    navigate('/documents/new-article');
    setVisible(false);
  };

  return (
    <>
      <div className="w-full my-4 mt-12 relative font-nunito">
        <div className="flex flex-col justify-around w-full overflow-scroll h-full">
          <h4>Add your training data:</h4>
          <div className="flex justify-around mb-2">
            <Tooltip title="Add a webpage as a help doc" placement="bottom">
              <Card
                hoverable
                title="Add Links"
                style={{ width: 200, height: 230 }}
                onClick={handleAddLink}
                cover={<img alt="add link" src={LinkIcon} className="w-16 h-16 mt-8 mb-2" />}
              >
                <Meta description="You can directly add all the site links" />
              </Card>
            </Tooltip>
            <Tooltip title="Add new help doc using file upload" placement="bottom">
              <Card
                hoverable
                title="Upload File"
                onClick={handleUploadFile}
                style={{ width: 200, height: 230 }}
                cover={<img alt="add link" src={UploadIcon} className="w-16 h-16 mt-8 mb-2" />}
              >
                <Meta description="You can upload file from your computer" />
              </Card>
            </Tooltip>
            <Tooltip title="Add new help doc by writing your own help doc" placement="bottom">
              <Card
                hoverable
                title="Write Article"
                style={{ width: 200, height: 230 }}
                onClick={handleWriteDocument}
                cover={<img alt="add link" src={WriteIcon} className="w-16 h-16 mt-8 mb-2" />}
              >
                <Meta description="You can also write your own help article here" />
              </Card>
            </Tooltip>
          </div>
        </div>
        {helpDocPresent && (
        <div className="absolute inset-0 flex items-center justify-center z-10 cursor-default bg-white">
          <Result
            status="success"
            title="Help doc added successfully"
            subTitle="You have added a new help doc to your knowledge base, you may continue to next step."
          />
        </div>
        )}
      </div>
      <AddLinkModal
        visible={openAddLinkModal}
        setVisible={setOpenAddLinkModal}
        setOpenAddNewModal={setVisible}
      />
      <FileUploadModal
        visible={openFileUploadModal}
        setVisible={setOpenFileUploadModal}
        setOpenAddNewModal={setVisible}
      />
    </>
  );
}

export default OnboardingAddDoc;
