import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectDocListView from './SelectDocListView';
import SelectDocGridView from './SelectDocGridView';
import { getAllHelpDocs } from '../../../store/helpCMSSlice';
import { setView } from '../../../store/preferencesSlice';

function ResourcesNewCoPilot({ selectedDocs, docsSelector }) {
  const dispatch = useDispatch();

  const { helpDocs } = useSelector((state) => state.helpCMS);
  const { view } = useSelector((state) => state.preferences);

  useEffect(() => {
    dispatch(getAllHelpDocs());
  }, []);

  const hasSelected = selectedDocs.length > 0;

  return (
    <div className="my-2">
      <div className="flex justify-end px-2">
        <div className="w-full flex items-center">
          <p className="font-medium">Select documents to train your new CoPilot on:</p>
        </div>
        <div className="flex justify-end items-center">
          {view === 'list'
            ? <AppstoreOutlined style={{ fontSize: '1.5rem' }} onClick={() => dispatch(setView('grid'))} />
            : <UnorderedListOutlined style={{ fontSize: '1.5rem' }} onClick={() => dispatch(setView('list'))} />}
        </div>
      </div>
      <div className="flex flex-col h-[32rem] overflow-scroll">
        <p className="px-2">{hasSelected ? `Selected ${selectedDocs.length} documents` : ''}</p>
        {view === 'list'
          ? <SelectDocListView style={{ fontSize: '1.5rem' }} docs={helpDocs} selectedDocs={selectedDocs} docsSelector={docsSelector} />
          : <SelectDocGridView style={{ fontSize: '1.5rem' }} docs={helpDocs} selectedDocs={selectedDocs} docsSelector={docsSelector} />}
      </div>
    </div>
  );
}

export default ResourcesNewCoPilot;
