import { SmileOutlined } from '@ant-design/icons';
import { Result } from 'antd';

function OnboardingFinish() {
  return (
    <div className="mt-12">
      <Result
        icon={<SmileOutlined />}
        title="Great, we have completed the setup!"
        subTitle="You can add new docs to your knowledge base by going to Documents from the left."
      />
    </div>
  );
}

export default OnboardingFinish;
