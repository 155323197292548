import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PlanBillingLayout from '../../layouts/PlanBillingLayout';
import BillingSettings from './BillingSettings/BillingSettings';
import PlanSettings from './PlansSettings/PlanSettings';
import { getBillingDetails } from '../../store/billingSlice';
import { getCurrentSubscription } from '../../store/subscriptionSlice';

function PlanBillingsLanding() {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('billing');

  useEffect(() => {
    dispatch(getBillingDetails());
    dispatch(getCurrentSubscription());
  }, [selectedTab]);

  return (
    <PlanBillingLayout tabSelection={{ selectedTab, setSelectedTab }}>
      {
                selectedTab === 'billing' ? <BillingSettings setSelectedTab={setSelectedTab} /> : <PlanSettings />
            }
    </PlanBillingLayout>
  );
}

export default PlanBillingsLanding;
