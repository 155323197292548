import { useEffect, useState } from 'react';
import { Alert, Button, Space } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function AlertBanner() {
  const navigate = useNavigate();
  const { data: usageLimit } = useSelector((state) => state.usageLimit);
  const { data: usageCounter } = useSelector((state) => state.usageCounter);

  const [messagesExhausted, setMessagesExhausted] = useState(false);
  const [docsExhausted, setDocsExhausted] = useState(false);

  const [messagesNearExhaustion, setMessagesNearExhaustion] = useState(false);
  const [docsNearExhaustion, setDocsNearExhaustion] = useState(false);

  const [warningDisplayTimeout, setWarningDisplayTimeout] = useState(false);

  const exhaustionChecks = () => {
    const {
      ai_message_limit, ai_message_credits, booster_message_credits, help_docs_limit, help_docs_credits, booster_docs_credits,
    } = usageLimit;
    const totalMessagesLimit = Number(ai_message_limit) + Number(booster_message_credits) + Number(ai_message_credits);
    const totalDocsLimit = Number(booster_docs_credits) + Number(help_docs_limit) + Number(help_docs_credits);
    const remainingMessages = totalMessagesLimit - Number(usageCounter.ai_messages);
    const remainingHelpDocs = totalDocsLimit - Number(usageCounter.help_docs);

    if (remainingMessages <= 0) {
      setMessagesExhausted(true);
    }
    if (remainingHelpDocs <= 0) {
      setDocsExhausted(true);
    }
    if (remainingMessages > 0 && remainingMessages < (0.20 * totalMessagesLimit)) { // remaining messages less than 20%
      setMessagesNearExhaustion(true);
    }
    if (remainingHelpDocs > 0 && remainingHelpDocs < (0.20 * totalDocsLimit)) { // remaining help docs less than 20%
      setDocsNearExhaustion(true);
    }
  };

  useEffect(() => {
    // if (usageLimit && Object.keys(usageLimit) > 0 && usageCounter && Object.keys(usageCounter) > 0) {
    exhaustionChecks();
    // }
  }, [usageLimit, usageCounter]);

  const checkWarningDisplayEligibility = () => {
    const currentTime = new Date();
    const warningCloseTime = new Date(localStorage.getItem('warningCloseTime'));
    warningCloseTime.setDate(warningCloseTime.getDate() + 2);
    if (currentTime > warningCloseTime) {
      setWarningDisplayTimeout(true);
    }
  };

  useEffect(() => {
    checkWarningDisplayEligibility();
  }, []);

  const warningClose = () => {
    // mark current time and save in local storage
    const currentTime = new Date();
    localStorage.setItem('warningCloseTime', currentTime);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>

      {/* 1. MESSAGES LIMIT EXHAUSTED  */}
      {(messagesExhausted && !docsExhausted)
      && (
      <Alert
        type="error"
        message="Your AI powered chat messages on chat widget have hit their limit. To maintain seamless 24x7 support for your users, consider an upgrade. Let AI keep the conversation going!"
        banner
        action={(
          <Button size="small" type="primary" onClick={() => navigate('/settings')}>
            Upgrade
          </Button>
        )}
      />
      )}

      {/* 2. DOCS LIMIT EXHAUSTED   */}
      {(docsExhausted && !messagesExhausted)
      && (
      <Alert
        type="error"
        message="You've reached your help documents limit. Elevate user experience by upgrading and continuing to add essential docs for AI to learn for unmatched support."
        banner
        action={(
          <Button size="small" type="primary" onClick={() => navigate('/settings')}>
            Upgrade
          </Button>
            )}
      />
      )}

      {/* 3. BOTH MESSAGE AND DOCS LIMIT EXHAUSTED */}
      {(docsExhausted && messagesExhausted)
      && (
      <Alert
        type="error"
        message="You've reached both message and doc limits. Upgrade for continuous AI powered chat support and to keep expanding it's knowledge base. Your visitors deserve the best."
        banner
        action={(
          <Button size="small" type="primary" onClick={() => navigate('/settings')}>
            Upgrade
          </Button>
            )}
      />
      )}

      {/* 4. EITHER OF THE LIMITS ABOUT TO EXHAUST, show if warningClose more than 2 days */}
      {(!messagesExhausted && !docsExhausted) && (messagesNearExhaustion || docsNearExhaustion) && (warningDisplayTimeout) && (
      <Alert
        message="Caution! You're nearing your plan's limits. Upgrade to prevent any service hiccups and to keep offering premium support to your visitors."
        banner
        closable
        onClose={warningClose}
        action={(
          <Button size="small" type="primary" onClick={() => navigate('/settings')}>
            Upgrade
          </Button>
          )}
      />
      )}
    </Space>
  );
}

export default AlertBanner;
