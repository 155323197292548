import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  view: localStorage.getItem('viewPreference') || 'list',
  theme: localStorage.getItem('themePreference') || 'light',
};

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setView: (state, action) => {
      localStorage.setItem('viewPreference', action.payload);
      return { ...state, view: action.payload };
    },
    setTheme: (state, action) => {
      localStorage.setItem('themePreference', action.payload);
      return { ...state, theme: action.payload };
    },
  },
});

export const { setView, setTheme } = preferencesSlice.actions;
export default preferencesSlice.reducer;
