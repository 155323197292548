import {
  Routes,
  Route,
} from 'react-router-dom';
// import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import MainLayout from '../layouts/MainLayout';
import DashboardPage from '../components/pages/DashboardPage';
import WidgetPage from '../components/pages/WidgetPage';
import NotificationsPage from '../components/pages/NotificationsPage';
import SubscriptionsPage from '../components/pages/SubscriptionsPage';
import SettingsPage from '../components/pages/SettingsPage';
import HelpCMSPage from '../components/pages/HelpCMSPage';
import NewArticle from '../components/helpcms/NewArticle';
import NotFoundRoute from './NotFoundRoute';
import CoPilotsPage from '../components/pages/CoPilotsPage';
import LeadsContactsPage from '../components/pages/LeadsContactsPage';
import DetailedCoPilotsPage from '../components/pages/DetailedCoPilotsPage';
import QueriesPage from '../components/pages/QueriesPage';
// import UserOnboarding from '../components/common/UserOnboarding';

function UserRoutes() {
  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/documents" element={<HelpCMSPage />} />
        <Route path="/documents/new-article" element={<NewArticle />} />
        <Route path="/widget" element={<WidgetPage />} />
        <Route path="/co-pilots" element={<CoPilotsPage />} />
        <Route path="/co-pilots/:copilotId" element={<DetailedCoPilotsPage />} />
        <Route path="/contacts" element={<LeadsContactsPage />} />
        <Route path="/queries" element={<QueriesPage />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/subscriptions" element={<SubscriptionsPage />} />
        <Route path="/settings" element={<SettingsPage />}>
          <Route path="/settings/profile" element={<SettingsPage />} />
          <Route path="/settings/security" element={<SettingsPage />} />
          <Route path="/settings/billing-plans" element={<SettingsPage />} />
        </Route>
        <Route path="*" element={<NotFoundRoute />} />
      </Routes>
    </MainLayout>
  );
}

export default UserRoutes;
