import { configureStore } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import helpCMSSlice from './helpCMSSlice';
import navSlice from './navSlice';
import billingSlice from './billingSlice';
import plansSlice from './plansSlice';
import subscriptionSlice from './subscriptionSlice';
import paymentMethodsSlice from './paymentMethodsSlice';
import ordersSlice from './ordersSlice';
import usageLimitSlice from './usageLimitSlice';
import usageCounterSlice from './usageCounterSlice';
import widgetConfigurationsSlice from './widgetConfigurationsSlice';
import onboardingSlice from './onboardingSlice';
import copilotsSlice from './copilotsSlice';
import preferencesSlice from './preferencesSlice';
import leadsContactsSlice from './leadsContactsSlice';
import conversationsSlice from './conversationsSlice';
import leadsQueriesSlice from './leadsQueriesSlice';

const store = configureStore({
  reducer: {
    user: userSlice,
    onboarding: onboardingSlice,
    helpCMS: helpCMSSlice,
    nav: navSlice,
    billing: billingSlice,
    plans: plansSlice,
    subscription: subscriptionSlice,
    paymentMethods: paymentMethodsSlice,
    orders: ordersSlice,
    usageLimit: usageLimitSlice,
    usageCounter: usageCounterSlice,
    widgetConfigurations: widgetConfigurationsSlice,
    copilots: copilotsSlice,
    preferences: preferencesSlice,
    contacts: leadsContactsSlice,
    conversations: conversationsSlice,
    leadsQueries: leadsQueriesSlice,
    // property: propertyReducer,
    // widget: widgetReducer
  },
});

export default store;
