import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteCoPilot, getAllCoPilots } from '../../store/copilotsSlice';

function DeleteCoPilotConfirm({
  visible, setVisible, deletionBot, setDeletionBot,
}) {
  const dispatch = useDispatch();

  const handleDelete = async () => {
    await dispatch(deleteCoPilot(deletionBot.id));
    setVisible(false);
    setDeletionBot(null);
    dispatch(getAllCoPilots());
  };

  const handleCancel = () => {
    setVisible(false);
    setDeletionBot(null);
  };

  return (
    <Modal
      title="Confirm Deletion"
      open={visible} // Set the visibility based on your logic
      onOk={handleDelete}
      onCancel={handleCancel}
      okText="Delete"
      okType="danger"
    >
      <p>
        {`Are you sure you want to delete ${deletionBot && deletionBot.name}`}
      </p>
    </Modal>
  );
}

export default DeleteCoPilotConfirm;
