import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

function NotFoundRoute() {
  const navigate = useNavigate();
  const routeToHome = () => {
    navigate('/');
  };

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist yet."
      extra={<Button onClick={routeToHome} type="primary">Back Home</Button>}
    />
  );
}

export default NotFoundRoute;
