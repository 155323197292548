import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QueriesList from '../queries/QueriesList';
import DeleteQueryConfirm from '../queries/DeleteQueryConfirm';
import HeaderQueries from '../queries/HeaderQueries';
import { setNav } from '../../store/navSlice';
import { getAllQueries, getArchivedQueries } from '../../store/leadsQueriesSlice';

function QueriesPage({ copilotId }) {
  const dispatch = useDispatch();

  const queriesInfo = `Find all the questions your visitors post for more information by a human executive. 
  Find their contact details and questions which you can answer through your support mail.`;

  const { activeQueries, archivedQueries } = useSelector((state) => state.leadsQueries);

  const [queries, setQueries] = useState(activeQueries);
  const [view, setView] = useState('active');
  const [openDeleteQueryModal, setOpenDeleteQueryModal] = useState(false);
  const [deletionQueryId, setDeletionQueryId] = useState(null);
  const [filterString, setFilterString] = useState('');

  useEffect(() => {
    dispatch(setNav({ title: 'Queries', about: queriesInfo }));
    dispatch(getAllQueries());
    dispatch(getArchivedQueries());
    // setView('active');
    // setQueries(activeQueries);
    return () => {
      dispatch(setNav());
    };
  }, []);

  useEffect(() => {
    if (view === 'active') {
      setQueries(activeQueries);
    } else if (view === 'archived') {
      setQueries(archivedQueries);
    } else if (view === 'starred') {
      setQueries(activeQueries.filter((query) => query.starred));
    }
  }, [view, activeQueries, archivedQueries]);

  const handleViewChange = async (e) => {
    const newView = e.target.value;
    setView(newView);
  };

  const handleDeletionConfirm = (id) => {
    setOpenDeleteQueryModal(true);
    setDeletionQueryId(id);
  };

  const filterQueries = () => {
    if (filterString !== '' && filterString !== null) {
      const filteredQueries = activeQueries.filter((query) => query.query.toLowerCase().includes(filterString.toLowerCase()) || query.leadsContact.email.toLowerCase().includes(filterString.toLowerCase()) || query.leadsContact.name.toLowerCase().includes(filterString.toLowerCase()));
      setQueries(filteredQueries);
    } else {
      setQueries(activeQueries);
    }
  };

  useEffect(() => {
    filterQueries();
  }, [filterString]);

  return (
    <div className="flex flex-col px-8">
      <HeaderQueries
        copilotId={copilotId}
        view={view}
        setView={setView}
        handleViewChange={handleViewChange}
        queries={queries}
        filterQueries={filterQueries}
        filterString={filterString}
        setFilterString={setFilterString}
      />
      <div className="h-[37rem] overflow-scroll text-left">
        <QueriesList queries={queries} handleDeletionConfirm={handleDeletionConfirm} view={view} />
      </div>
      <DeleteQueryConfirm
        visible={openDeleteQueryModal}
        setVisible={setOpenDeleteQueryModal}
        deletionQueryId={deletionQueryId}
        setDeletionQueryId={setDeletionQueryId}
      />
    </div>
  );
}

export default QueriesPage;
