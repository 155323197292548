import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

function RichTextEditor({ articleContent, setArticleContent }) {
  return (
    <div className="h-full rounded-xl">
      <ReactQuill theme="snow" value={articleContent} onChange={setArticleContent} className="h-full" />
    </div>
  );
}

export default RichTextEditor;
