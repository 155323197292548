import React, { useEffect } from 'react';
import {
  Avatar,
  Empty, Tooltip,
} from 'antd';
import { messageTimeStampFormat } from '../../../utils/dateTimeFormat';

import TPLogo from '../../../assets/tp_logo_final_black.svg';

function SentMessage({ index, msg }) {
  const formattedDate = messageTimeStampFormat(msg.delivered_at);
  return (
    <div id={`${formattedDate.currentFormat}-${index}`}>
      <div className="flex justify-end mb-4 cursor-default">
        <div className="flex flex-col items-end w-full">
          <div className="bg-blue-500 text-white text-sm rounded-br-none rounded-lg px-2 text-end" style={{ width: 'fit-content', maxWidth: '30rem' }}>
            <p className="m-2">{msg.message}</p>
          </div>
          <Tooltip title={formattedDate.currentFormat} placement="bottomRight" color="grey">
            <span className="text-xs text-gray-500 text-end">{formattedDate.agoFormat}</span>
          </Tooltip>
        </div>
        <Avatar
          className="bg-slate-50 self-end m-2"
        //   icon={<UserOutlined />}
          src={TPLogo}
          style={{ height: '30px', width: '48px' }}
        />
      </div>
    </div>
  );
}

function ReceivedMessage({ index, msg, customerImage }) {
  const formattedDate = messageTimeStampFormat(msg.delivered_at);
  return (
    <div id={`${formattedDate.currentFormat}-${index}`}>
      <div className="flex justify-start mb-4 cursor-default">
        <Avatar className="self-end m-2" src={customerImage} />
        <div className="flex flex-col">
          <div className="bg-gray-300 text-black text-sm rounded-bl-none rounded-lg px-2 py-1 text-start" style={{ width: 'fit-content', maxWidth: '30rem' }}>
            <p className="">{msg.message}</p>
          </div>
          <Tooltip title={formattedDate.currentFormat} placement="bottomLeft" color="grey">
            <span className="text-xs text-gray-500 cursor-default text-start">{formattedDate.agoFormat}</span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

function MessagesDisplay({ messages, customerImage }) {
  const formatMessages = (msgs) => {
    const finalMessages = [];
    if (msgs && msgs.length > 0) {
      msgs.forEach((msg) => {
        finalMessages.push({
          id: `customer-${msg.id}`,
          sender: 'customer',
          message: msg.message,
          delivered_at: msg.delivered_at,
        });
        finalMessages.push({
          id: `agent-${msg.id}`,
          sender: 'agent',
          message: msg.reply,
          delivered_at: msg.delivered_at,
        });
      });
    }
    return finalMessages;
  };

  const formattedMessages = formatMessages(messages);

  return (
    <div className="w-full p-4">
      {messages && messages.length > 0
        ? formattedMessages.map((item, index) => (
          <div key={item.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: item.sender.includes('agent') ? 'end' : 'start' }}>
            {item.sender.includes('agent') ? <SentMessage index={index} msg={item} /> : <ReceivedMessage index={index} msg={item} customerImage={customerImage} />}
          </div>
        ))
        : (
          <Empty
            className="flex flex-col items-center justify-center w-full h-full"
            description={(
              <div className="flex flex-col">
                <p>
                  Select a conversation to view messages.
                </p>
                <p className="text-xs">
                  Select one conversation from left to view all the messages in the conversation.
                </p>
              </div>
        )}
          />
        )}
    </div>
  );
}

export default MessagesDisplay;
