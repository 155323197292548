export const commonEmojis = [
  '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣',
  '😊', '😇', '🙂', '🙃', '😉', '😌', '😍', '🥰',
  '😋', '😎', '🤩', '😏', '😒', '😞', '😔', '😟',
  '😕', '🙁', '☹️', '😣', '😖', '😫', '😩', '🥺',
  '😢', '😭', '😤', '😠', '😡', '🤬', '🤯', '😳',
  '🥴', '😱', '😨', '😰', '😥', '😓', '🤗', '🤔',
  '🤭', '🤫', '🤥', '😶', '😐', '😑', '😬', '🙄',
  '😯', '😦', '😧', '😮', '😲', '😴', '🤤', '😪',
  '😵', '🤐', '🥱', '😷', '🤒', '🤕', '🤢', '🤮',
  '🤧', '🥵', '🥶', '🥳', '🥺', '🤠', '🤡', '🤥',
  '🤫', '🤭', '🧐', '🤓', '😈', '👿', '👹', '👺',
  '💀', '👻', '👽', '👾', '🤖', '💩', '😺', '😸',
  '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '🙈',
  '🙉', '🙊', '🐵', '🐶', '🐺', '🐱', '🦁', '🐯',
  '🦒', '🦌', '🐴', '🦄', '🐮', '🐷', '🐽', '🐗',
  '🦔', '🦇', '🐻', '🐨', '🐼', '🦘', '🦡', '🐾',
  '🦃', '🐔', '🐓', '🐣', '🐤', '🐥', '🐦', '🐧',
  '🕊️', '🦅', '🦆', '🦢', '🦉', '🦚', '🦜', '🦩',
  '🦤', '🪶', '🦞', '🦐', '🦀', '🦪', '🐙', '🦈',
  '🐚', '🐌', '🦋', '🐛', '🐜', '🐝', '🪲', '🐞',
  '🦗', '🕷️', '🦂', '🦟', '🦠', '💐', '🌸', '💮',
  '🏵️', '🌹', '🥀', '🌺', '🌻', '🌼', '🌷', '🌱',
  '🪴', '🌲', '🌳', '🌴', '🌵', '🌾', '🌿', '☘️',
  '🍀', '🍁', '🍂', '🍃', '🍇', '🍈', '🍉', '🍊',
  '🍋', '🍌', '🍍', '🥭', '🍎', '🍏', '🍐', '🍑',
  '🍒', '🍓', '🫐', '🥝', '🍅', '🍆', '🥑', '🥦',
  '🥒', '🌶️', '🌽', '🥕', '🫒', '🧄', '🧅', '🍄',
  '🥜', '🌰', '🍞', '🥐', '🥖', '🥨', '🥯', '🥞',
  '🧇', '🧀', '🍖', '🍗', '🥩', '🍔', '🍟', '🍕',
  '🌭', '🍿', '🍲', '🍱', '🍘', '🍙', '🍚', '🍛',
  '🍜', '🍝', '🍠', '🍢', '🍣', '🍤', '🍥', '🥮',
  '🍡', '🥟', '🥠', '🥡', '🦀', '🦞', '🦐', '🦑',
  '🍦', '🍧', '🍨', '🍩', '🍪', '🎂', '🍰', '🧁',
  '🥧', '🍫', '🍬', '🍭', '🍮', '🍯', '🍼', '🥛',
  '☕', '🍵', '🍶', '🍾', '🍷', '🍸', '🍹', '🍺',
  '🍻', '🥂', '🥃', '🍽️', '🍴', '🥄', '🍽️', '🥄',
];

const supportEmojis = [
  '😀', '😃', '😄', '😊', '😇', '🙂', '🙃', '😉', '😌', '😍', '🥰', '😋', '😎',
  '😊', '🥺', '😢', '😭', '😔', '🙂', '🙁', '😕', '😟', '😞', '😖', '😣', '😫',
  '😤', '😠', '😥', '😓', '🤗', '🤔', '🤨', '😐', '😬', '🙄', '😶', '😪', '😴',
  '😷', '🤒', '🤕', '🤢', '🤮', '🤧', '🥴', '🥱', '🤯', '😵', '🥶', '🥵', '🥳',
  '🤩', '🤠', '🥺', '😇', '🤓', '🧐', '🙌', '👏', '👍', '👎', '👊', '✊', '🤝',
  '🤞', '🤜', '🤛', '🙏', '✌️', '🤟', '👌', '🤏', '👋', '🤚', '🖐️', '✋', '👆',
  '👇', '👉', '👈', '🙌', '🤲', '🙏', '💪', '🦾', '🦵', '🦿', '🦶', '👣', '👂',
  '🦻', '👃', '🧠', '🦷', '🦴', '👁️', '👀', '👅', '👄', '💋', '🩸', '👶', '🧒',
  '👦', '👧', '🧑', '👨', '👩', '🧓', '👴', '👵', '👨‍⚕️', '👩‍⚕️', '👨‍🎓', '👩‍🎓',
  '👨‍🏫', '👩‍🏫', '👨‍⚖️', '👩‍⚖️', '👨‍🌾', '👩‍🌾', '👨‍🍳', '👩‍🍳', '👨‍🔧', '👩‍🔧',
  '👨‍🏭', '👩‍🏭', '👨‍💼', '👩‍💼', '👨‍🔬', '👩‍🔬', '👨‍💻', '👩‍💻', '👨‍🎤', '👩‍🎤',
  '👨‍🎨', '👩‍🎨', '👨‍✈️', '👩‍✈️', '👨‍🚀', '👩‍🚀', '👨‍🚒', '👩‍🚒', '👮', '👮‍♀️',
  '🕵️', '🕵️‍♀️', '💂', '💂‍♀️', '👷', '👷‍♀️', '👸', '🤴', '👰', '🤵', '👼',
  '🤰', '🤱', '🙇', '🙇‍♀️', '💁', '💁‍♂️', '💁‍♀️', '🙅', '🙅‍♂️', '🙅‍♀️', '🙆',
  '🙆‍♂️', '🙆‍♀️', '🙋', '🙋‍♂️', '🙋‍♀️', '🧏', '🧏‍♂️', '🧏‍♀️', '🤦', '🤦‍♂️',
  '🤦‍♀️', '🤷', '🤷‍♂️', '🤷‍♀️', '🙎', '🙎‍♂️', '🙎‍♀️', '🙍', '🙍‍♂️', '🙍‍♀️',
  '💇', '💇‍♂️', '💇‍♀️', '💆', '💆‍♂️', '💆‍♀️', '🧖', '🧖‍♂️', '🧖‍♀️', '💅',
  '🤳', '💃', '🕺', '👯', '👯‍♂️', '👯‍♀️', '🕴️', '🚶', '🚶‍♂️', '🚶‍♀️', '🏃',
  '🏃‍♂️', '🏃‍♀️', '🧍', '🧍‍♂️', '🧍‍♀️', '🧎', '🧎‍♂️', '🧎‍♀️', '🧑‍🦯', '👨‍🦯',
  '👩‍🦯', '🧑‍🦼', '👨‍🦼', '👩‍🦼', '🧑‍🦽', '👨‍🦽', '👩‍🦽', '🏂', '🧗', '🧗‍♂️',
  '🧗‍♀️', '🚴', '🚴‍♂️', '🚴‍♀️', '🚵', '🚵‍♂️', '🚵‍♀️', '🛀', '🛌', '🕊️', '🦅',
  '🦆', '🦢', '🦉', '🦚', '🦜', '🦩', '🦤', '🦆', '🦜', '🐦', '🦔', '🦝', '🐇',
  '🦙', '🦦', '🦨', '🐁', '🐀', '🐿️', '🦔', '🦡', '🦇', '🐻', '🐨', '🐼', '🦘',
  '🦡', '🐾', '🦃', '🐔', '🐓', '🦢', '🦆', '🦩', '🦤', '🦚', '🦜', '🦞', '🦐',
  '🦀', '🦑', '🐙', '🦈', '🐚', '🐌', '🦋', '🐛', '🐜', '🐝', '🦟', '🐞', '🦗',
  '🕷️', '🦠', '💐', '🌸', '🌼', '🌻', '🍀', '🌺', '🍁', '🍂', '🍃', '🍇', '🍈',
  '🍉', '🍊', '🍋', '🍌', '🍍', '🥭', '🍎', '🍏', '🍐', '🍑', '🍒', '🍓', '🫐',
  '🥝', '🍅', '🥑', '🍆', '🥒', '🌶️', '🌽', '🍄', '🥜', '🌰', '🍞', '🥐', '🥖',
  '🥨', '🥯', '🥞', '🧇', '🧀', '🍖', '🍗', '🥩', '🍔', '🍟', '🍕', '🌭', '🍿',
  '🍲', '🍛', '🍜', '🍝', '🍠', '🍢', '🍣', '🍤', '🍥', '🥮', '🍡', '🥟', '🥠',
  '🥡', '🦀', '🦞', '🦐', '🦑', '🍦', '🍧', '🍨', '🍩', '🍪', '🎂', '🍰', '🧁',
  '🥧', '🍫', '🍬', '🍭', '🍮', '🍯', '🍼', '🥛', '☕', '🍵', '🍶', '🍾', '🍷',
  '🍸', '🍹', '🍺', '🍻', '🥂', '🥃', '🍽️', '🍴', '🥄', '🏺', '🥤', '🍶',
];

export default supportEmojis;
