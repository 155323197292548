import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { baseURL } from '../config';

export const getAllContacts = createAsyncThunk('leadsContacts/getAllContacts', async () => {
  const response = await crudData(baseURL, '/leads', 'GET');
  const { data } = await response.json();
  return data;
});

export const createContact = createAsyncThunk('leadsContacts/createContact', async (dataObj) => {
  const response = await crudData(baseURL, '/leads', 'POST', {}, {}, dataObj).then((res) => res.json());
  if (!response.success) {
    return message.error(response.message);
  }
  message.success('New Contact created successfully');
  return response.data;
});

export const updateContact = createAsyncThunk('leadsContacts/updateContact', async (updationObject) => {
  const response = await crudData(baseURL, '/leads/{id}', 'PUT', { id: updationObject.id }, {}, { ...updationObject });
  const { data } = await response.json();
  return data;
});

export const deleteContact = createAsyncThunk('leadsContacts/deleteContact', async (id) => {
  const response = await crudData(baseURL, '/leads/{id}', 'DELETE', { id }).then((res) => res.json());
  if (!response.success) {
    return message.error(response.message);
  }
  message.success(response.message);
  return response.data;
});

export const bulkDeleteContacts = createAsyncThunk('leadsContacts/bulkDeleteContacts', async (ids) => {
  const response = await crudData(baseURL, '/leads/bulk-delete', 'POST', {}, {}, { ids }).then((res) => res.json());
  if (!response.success) {
    return message.error(response.message);
  }
  message.success(response.message);
  return response.data;
});

const initialState = {
  data: [],
  status: requestStatuses.IDLE,
};

const leadsContacts = createSlice({
  name: 'leadsContacts',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllContacts.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getAllContacts.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getAllContacts.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(createContact.pending, (state) => {
        state.updateSubscriptionStatus = requestStatuses.LOADING;
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.updateSubscriptionStatus = requestStatuses.SUCCESS;
        state.data = [action.payload, ...state.data];
      })
      .addCase(createContact.rejected, (state) => {
        state.updateSubscriptionStatus = requestStatuses.FAILED;
      })
      .addCase(updateContact.pending, (state) => {
        state.updateSubscriptionStatus = requestStatuses.LOADING;
      })
      .addCase(updateContact.fulfilled, (state) => {
        state.updateSubscriptionStatus = requestStatuses.SUCCESS;
      })
      .addCase(updateContact.rejected, (state) => {
        state.updateSubscriptionStatus = requestStatuses.FAILED;
      })
      .addCase(deleteContact.pending, (state) => {
        state.updateSubscriptionStatus = requestStatuses.LOADING;
      })
      .addCase(deleteContact.fulfilled, (state) => {
        state.updateSubscriptionStatus = requestStatuses.SUCCESS;
      })
      .addCase(deleteContact.rejected, (state) => {
        state.updateSubscriptionStatus = requestStatuses.FAILED;
      });
  },
});

export default leadsContacts.reducer;
