// import { EditOutlined, LinkOutlined, UploadOutlined } from '@ant-design/icons';
import { LinkOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button, Modal, Input, Tag, Form, Checkbox, Popover, Space,
} from 'antd';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addHelpLinks } from '../../store/helpCMSSlice';

const maximumAllowedLinks = process.env.REACT_APP_MAX_LINKS || 100;

const weblinkDescription = `Add links to a product description, FAQ page, help center article, blog post, or
any other relevant webpage
you want the TelePilot AI to learn from.`;

function AddLinkModal({ visible, setVisible, setOpenAddNewModal }) {
  const dispatch = useDispatch();
  const [webLinks, setWebLinks] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [publiclyVisible, setPubliclyVisible] = useState(true);
  const [trainChatBot, setTrainChatBot] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const linksBox = useRef(null);

  const scrollToBottom = () => {
    const { current } = linksBox;
    if (current) {
      current.scrollTop = current.scrollHeight + 1;
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const validateURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name and extension
      + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
      + '(\\:\\d+)?' // port
      + '(\\/[-a-z\\d%@_.~+&:]*)*' // path
      + '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' // query string
      + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
  };

  const handleInputConfirm = () => {
    if (validateURL(inputValue) && webLinks.length < maximumAllowedLinks) {
      setWebLinks([...webLinks, inputValue]);
      setInputValue('');
      form.setFields([{
        name: 'webLink',
        value: '',
        errors: [],
      }]);
      scrollToBottom();
    } else if (!validateURL(inputValue)) {
      form.setFields([{
        name: 'webLink',
        errors: ['Invalid URL!'],
      }]);
    } else if (webLinks.length >= maximumAllowedLinks) {
      form.setFields([{
        name: 'webLink',
        errors: [`Maximum of ${maximumAllowedLinks} URLs can be added`],
      }]);
    }
  };

  const changePublicVisibility = () => {
    const currentState = publiclyVisible;
    setPubliclyVisible(!currentState);
  };

  const changeChatBotTrainable = () => {
    const currentState = trainChatBot;
    setTrainChatBot(!currentState);
  };

  const saveLinks = async () => {
    setLoading(true);
    await dispatch(addHelpLinks({ links: webLinks, publiclyVisible, trainChatBot }));
    setWebLinks([]);
    setInputValue('');
    form.setFields([{
      name: 'webLink',
      value: '',
      errors: [],
    }]);
    setLoading(false);
    setVisible(false);
    setOpenAddNewModal(false);
  };

  return (
    <Modal
      title="Add New Links To Your Documents"
      centered
      open={visible}
      onCancel={() => {}}
      footer={[
        <Button key="back" onClick={() => setVisible(false)} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={saveLinks} disabled={webLinks.length === 0} loading={loading}>
          Save
        </Button>,
      ]}
      closable={false}
      width={900}
    >
      <div className="flex flex-col p-4 m-2 justify-around w-[95%] overflow-scroll">
        <Form form={form}>
          <p className="text-base text-gray-600 mb-2">
            {weblinkDescription}
          </p>
          <Form.Item name="webLink">
            <Space.Compact className="mt-2 w-[95%]">
              <Input
                addonBefore={<LinkOutlined />}
                type="text"
                size="large"
                className="overflow-scroll"
                placeholder="Insert link and press enter"
                value={inputValue}
                onChange={handleInputChange}
                onPressEnter={handleInputConfirm}
              />
              <Button onClick={handleInputConfirm} size="large" disabled={loading}>Add</Button>
            </Space.Compact>
          </Form.Item>
        </Form>
        <p className="m-2 my-4">
          Added Links:
          {' '}
          {webLinks.length}
        </p>
        <div
          ref={linksBox}
          className="flex flex-col items-start justify-start justify-items-start w-[95%] h-40 overflow-y-scroll overflow-x-scroll border border-solid border-gray-200 rounded"
        >
          {
                  webLinks.length > 0 ? webLinks.map((link) => (
                    <Tag
                      key={link}
                      closable
                      onClose={() => setWebLinks(webLinks.filter((l) => l !== link))}
                      className="w-fit h-fit m-1"
                      color="geekblue"
                    >
                      {link}
                    </Tag>
                  )) : <p className="text-gray-400 m-2">No links added yet</p>
                }
        </div>
        <div className="flex flex-col m-2">
          {/* <div className="flex items-center">
            <Checkbox className="m-1" checked={publiclyVisible} onChange={changePublicVisibility} disabled={loading}>
              Publicly Visible
            </Checkbox>
            <Popover content="Publicly visible links will be displayed on your help center page." placement="right">
              <QuestionCircleOutlined />
            </Popover>
          </div> */}
          {/* <div className="flex items-center">
            <Checkbox className="m-1" checked={trainChatBot} onChange={changeChatBotTrainable} disabled={loading}>
              Use to train TelePilot AI
            </Checkbox>
            <Popover content="TelePilot AI will learn from the content of the links you add for its automated responses." placement="right">
              <QuestionCircleOutlined />
            </Popover>
          </div> */}
        </div>
      </div>
    </Modal>
  );
}
export default AddLinkModal;
