/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import {
  Button, Modal, Steps, Tooltip,
} from 'antd';
import { useDispatch } from 'react-redux';
import OnboardingAddDoc from '../onboarding/OnboardingAddDoc';
import OnboardingAddWidget from '../onboarding/OnboardingAddWidget';
import OnboardingFinish from '../onboarding/OnboardingFinish';
import { updateOnboarding } from '../../store/onboardingSlice';
import OnboardingCreateCoPilot from '../onboarding/OnboardingCreateCoPilot';
import { getUsageCounter } from '../../store/usageCounterSlice';

function UserOnboarding({ visible, setVisible, setOpenSupportModal }) {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [onboardingFinished, setOnboardingFinished] = useState(false);
  const [helpDocPresent, setHelpDocPresent] = useState(false);
  const [widgetIntegrated, setWidgetIntegrated] = useState(false);
  const [copilotCreated, setCopilotCreated] = useState(false);
  const [tooltipValue, setTooltipValue] = useState('Please complete all steps to finish');
  const [nextDisabled, setNextDisabled] = useState(false);

  useEffect(() => {
    dispatch(getUsageCounter());
  }, [visible]);

  useEffect(() => {
    if (step === 0 && !helpDocPresent) {
      setTooltipValue('Please add your first HelpDoc to continue');
      setNextDisabled(true);
    } else if (step === 1 && !copilotCreated) {
      setTooltipValue('Please create your CoPilot to continue');
      setNextDisabled(true);
    } else if (step === 2 && !widgetIntegrated) {
      setTooltipValue('Please integrate your widget to continue');
    } else if (step === 3) {
      setTooltipValue('Close');
    } else {
      setNextDisabled(false);
    }
  }, [step, helpDocPresent, copilotCreated, widgetIntegrated]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const handleFinish = async () => {
    await dispatch(updateOnboarding({
      confetti_blown: true,
      guided_tour_completed: true,
      onboarding_completed: true,
    }));

    setStep(step + 1);
    setOnboardingFinished(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const onboardingSteps = [
    {
      title: 'Train your chatbot',
      content: <OnboardingAddDoc helpDocPresent={helpDocPresent} setHelpDocPresent={setHelpDocPresent} setVisible={setVisible} />,
    },
    {
      title: 'Integrate Widget',
      content: <OnboardingCreateCoPilot copilotCreated={copilotCreated} setCopilotCreated={setCopilotCreated} />,
    },
    {
      title: 'Integrate Widget',
      content: <OnboardingAddWidget widgetIntegrated={widgetIntegrated} setWidgetIntegrated={setWidgetIntegrated} />,
    },
    {
      title: 'Onboarding Finished',
      content: <OnboardingFinish />,
    },
  ];

  return (
    <Modal
      title="Get started with TelePilot AI - Create your first chatbot"
      centered
      open={visible}
      onCancel={handleClose}
      footer={[
        <Button className={`${step === 0 || step === 2 ? 'hidden' : ''}`} key="back" onClick={previousStep}>
          Back
        </Button>,
        <Tooltip className="mx-2" title={tooltipValue}>
          <Button
            key="submit"
            type="primary"
            disabled={nextDisabled}
            onClick={step === 3 && onboardingFinished ? handleClose : step === 2 ? handleFinish : nextStep}
          >
            {step === 3 ? 'Close' : step === 2 ? 'Finish' : 'Next'}
          </Button>
        </Tooltip>,
      ]}
      width={900}
    >
      <div className="p-2 mx-2 my-8">
        <Steps
          current={step}
          items={[
            {
              title: 'Step 1',
              description: <span className="w-40 flex">Add your first HelpDoc *</span>,
            },
            {
              title: 'Step 2',
              description: <span className="w-40 flex">Create your CoPilot *</span>,
            },
            {
              title: 'Step 3',
              description: 'Integrate your widget',
            },
            {
              title: 'Finish',
              description: '',
            },
          ]}
        />
        <div>
          {onboardingSteps[step].content}
        </div>
        <div className="text-gray-500">
          Facing issues? Feel free to
          <Button className="ml-1 p-0" type="link" onClick={() => setOpenSupportModal(true)}>
            contact support
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default UserOnboarding;
