/* eslint-disable consistent-return */
import {
  // Card,
  // Button,
  Col, Row,
  // message,
} from 'antd';
import { useEffect, useState } from 'react';
// import { PlusCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import CurrentPlanCard from './CurrentPlanCard';
import BillingInformationCard from './BillingInformationCard';
// import PaymentMethodCard from './PaymentMethodCard';
import OrderHistoryTable from './OrderHistoryTable';
// import PlusCircleOutline from '../../../assets/plus-circle-outline.svg';
import { getSavedPaymentMethods } from '../../../store/paymentMethodsSlice';
// import { billingURL } from '../../../config';
// import crudData from '../../../utils/apiService';
import CancellationConfirm from './CancellationConfirm';

function BillingSettings({ setSelectedTab }) {
  const dispatch = useDispatch();
  const { data: billingData } = useSelector((state) => state.billing);
  // const { data: savedPaymentMethods } = useSelector((state) => state.paymentMethods);

  const [openCancellationConfirm, setOpenCancellationConfirm] = useState(false);

  useEffect(() => {
    dispatch(getSavedPaymentMethods());
  }, []);

  // const addNewPaymentMethod = async () => {
  //   const response = await crudData(billingURL, '/checkout/setup', 'POST').then((res) => res.json());
  //   if (!response.success) {
  //     return message.error(response.message);
  //   }
  //   const { sessionURL } = response.data;
  //   window.location.href = sessionURL;
  // };

  return (
    <>
      <div className="flex flex-col py-2 px-4 text-start">
        <Row className="flex justify-between">
          <Col span={12}>
            <p className="text-xl font-semibold">Current Plan</p>
            <CurrentPlanCard
              setSelectedTab={setSelectedTab}
              setOpenCancellationConfirm={setOpenCancellationConfirm}
            />
          </Col>
          {billingData && Object.keys(billingData).length > 0 && (
          <Col span={12}>
            <p className="text-xl font-semibold">Billing Information</p>
            <BillingInformationCard data={billingData} />
          </Col>
          )}
        </Row>
        {/* <Row>
          <div className="my-4">
            <p className="text-xl font-semibold">Payment Methods</p>
            <div className="flex items-center flex-wrap">
              {savedPaymentMethods.length > 0 && savedPaymentMethods.map((card) => (
                <PaymentMethodCard key={card.last4} cardDetails={card} />
              ))}
              <Card
                className="border border-dashed border-gray-400 rounded py-8 px-8 mx-4 cursor-pointer hover:bg-blue-50"
                onClick={addNewPaymentMethod}
              >
                <div className="flex flex-col items-center">
                  <img src={PlusCircleOutline} alt="plus-circle-outline" className="w-12 h-12 mb-4" />
                  <span className="font-semibold text-[#5272B0]">Add New Payment Method</span>
                </div>
              </Card>
            </div>
          </div>
        </Row> */}
        <Row>
          <div className="w-full h-full my-4">
            <p className="text-xl font-semibold">Order History</p>
            <OrderHistoryTable />
          </div>
        </Row>
      </div>
      <CancellationConfirm visible={openCancellationConfirm} setVisible={setOpenCancellationConfirm} />
    </>
  );
}

export default BillingSettings;
