import { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

function CelebrationConfetti({ duration }) {
  const [numberOfPieces, setNumberOfPieces] = useState(500);

  useEffect(() => {
    const decreaseConfetti = () => {
      setNumberOfPieces((prev) => Math.max(prev - 150, 0)); // decrease by 150 every interval
    };

    // Start decreasing confetti after 5 seconds
    const startTimer = setTimeout(() => {
      const interval = setInterval(decreaseConfetti, 300); // decrease every micro second

      return () => clearInterval(interval); // Cleanup interval
    }, duration * 1000 || 3000);

    return () => clearTimeout(startTimer); // Cleanup timer
  }, []);

  return (
    <div>
      {numberOfPieces > 0 && <Confetti numberOfPieces={numberOfPieces} />}
    </div>
  );
}

export default CelebrationConfetti;
