import { CheckCircleTwoTone, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { useState } from 'react';

function TitleStyle({ name, price, currency }) {
  return (
    <div className="flex justify-between items-center">
      <span className="font-bold text-lg m-2">{name}</span>
      <div className="flex items-center">
        <span className="text-xl font-bold">
          {currency === 'USD' ? '$' : currency}
          {price}
          {' '}
        </span>
        <span className="text-gray-500"> /add-on</span>
      </div>
    </div>
  );
}

function AddOnCard({ planDetails, initiatePurchase }) {
  const {
    'Product.id': productId, 'Product.name': productName, 'Product.features': features, unit_amount, currency,
  } = planDetails;
  const price = parseFloat(unit_amount);
  const [quantity, setQuantity] = useState(1);
  const [loadingPurchase, setLoadingPurchase] = useState(false);
  const handleQuantityChange = (value) => {
    if (value >= 1 && value <= 99) {
      setQuantity(value);
    }
  };
  const handlePurchaseInit = async () => {
    setLoadingPurchase(true);
    await initiatePurchase(productId, quantity);
    setLoadingPurchase(false);
  };
  return (
    <Card style={{ width: 330, height: 'fit-content' }} title={<TitleStyle name={productName} price={price} currency={currency} />} className="my-4 mx-2 bg-green-50" loading={!planDetails}>
      <div className="flex flex-col justify-between w-full">
        <span className="text-gray-700 mb-2">
          Each
          {' '}
          <strong>{productName}</strong>
          {' '}
          adds following to your current plan:
        </span>
        <div className="flex flex-col mb-8 m-auto">
          {features.map((feature) => (
            <div className="flex items-center text-gray-900 my-2" key={feature}>
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              <span className="mx-2">{feature}</span>
            </div>
          ))}
        </div>
        <div className="flex items-center space-x-4 mb-4 m-auto">
          <Button
            type="text"
            icon={<MinusOutlined />}
            onClick={() => handleQuantityChange(quantity - 1)}
          />
          <div
            className="w-8 h-7 text-center border border-solid border-blue-500 rounded font-semibold text-lg bg-white text-gray-600"
          >
            {quantity}
          </div>
          <Button
            type="text"
            icon={<PlusOutlined />}
            onClick={() => handleQuantityChange(quantity + 1)}
          />
        </div>
        <div className="flex justify-center w-full">
          <Button loading={loadingPurchase} className="w-full" type="primary" onClick={handlePurchaseInit}>
            {`Purchase ${quantity} Booster(s)`}
          </Button>
        </div>
        <div className="flex flex-col mt-4 w-full text-gray-700">
          <span>
            Total:
            {' '}
            <strong>
              {currency === 'USD' ? '$' : currency}
              {quantity * price}
            </strong>
          </span>
          <span>Next: Complete Payment</span>
        </div>
      </div>
    </Card>
  );
}

export default AddOnCard;
