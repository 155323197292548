import { Layout, Menu, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';

const { Content } = Layout;

const planBillingOptions = [
  {
    key: 'billing',
    label: 'Billing',
  },
  {
    key: 'plans',
    label: 'Plans',
  },
];

function PlanBillingLayout({ tabSelection, children }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      {/* DESKTOP VIEW */}
      <div className="hidden sm:flex">
        <Layout hasSider>
          <Sider
            style={{
              background: colorBgContainer,
            }}
          >
            <Menu
              mode="inline"
              selectedKeys={[tabSelection.selectedTab]}
              style={{ height: '100%' }}
            >
              {planBillingOptions.map((item) => (
                <Menu.Item key={item.key} onClick={() => tabSelection.setSelectedTab(item.key)}>
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Layout>
            <Content
              className="bg-white overflow-scroll h-full font-nunito"
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      </div>

    </>
  );
}
export default PlanBillingLayout;
