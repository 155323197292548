import {
  Button, Card, Form, Input, Select, Typography,
} from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateBillingDetails } from '../../../store/billingSlice';
import countriesWithTZCode from '../../../constants/countriesWithTZCode.json';

function BillingInformationCard({ data }) {
  const dispatch = useDispatch();

  const { Option } = Select;
  const { Paragraph, Text } = Typography;

  const { countries } = countriesWithTZCode;

  const [editing, setEditing] = useState(false);
  const [countryOptions, setCountryOptions] = useState([...countries]);
  const [form] = Form.useForm();

  const handleCountrySearch = (value) => {
    const filteredOptions = countries.filter(
      (option) => option.name.toLowerCase().includes(value.toLowerCase()),
    );
    setCountryOptions(filteredOptions);
  };

  const handleBillingDetailsUpdate = (formdata) => {
    dispatch(updateBillingDetails(formdata));
    setEditing(false);
  };

  return (
    <div>
      {!editing ? (
        <Card
          style={{ width: 500, minHeight: 285 }}
          className="border-solid border-gray-100"
          loading={!data}
        >
          <div className="flex flex-col justify-between">
            <div className="flex flex-col justify-around">
              <div className="flex w-full mb-2">
                <span className="font-semibold w-20">Name:</span>
                <Text
                  style={{ width: 350 }}
                  ellipsis={{ tooltip: data?.name }}
                >
                  {data?.name}
                </Text>
              </div>
              <div className="flex items-center w-full mb-2">
                <span className="font-semibold w-20">Email:</span>
                <Text
                  style={{ width: 350 }}
                  ellipsis={{ tooltip: data?.email }}
                >
                  {data?.email}
                </Text>
              </div>
              <div className="flex items-center w-full mb-2">
                <span className="font-semibold w-20">Phone:</span>
                <span className="">{data?.phone ? data.phone : 'NA'}</span>
              </div>
              <div className="flex items-start mb-2">
                <span className="font-semibold w-20">Address:</span>
                {/* <div className="flex flex-col h-fit items-start"> */}
                {/* <span className="w-96"> */}
                <Paragraph style={{ width: 350 }} ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
                  {data.Address?.address}
                  ,
                  {' '}
                  {data.Address?.city}
                  ,
                  {' '}
                  {data.Address?.state}
                  ,
                  {' '}
                  {data.Address?.postal_code}
                </Paragraph>
                {/* </span> */}
                {/* </div> */}
              </div>
              <div className="flex items-start mb-2">
                <span className="font-semibold w-20">Country:</span>
                <Text
                  style={{ width: 350 }}
                  ellipsis={{ tooltip: data.Address?.country }}
                >
                  {data.Address?.country}
                </Text>
              </div>
            </div>
            <div>
              <Button className="mt-4" onClick={() => setEditing(true)}>Update Billing Information</Button>
            </div>
          </div>
        </Card>
      )
        : (
          <Card
            style={{ width: 500, height: 550 }}
            className="border-solid border-accent1 shadow-lg flex p-0 items-start"
            loading={!data}
          >
            <Form
              form={form}
              labelCol={{ span: 6 }}
              // wrapperCol={{ span: 24 }}
              style={{
                width: 400,
                margin: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'start',
                justifyContent: 'space-between',
              }}
              initialValues={{
                name: data?.name,
                email: data?.email,
                phone: data?.phone,
                address: data.Address?.address,
                city: data.Address?.city,
                state: data.Address?.state,
                postal_code: data.Address?.postal_code,
                country: data.Address?.country,
                remember: true,
              }}
              onFinish={handleBillingDetailsUpdate}
            // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: true, message: 'Please enter the name' },
                  { max: 100, message: 'Maximum 100 characters' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  { max: 20, message: 'Maximum 20 characters' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Please enter the email' },
                  { type: 'email', message: 'Please enter a valid email address' },
                  { max: 100, message: 'Maximum 100 characters' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  { required: true, message: 'Please enter the address' },
                  { max: 200, message: 'Maximum 200 characters' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  { pattern: /^[A-Za-z\d\s\-']+$/, message: 'Invalid city name' },
                  { max: 100, message: 'Maximum 100 characters' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  { required: true, message: 'Please enter the state/province' },
                  { pattern: /^[A-Za-z\d\s\-']+$/, message: 'Invalid state name' },
                  { max: 100, message: 'Maximum 100 characters' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="postal_code"
                label="Postal Code"
                rules={[
                  { required: true, message: 'Please enter the ZIP/Postal Code' },
                  { pattern: /^[A-Za-z\d\s-]+$/, message: 'Invalid postal code' },
                  { max: 20, message: 'Maximum 20 characters' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="country"
                label={(
                  <span className="flex items-center">
                    Country
                  </span>
          )}
                rules={[{ required: true, message: 'Please select your country' }]}
              >
                <Select
                  showSearch
                  placeholder="Select your country"
                  onSearch={handleCountrySearch}
                  filterOption={false}
                  className="w-full"
                >
                  {countryOptions.map((country) => (
                    <Option className="text-black" key={country.name} value={country.name}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="flex w-full"
              >
                <Button className="mr-2" htmlType="button" onClick={() => setEditing(false)}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
    </div>
  );
}

export default BillingInformationCard;
