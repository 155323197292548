import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { baseURL } from '../config';

export const getOnboarding = createAsyncThunk('onboarding/getOnboarding', async () => {
  const response = await crudData(baseURL, '/accounts/onboarding', 'GET');
  const { data } = await response.json();
  return data;
});

export const updateOnboarding = createAsyncThunk('onboarding/updateOnboarding', async (updatedOnboarding) => {
  const response = await crudData(baseURL, '/accounts/onboarding', 'PUT', {}, {}, updatedOnboarding);
  const { data } = await response.json();
  return data;
});

const initialState = {
  data: {},
  status: requestStatuses.IDLE,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getOnboarding.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getOnboarding.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getOnboarding.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(updateOnboarding.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(updateOnboarding.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(updateOnboarding.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      });
  },
});

export default onboardingSlice.reducer;
