import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { billingURL } from '../config';

export const getBillingDetails = createAsyncThunk('billing/getBillingDetails', async () => {
  const response = await crudData(billingURL, '/billing-entities/default', 'GET');
  const { data } = await response.json();
  return data;
});

export const updateBillingDetails = createAsyncThunk('billing/updateBillingDetails', async (newBillingDetails) => {
  const response = await crudData(billingURL, '/billing-entities', 'PUT', {}, {}, newBillingDetails).then((res) => res.json());
  if (!response.success) {
    return message.error(response.message);
  }
  message.success(response.message);
  return response.data;
});

export const addBillingDetails = createAsyncThunk('billing/addBillingDetails', async (billingDetails) => {
  const response = await crudData(billingURL, '/billing-entities', 'POST', {}, {}, billingDetails).then((res) => res.json());
  if (!response.success) {
    return message.error(response.message);
  }
  message.success(response.message);
  return response.data;
});

const initialState = {
  data: [],
  status: requestStatuses.IDLE,
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBillingDetails.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getBillingDetails.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getBillingDetails.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(updateBillingDetails.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(updateBillingDetails.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(updateBillingDetails.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(addBillingDetails.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(addBillingDetails.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(addBillingDetails.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      });
  },
});

export default billingSlice.reducer;
