import { DeleteOutlined } from '@ant-design/icons';
import {
  Button, Modal,
} from 'antd';

function DeleteConfirmation({
  visible, setVisible, deleteDoc, docTitle,
}) {
  const handleDelete = () => {
    deleteDoc();
    setVisible(false);
  };
  return (
    <Modal
      title={`Delete ${docTitle}?`}
      centered
      open={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button danger key="submit" icon={<DeleteOutlined />} onClick={handleDelete}>
          Delete
        </Button>,
      ]}
      width={600}
    >
      <p className="mt-8">
        Are you sure you want to delete this doc? It will be permanently removed and cannot be undone.
      </p>
      <p className="mb-8">If you don&apos;t want the doc to be visible, you can choose to unpublish it.</p>
    </Modal>
  );
}
export default DeleteConfirmation;
