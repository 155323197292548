/* eslint-disable consistent-return */
import {
  Button, Form, Input, Modal, Select, message,
} from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import countriesWithTZCode from '../../constants/countriesWithTZCode.json';
import { addBillingDetails } from '../../store/billingSlice';
import crudData from '../../utils/apiService';
import { billingURL } from '../../config';

function BillingPopup({
  visible, setVisible, newRequestedProduct,
}) {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { countries } = countriesWithTZCode;
  const [countryOptions, setCountryOptions] = useState([...countries]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCountrySearch = (value) => {
    const filteredOptions = countries.filter(
      (option) => option.name.toLowerCase().includes(value.toLowerCase()),
    );
    setCountryOptions(filteredOptions);
  };

  const continuePurchase = async (productId, period) => {
    const response = await crudData(billingURL, '/checkout/subscription', 'POST', {}, {}, { productId, period }).then((res) => res.json());
    if (!response.success) {
      return message.error(response.message);
    }
    const { sessionURL } = response.data;
    window.location.href = sessionURL;
  };

  const addNewBillingDetails = async (formdata) => {
    try {
      setLoading(true);
      const res = await dispatch(addBillingDetails(formdata));
      if (res.error) {
        setVisible(false);
        return message.error('Error saving details, please try again later.');
      }
      setVisible(false);
      setLoading(false);
      return continuePurchase(newRequestedProduct.productId, newRequestedProduct.period);
    } catch (error) {
      return message.error('Error saving details, please try again later.');
    }
  };

  return (
    <Modal
      title="Add Billing Details"
      centered
      open={visible}
      closable={false}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Save Billing Details
        </Button>,
      ]}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
              // wrapperCol={{ span: 24 }}
        style={{
          width: 450,
          marginTop: 30,
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'start',
          justifyContent: 'space-between',
        }}
        onFinish={addNewBillingDetails}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: 'Please enter the name' },
            { max: 100, message: 'Maximum 100 characters' },
          ]}
        >
          <Input
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          rules={[
            { max: 20, message: 'Maximum 20 characters' },
          ]}
        >
          <Input
            maxLength={20}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please enter the email' },
            { type: 'email', message: 'Please enter a valid email address' },
            { max: 100, message: 'Maximum 100 characters' },
          ]}
        >
          <Input
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          rules={[
            { required: true, message: 'Please enter the address' },
            { max: 200, message: 'Maximum 200 characters' },
          ]}
        >
          <Input.TextArea
            maxLength={200}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item
          name="city"
          label="City"
          rules={[
            { pattern: /^[A-Za-z\d\s\-']+$/, message: 'Invalid city name' },
            { max: 100, message: 'Maximum 100 characters' },
          ]}
        >
          <Input
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          name="state"
          label="State"
          rules={[
            { required: true, message: 'Please enter the state/province' },
            { pattern: /^[A-Za-z\d\s\-']+$/, message: 'Invalid state name' },
            { max: 100, message: 'Maximum 100 characters' },
          ]}
        >
          <Input
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          name="postal_code"
          label="Postal Code"
          rules={[
            { required: true, message: 'Please enter the ZIP/Postal Code' },
            { pattern: /^[A-Za-z\d\s-]+$/, message: 'Invalid postal code' },
            { max: 20, message: 'Maximum 20 characters' },
          ]}
        >
          <Input
            maxLength={20}
          />
        </Form.Item>
        <Form.Item
          name="country"
          label={(
            <span className="flex items-center">
              Country
            </span>
          )}
          rules={[{ required: true, message: 'Please select your country' }]}
        >
          <Select
            showSearch
            placeholder="Select your country"
            onSearch={handleCountrySearch}
            filterOption={false}
            className="w-full"
          >
            {countryOptions.map((country) => (
              <Option className="text-black" key={country.name} value={country.name}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default BillingPopup;
