import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../components/common/SideBar';
import HeaderBar from '../components/common/HeaderBar';
import AlertBanner from '../components/common/AlertBanner';
import { updateOnboarding } from '../store/onboardingSlice';
import UserOnboarding from '../components/common/UserOnboarding';
import CelebrationConfetti from '../components/common/CelebrationConfetti';
import VideoModal from '../components/common/VideoModal';
import { urls } from '../config';

import gettingStartedVideoCaptions from '../assets/video-captions/getting-started-with-tpai.vtt';

const { Content, Footer } = Layout;

function MainLayout({ children }) {
  const dispatch = useDispatch();
  const { data: onboarding } = useSelector((state) => state.onboarding);
  const [openGuidedTour, setOpenGuidedTour] = useState(false);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);
  const [openSupportModal, setOpenSupportModal] = useState(false);
  const [openOnboardingVideo, setOpenOnboardingVideo] = useState(false);

  useEffect(() => {
    if (onboarding.guided_tour_completed === false) {
      setOpenGuidedTour(true);
      dispatch(updateOnboarding({ guided_tour_completed: true }));
    }
  }, [onboarding]);

  useEffect(() => {
    if (onboarding.onboarding_completed === false && onboarding.guided_tour_completed === true && openGuidedTour === false) {
      if (onboarding.confetti_blown === false) {
        dispatch(updateOnboarding({ confetti_blown: true }));
      }
      setTimeout(() => { setOpenOnboardingModal(true); }, 1000);
      setTimeout(() => { setOpenOnboardingVideo(true); }, 1000);
    }
  }, [onboarding, openGuidedTour]);

  return (
    <>
      {onboarding.confetti_blown === false && <CelebrationConfetti />}
      {/* DESKTOP VIEW */}
      <div className="hidden sm:flex">
        <Layout hasSider>
          <SideBar openGuidedTour={openGuidedTour} setOpenGuidedTour={setOpenGuidedTour} />
          <Layout
            style={{
              marginLeft: 200,
            }}
          >
            <HeaderBar
              setOpenGuidedTour={setOpenGuidedTour}
              onboardingComplete={onboarding.onboarding_completed}
              setOpenOnboardingModal={setOpenOnboardingModal}
              openSupportModal={openSupportModal}
              setOpenSupportModal={setOpenSupportModal}
              setOpenOnboardingVideo={setOpenOnboardingVideo}
            />
            <Content
              className="bg-white rounded-lg my-2 mx-2 overflow-scroll h-[90vh] font-nunito"
            >
              <AlertBanner />
              <UserOnboarding visible={openOnboardingModal} setVisible={setOpenOnboardingModal} setOpenSupportModal={setOpenSupportModal} />
              <VideoModal
                visible={openOnboardingVideo}
                setVisible={setOpenOnboardingVideo}
                videoTitle="Getting Started with TelePilot AI"
                videoUrl={urls.getStarted.video}
                // subtitlesUrl={urls.getStarted.captions}
                subtitlesFile={gettingStartedVideoCaptions}
                thumbnaillUrl={urls.getStarted.thumbnail}
              />
              {children}
            </Content>
          </Layout>
        </Layout>
      </div>

      {/* MOBILE VIEW */}
      <div className="sm:hidden">
        <Layout>
          <HeaderBar />
          <Content
            className="bg-white rounded-lg my-8 mx-4 overflow-scroll h-[85vh] p-4"
          >
            <div className="bg-white rounded p-2 text-start">
              <div className="flex flex-col w-full">
                <h2 className="text-xl font-semibold mb-4">TelePilot AI Setup</h2>
                <p className="text-gray-800 w-full">
                  {`To continue accessing our services seamlessly, we recommend switching to a laptop, computer, or tablet. 
                Certain steps, like integrating our chat widget script into your website, may be necessary.`}
                </p>
                <p className="text-gray-800 w-full">Please follow these instructions:</p>
                <p className="text-gray-800 w-full m-2">1. Open a web browser on your laptop, computer, or tablet.</p>
                <p className="text-gray-800 w-full m-2">2. Visit https://www.telepilot.ai</p>
                <p className="text-gray-800 w-full m-2">3. Log in using your existing credentials.</p>
                <p className="text-gray-800 w-full m-2">4. Integrate our chat widget script as per the provided documentation.</p>
                <p className="text-gray-800 w-full">Once integrated, enjoy our services on your laptop, computer, or tablet.</p>
                <div>
                  <p className="text-gray-800 w-full mt-12">
                    If you have any additional questions, please feel free to contact us at
                    {' '}
                    <a href="mailto:support@telepilot.ai">
                      support@telepilot.ai
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Content>
          <Footer
            style={{
              textAlign: 'center',
            }}
          >
            TelePilot AI ©2023
          </Footer>
        </Layout>
      </div>
    </>
  );
}
export default MainLayout;
