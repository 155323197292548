import {
  Button,
  //   Button,
  Col, Divider, Input, Popover, Row, Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ReloadOutlined, SmileOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import WidgetCard from './WidgetCard';
import supportEmojis from '../../constants/emojis';
import { updateWidgetConfigurations } from '../../store/widgetConfigurationsSlice';

function TextOptions({ onSelectEmoji, field }) {
  return (
    <div className="flex flex-col w-72 h-24 overflow-scroll">
      <div className="flex flex-wrap">
        {supportEmojis.map((emoji) => (
          <Button
            size="large"
            ghost
            key={emoji}
            className="cursor-pointer p-2"
            onClick={() => onSelectEmoji(emoji, field)}
          >
            {emoji}
          </Button>
        ))}
      </div>
    </div>
  );
}

function Flow({
  copilotId,
  chatHeading,
  setChatHeading,
  chatSubHeading,
  setChatSubHeading,
  welcomeMessage,
  setWelcomeMessage,
  selectedColor,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: usageLimit } = useSelector((state) => state.usageLimit);

  const [loading, setLoading] = useState(false);
  const [widgetCustomizable, setWidgetCustomizable] = useState(false);
  const [initialChatHeading, setInitialChatHeading] = useState(chatHeading);
  const [initialChatSubHeading, setInitialChatSubHeading] = useState(chatSubHeading);
  const [initialWelcomeMessage, setInitialWelcomeMessage] = useState(welcomeMessage);
  const handleHeadingChange = (e) => {
    setChatHeading(e.target.value);
  };
  const handleSubHeadingChange = (e) => {
    setChatSubHeading(e.target.value);
  };
  const handleWelcomeMessageChange = (e) => {
    setWelcomeMessage(e.target.value);
  };

  useEffect(() => {
    const isCustomizable = usageLimit && usageLimit.widget_customizable;
    setWidgetCustomizable(!!isCustomizable);
    if (!initialChatHeading || !initialChatSubHeading || !initialWelcomeMessage || usageLimit) {
      setInitialChatHeading(chatHeading);
      setInitialChatSubHeading(chatSubHeading);
      setInitialWelcomeMessage(welcomeMessage);
    }
  }, [usageLimit]);

  const handleEmojiSelect = (emoji, field) => {
    if (field === 'heading') {
      setChatHeading(chatHeading + emoji);
    } else if (field === 'subheading') {
      setChatSubHeading(chatSubHeading + emoji);
    }
  };

  const resetFlow = () => {
    setChatHeading('Hi there! 👋');
    setChatSubHeading('Need help with something?');
    setWelcomeMessage('Hello, how can I help you today?');
  };

  const saveFlowChanges = async () => {
    setLoading(true);
    const data = {
      chat_heading: chatHeading,
      chat_subheading: chatSubHeading,
      welcome_message: welcomeMessage,
    };
    await dispatch(updateWidgetConfigurations({ id: copilotId, updates: data }));
    setLoading(false);
    // Update initial values after saving changes
    setInitialChatHeading(chatHeading);
    setInitialChatSubHeading(chatSubHeading);
    setInitialWelcomeMessage(welcomeMessage);
  };

  const hasChanges = chatHeading !== initialChatHeading || chatSubHeading !== initialChatSubHeading || welcomeMessage !== initialWelcomeMessage;

  return (
    <div className="px-2 m-2 h-full relative">
      <Row className={`h-full ${widgetCustomizable ? 'opacity-100' : 'opacity-20'}`} gutter={16}>
        <Col span={12}>
          <div className="flex flex-col items-start h-full w-5/6 text-start">
            <div className="w-full">
              <h4>Chat Heading</h4>
              <div className="flex">
                <Input
                  placeholder="Hi there 👋"
                  onChange={handleHeadingChange}
                  value={chatHeading}
                  maxLength={30}
                  suffix={(
                    <Popover content={<TextOptions onSelectEmoji={handleEmojiSelect} field="heading" />} placement="bottom" mouseLeaveDelay={1}>
                      <SmileOutlined />
                    </Popover>
                  )}
                />
              </div>
            </div>
            <div className="w-full">
              <h4>Chat Subheading</h4>
              <div className="flex">
                <Input
                  placeholder="Need help with something?"
                  onChange={handleSubHeadingChange}
                  value={chatSubHeading}
                  maxLength={40}
                  suffix={(
                    <Popover content={<TextOptions onSelectEmoji={handleEmojiSelect} field="subheading" />} placement="bottom" mouseLeaveDelay={1}>
                      <SmileOutlined />
                    </Popover>
                )}
                />
              </div>
            </div>
            <div className="w-full">
              <h4>Welcome Message</h4>
              <div className="flex">
                <TextArea
                  placeholder="Hello, how can I help you today?"
                  autoSize={{ minRows: 6, maxRows: 6 }}
                //   className="w-5/6"
                  onChange={handleWelcomeMessageChange}
                  value={welcomeMessage}
                  maxLength={500}
                />
                {/* <div className="flex h-fit items-center">
                  <p className="ml-2">include: </p>
                  <Tag className="h-fit mx-2" color="blue">Name</Tag>
                </div> */}
              </div>
            </div>
            <Button type="dashed" className="my-4" icon={<ReloadOutlined />} onClick={resetFlow}>Reset to Default</Button>
            <Button type="primary" className="my-4" onClick={saveFlowChanges} disabled={!widgetCustomizable || !hasChanges} loading={loading}>Save Changes</Button>
          </div>
        </Col>
        <Col span={2}>
          <Divider className="h-full" type="vertical" />
        </Col>
        <Col span={10}>
          <div className="flex justify-center h-full">
            <WidgetCard
              chatHeading={chatHeading}
              chatSubHeading={chatSubHeading}
              welcomeMessage={welcomeMessage}
              selectedColor={selectedColor}
            />
          </div>
        </Col>
      </Row>
      {!widgetCustomizable && (
      <div className="absolute inset-0 flex items-center justify-center z-10 cursor-default">
        <Typography.Text className="bg-white py-4 px-2 rounded shadow-md">
          Flow customizations available from standard plans. Please
          {' '}
          <Button className="m-0 p-0" type="link" onClick={() => navigate('/settings/billing-plans')}>upgrade your plan</Button>
          {' '}
          to continue
        </Typography.Text>
      </div>
      )}
    </div>
  );
}

export default Flow;
