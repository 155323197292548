import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Tabs } from 'antd';
import { getCoPilotDetails } from '../../store/copilotsSlice';
import { setNav } from '../../store/navSlice';
import Code from '../widget/Code';
import WidgetPage from './WidgetPage';
import Conversations from '../detailedcopilots/conversations/Conversations';

function DetailedCoPilotsPage() {
  const dispatch = useDispatch();
  const { copilotId } = useParams();
  const { selectedCoPilot: copilot } = useSelector((state) => state.copilots);

  const [activeKey, setActiveKey] = React.useState('conversations');

  const copilotsPageInfo = `Create and manage all your chatbots from here. 
  Find a detailed view of chatbot below, you can manage conversations,
   support queries, customization and installation from here.`;

  useEffect(() => {
    dispatch(setNav({ title: 'CoPilots', about: copilotsPageInfo }));
    dispatch(getCoPilotDetails(copilotId));
    return () => {
      dispatch(setNav());
    };
  }, []);

  const detailedCoPilotTabs = [
    {
      key: 'conversations',
      label: (
        <Popover
          content="Find all the conversations your bot has been involved in"
          trigger="hover"
          placement="bottomLeft"
          mouseEnterDelay={1}
        >
          <span>
            Conversations
          </span>
        </Popover>
      ),
      children: <Conversations copilotId={copilotId} />,
    },
    {
      key: 'customization',
      label: (
        <Popover
          content="Customize your CoPilot to the look and feel of your brand"
          trigger="hover"
          placement="bottom"
          mouseEnterDelay={1}
        >
          <span>
            Customization
          </span>
        </Popover>
      ),
      children: <WidgetPage copilotId={copilotId} />,
    },
    {
      key: 'installation',
      label: (
        <Popover
          content="Installation instructions for the CoPilot"
          trigger="hover"
          placement="bottomLeft"
          mouseEnterDelay={1}
        >
          <span>
            Installation
          </span>
        </Popover>
      ),
      children: <Code copilot_id={copilotId} />,
    },
  ];

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-start w-[50%]">
        <Link to="/co-pilots">
          <ArrowLeftOutlined className="mx-4 text-xl cursor-pointer h-6" />
        </Link>
        <span className="text-2xl font-bold text-start font-openSans">{copilot?.name}</span>
      </div>
      <div className="py-4 px-8">
        <Tabs
          activeKey={activeKey}
          onChange={handleTabChange}
          items={detailedCoPilotTabs}
        />
      </div>
    </div>
  );
}

export default DetailedCoPilotsPage;
