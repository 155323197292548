/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  DeleteOutlined, FolderAddOutlined, StarOutlined,
} from '@ant-design/icons';
import React from 'react';
import {
  Avatar, Divider, List, Space,
} from 'antd';
import { useDispatch } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { updateArchiveStatus, updateStarStatus } from '../../store/leadsQueriesSlice';
import { convertToReadableDate } from '../../utils/dateTimeFormat';

function IconText({ icon, text }) {
  return (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );
}
function QueriesList({ queries, handleDeletionConfirm, view }) {
  const dispatch = useDispatch();

  const handleStar = async (id, starred) => {
    await dispatch(updateStarStatus({ queryId: id, status: starred }));
  };

  const handleArchive = async (id, starred) => {
    await dispatch(updateArchiveStatus({ queryId: id, status: starred }));
  };

  return (
    <div>
      <List
        itemLayout="vertical"
        size="medium"
        pagination={{
        //   onChange: (page) => {
        //     console.log(page);
        //   },
          pageSize: 10,
        }}
        dataSource={queries}
        renderItem={(item, index) => (
          <List.Item
            key={item.title}
            actions={[
            //   <IconText icon={StarOutlined} text="" key="list-vertical-star-o" />,
              <div className="flex justify-between items-center">
                <div className="cursor-pointer flex items-center text-sm" onClick={() => handleArchive(item.id, !item.archived)}>
                  {item.archived ? (
                    <>
                      <UnarchiveIcon style={{ height: 18, width: 18, marginRight: 2 }} />
                      <span>Unarchive</span>
                    </>
                  ) : (
                    <>
                      <ArchiveIcon style={{ height: 18, width: 18, marginRight: 2 }} />
                      <span>Archive</span>
                    </>
                  )}
                </div>
                <Divider type="vertical" />
                <div className="cursor-pointer text-red-500" onClick={() => handleDeletionConfirm(item.id)}>
                  <IconText icon={DeleteOutlined} text="Delete" key="list-vertical-message" />
                </div>
              </div>,
            ]}
            extra={
               (!item.archived && view !== 'archived') && (
                 <div className="cursor-pointer" onClick={() => handleStar(item.id, !item.starred)}>
                   <StarIcon style={{ color: item.starred ? '#FFD700' : 'grey' }} />
                 </div>
               )
            }
          >
            <List.Item.Meta
              avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
              title={(
                <div className="flex flex-col">
                  {item.leadsContact ? <span className="font-semibold shadow-none">{item.leadsContact.name}</span> : <span className="font-semibold shadow-none">Anonymous</span>}
                  {item.leadsContact && <span className="text-sm font-normal text-gray-700">{item.leadsContact.email}</span>}
                </div>
          )}
              description={(
                <div className="flex flex-col">
                  <span>{convertToReadableDate(item.createdAt)}</span>
                </div>
              )}
              style={{ display: 'flex', alignItems: 'center' }}
            />
            {item.query}
          </List.Item>
        )}
      />
    </div>
  );
}
export default QueriesList;
