/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Empty } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

/* eslint-disable no-undef */
function EmptyPageMessage({ setOpenAddNewModal, setOpenAddLinkModal, setOpenFileUploadModal }) {
  const { data: usageLimit } = useSelector((state) => state.usageLimit);
  return (
    <Empty
      className="flex flex-col items-center justify-center w-full h-full"
      description={(
        <div className="flex flex-col">
          <p className="text-base">
            No help documents found.
          </p>
          <p className="text-sm">
            {'Get started with '}
            <span className="cursor-pointer text-blue-800" onClick={() => setOpenAddNewModal(true)}>adding a new help document.</span>
            <br />
            <br />
            <span>
              {'You can '}
              {usageLimit.doc_via_link && <span className="cursor-pointer text-blue-800" onClick={() => setOpenAddLinkModal(true)}>add a link</span>}

              { usageLimit.doc_via_upload && (
              <span className="cursor-pointer text-blue-800" onClick={() => setOpenFileUploadModal(true)}>
                {', '}
                upload file
              </span>
              )}
              { usageLimit.doc_via_writeup && (
              <Link to="/documents/new-article">
                {' '}
                {' or '}
                <span className="cursor-pointer text-blue-800">write a new document</span>
              </Link>
              )}
              {' yourself.'}
            </span>
          </p>
        </div>
        )}
    />
  );
}

export default EmptyPageMessage;
