import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function TitleStyle({ name, price, currency }) {
  return (
    <div className="flex justify-between items-center">
      <span className="font-bold text-lg m-2">{name}</span>
      <span className="text-xl font-bold">
        {currency === 'USD' ? '$' : currency}
        {price}
        {' '}
      </span>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
function PlanCard({
  planDetails, initiatePurchase, selectedPeriod, downgradeSubsriptionPlan,
}) {
  const { data: subscriptionData } = useSelector((state) => state.subscription);
  const {
    'Product.id': productId, 'Product.name': productName, 'Product.features': features, unit_amount, currency, stripe_price_id,
  } = planDetails;
  const price = parseFloat(unit_amount);

  const [ctaText, setCtaText] = useState('Subscribe');
  const [buttonType, setButtonType] = useState('primary');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loadingPurchase, setLoadingPurchase] = useState(false);
  const [ctaAction, setCtaAction] = useState('upgrade');

  const handlePurchaseInit = async () => {
    const period = selectedPeriod === 'monthly' ? 'month' : 'year';
    setLoadingPurchase(true);
    await initiatePurchase(productId, period);
    setLoadingPurchase(false);
  };

  const handleDowngrade = async () => {
    const period = selectedPeriod === 'monthly' ? 'month' : 'year';
    setLoadingPurchase(true);
    await downgradeSubsriptionPlan(productId, period);
  };

  const handleCTAText = () => {
    let newCTAText = 'Subscribe';
    if (subscriptionData) {
      if (subscriptionData.Price.stripe_price_id === stripe_price_id) {
        newCTAText = 'Current Plan';
        setButtonDisabled(true);
      } else if (subscriptionData.Price?.recurring_interval === planDetails?.recurring_interval) {
        const currentSubscriptionAmount = subscriptionData.Price.currency === 'USD' ? subscriptionData.Price.unit_amount : subscriptionData.Price.unit_amount / 84;
        if (Number(currentSubscriptionAmount) > Number(unit_amount)) {
          newCTAText = 'Downgrade';
          setButtonType('default');
        } else if (Number(currentSubscriptionAmount) < Number(unit_amount)) {
          newCTAText = 'Upgrade';
        } else {
          newCTAText = 'Current Plan';
          setButtonDisabled(true);
        }
      }
    }
    setCtaText(newCTAText);
  };

  const handleCTAAction = () => {
    let newCTAAction = 'upgrade';
    if (subscriptionData) {
      if (subscriptionData.Price.stripe_price_id === stripe_price_id) {
        newCTAAction = '';
      }
      const currentSubscriptionAmount = subscriptionData.Price.currency.toLowerCase() === 'usd' ? subscriptionData.Price.unit_amount : subscriptionData.Price.unit_amount / 84;
      if (Number(currentSubscriptionAmount) > Number(unit_amount)) {
        newCTAAction = 'downgrade';
      } else if (Number(currentSubscriptionAmount) < Number(unit_amount)) {
        newCTAAction = 'upgrade';
      } else {
        newCTAAction = '';
      }
    }
    setCtaAction(newCTAAction);
  };

  useEffect(() => {
    if (subscriptionData && planDetails) {
      handleCTAText();
      handleCTAAction();
    }
  }, []);

  return (
    <Card style={{ width: 330 }} title={<TitleStyle name={productName} price={price} currency={currency} />} className="my-4 mx-2 bg-blue-50" loading={!planDetails}>
      <div className="flex flex-col justify-between w-full h-96">
        <div className="flex flex-col">
          {features.map((feature) => (
            <div className="flex items-center text-gray-900 my-2" key={feature}>
              <CheckCircleTwoTone twoToneColor="#52c41a" />
              <span className="mx-2">{feature}</span>
            </div>
          ))}
        </div>
        <div className="flex justify-center w-full">
          {/* <Button className="w-full" type="primary" onClick={planSelectionAction} disabled={currentPlanDetails.planId === planDetails.planId}>
            {currentPlanDetails.planId === planDetails.planId ? 'Current Plan' : `${planSelectionAction} Plan`}
          </Button> */}
          <Button loading={loadingPurchase} className="w-full" type={buttonType} onClick={ctaAction === 'upgrade' ? handlePurchaseInit : handleDowngrade} disabled={buttonDisabled || loadingPurchase}>
            {ctaText}
          </Button>
        </div>
      </div>
    </Card>
  );
}

export default PlanCard;
