/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import {
  Form, Button, Input, message, Divider,
} from 'antd';
import Cookies from 'js-cookie';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { baseURL } from '../../config';

function Code({ copilot_id }) {
  const { data: user } = useSelector((state) => state.user);
  const [tag, setTag] = useState();
  const [mailSent, setMailSent] = useState(false);
  const [developerEmail, setDeveloperEmail] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && user.company) {
      const companyWidgetTag = `<script type="text/javascript">window.CP_ID="${copilot_id}";(function(){d=document;s=d.createElement("script");s.src="//widgets.scripts-tp.com/main.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();</script>`;
      setTag(companyWidgetTag);
    }
  }, [user]);

  const handleCopy = () => {
    navigator.clipboard.writeText(tag)
      .then(() => {
        message.success('Tag copied to clipboard!');
      })
      .catch(() => {
        message.error('Failed to copy tag. Please manually select and copy.');
      });
  };

  const sendDeveloperMail = () => {
    setLoading(true);
    const postData = {
      email: developerEmail,
    };
    fetch(`${baseURL}/accounts/mail-developer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('accessToken')} `,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res.success) {
          message.success('Mail sent successfully!');
          setMailSent(true);
        } else {
          message.error('Failed to send email. Please try again later.');
        }
      })
      .catch(() => {
        // console.error('Error:', error);
      });
  };

  return (
    <div className="bg-white rounded p-2 text-start">
      <div className="flex flex-col w-full">
        <h2 className="text-xl font-semibold mb-4">TelePilot AI Chat Widget Setup</h2>
        <p className="text-gray-800 w-full">To enable the TelePilot AI chat widget on your website, please copy the following tag and place it on body of each page where you want the chat widget to be available.</p>
        <p className="text-gray-800 w-full">Alternatively, you can directly add this tag to your Google Tag Manager:</p>
        <div className="flex items-center mt-4 w-fit h-fit">
          {tag ? (
            <span className="p-4 border rounded bg-gray-200 overflow-wrap w-full">
              {tag}
            </span>
          ) : (
            <LoadingOutlined
              style={{
                fontSize: 24,
                color: '#1890ff',
              }}
              spin
            />
          )}
          <Button type="primary" className="mx-2" onClick={handleCopy} disabled={!tag}>
            Copy Tag
          </Button>
        </div>
        <Divider />
        <div>
          <p className="text-lg font-bold">Send it to developer?</p>
          <p className="text-gray-800 w-full mt-4">
            {`Please provide your developer's email and we'll send 
            the above tag with installation instructions to them.`}
          </p>
          <div className="flex items-center">
            <Form onFinish={sendDeveloperMail} className="flex">
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid email',
                    type: 'email',
                  },
                ]}
                className="mr-2 w-96"
              >
                <Input
                  value={developerEmail}
                  onChange={(e) => {
                    setDeveloperEmail(e.target.value);
                    setMailSent(false);
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" disabled={mailSent} loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
          {mailSent && (
          <div className="flex items-center">
            <CheckCircleOutlineIcon twoToneColor="#006200" />
            <span className="text-xs m-2">Mail was sent to above email with integration instructions. Didn&apos;t recieve email?  Check spam or</span>
            <a className="text-xs m-2" onClick={sendDeveloperMail}>Send Again</a>
          </div>
          )}
        </div>
        <div>
          <p className="text-gray-800 w-full mt-12">
            If you have any additional questions, please feel free to contact us at
            {' '}
            <a href="mailto:support@telepilot.ai">
              support@telepilot.ai
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Code;
