import { Button } from 'antd';

function SubscriptionPage() {
  const plans = [
    {
      name: 'Limited Time Free Plan',
      description: 'Get started with our special introductory offer! This plan allows you to experience the core functionalities of TelePilot AI at no cost. Enjoy all standard features for free.',
      buttonText: 'Current Plan',
      isDisabled: false,
    },
    {
      name: 'Starter',
      description: 'Perfect for small businesses and startups, our Starter Plan offers enhanced capabilities beyond the basic free package.',
      buttonText: 'Coming Soon',
      isDisabled: true,
    },
    {
      name: 'Pro',
      description: 'Designed for growing businesses with a larger customer base, our Pro Plan provides full access to TelePilot AI\'s extensive features.',
      buttonText: 'Coming Soon',
      isDisabled: true,
    },
    {
      name: 'AI Starter',
      description: 'Step up your game with AI. Our AI Starter Plan introduces you to more advanced AI functionalities.',
      buttonText: 'Coming Soon',
      isDisabled: true,
    },
    {
      name: 'AI Pro',
      description: 'Transform your customer service with our top-tier AI Pro Plan. This plan includes all features of AI Starter and more advanced AI capabilities.',
      buttonText: 'Coming Soon',
      isDisabled: true,
    },
  ];

  return (
    <div className="flex flex-wrap justify-center p-10 h-full overflow-scroll">
      <h1 className="text-3xl font-semibold mb-5 w-full text-center">Choose Your Plan</h1>
      {plans.map((plan) => (
        <div key={plan} className="shadow-md bg-white m-5 rounded-lg flex flex-col p-6 justify-between w-20" style={{ minWidth: '300px', height: '300px' }}>
          <h2 className="text-2xl font-semibold mb-2 text-gray-800">{plan.name}</h2>
          <p className="w-full text-gray-900">{plan.description}</p>
          <Button className={`${plan.isDisabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700 text-white'}`} disabled={plan.isDisabled}>
            {plan.buttonText}
          </Button>
        </div>
      ))}
    </div>
  );
}

export default SubscriptionPage;
