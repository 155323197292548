import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import ConversationsList from './ConversationsList';
import MessagesDisplay from './MessagesDisplay';
import { getAllConversations } from '../../../store/conversationsSlice';
import crudData from '../../../utils/apiService';
import { baseURL } from '../../../config';

function Conversations({ copilotId }) {
  const dispatch = useDispatch();
  const [selectedConversationId, setSelectedConversationId] = useState();
  const { allConversations: conversations } = useSelector((state) => state.conversations);
  const [messages, setMessages] = useState([]);

  const [customerImage, setCustomerImage] = useState('https://api.dicebear.com/7.x/miniavs/svg?seed=0');

  useEffect(() => {
    dispatch(getAllConversations(copilotId));
  }, []);

  const getConversationMessages = async (convoId) => {
    try {
      if (convoId) {
        const response = await crudData(baseURL, '/conversations/messages/{convoId}', 'GET', { convoId }).then((res) => res.json());
        setMessages(response.data);
      }
    } catch (error) {
      message.error(`Failed to load messages, ${error.message}`);
    }
  };

  useEffect(() => {
    if (selectedConversationId) {
      getConversationMessages(selectedConversationId);
    }
  }, [selectedConversationId]);

  const handleSelectConversation = (conversationId, convIndex) => {
    setSelectedConversationId(conversationId);
    setCustomerImage(`https://api.dicebear.com/7.x/miniavs/svg?seed=${convIndex}`);
  };

  return (
    <div>
      <div className="flex">
        <div className="w-1/4 h-[34rem] overflow-scroll">
          <ConversationsList conversations={conversations} selectedConversationId={selectedConversationId} handleSelectConversation={handleSelectConversation} />
        </div>
        <div className="w-3/4 h-[34rem] overflow-scroll">
          <MessagesDisplay messages={messages} customerImage={customerImage} selectedConversationId={selectedConversationId} />
        </div>
      </div>
    </div>
  );
}

export default Conversations;
