import { Button } from 'antd';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import RichTextEditor from './RichTextEditor';
import EditableTitle from './EditableTitle';
import SavePublishModal from './SavePublishModal';

function NewArticle() {
  const navigate = useNavigate();
  const [articleTitle, setArticleTitle] = useState('Untitled Document');
  const [articleContent, setArticleContent] = useState('');
  const [savePublishModalVisible, setSavePublishModalVisible] = useState(false);

  const discardNewArticle = () => {
    setArticleTitle('');
    setArticleContent('');
    navigate('/documents');
  };

  return (
    <div className="h-full p-4 w-full">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-start w-[50%]">
          <Link to="/documents">
            <ArrowLeftOutlined className="mx-4 text-xl cursor-pointer" />
          </Link>
          {/* <h4 className="text-xl font-bold text-start font-openSans">New Article</h4> */}
          <EditableTitle articleTitle={articleTitle} setArticleTitle={setArticleTitle} />
        </div>
        <div className="flex w-[20%] justify-around items-center">
          <Button icon={<DeleteOutlined />} className="float-end" danger onClick={discardNewArticle}>Discard</Button>
          {/* <Button icon={<SaveOutlined />} className="float-end"
           disabled={articleContent.length === 0}>Save as draft</Button> */}
          <Button
            type="primary"
            className="float-end"
            disabled={articleContent.length === 0}
            onClick={() => setSavePublishModalVisible(true)}
          >
            Save & Publish
          </Button>
        </div>
      </div>
      <div className="h-5/6 mt-2">
        <RichTextEditor articleContent={articleContent} setArticleContent={setArticleContent} />
      </div>
      <SavePublishModal
        visible={savePublishModalVisible}
        setVisible={setSavePublishModalVisible}
        documentTitle={articleTitle}
        documentContent={articleContent}
      />
    </div>
  );
}

export default NewArticle;
