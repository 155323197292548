import { useEffect, useState } from 'react';
import {
  Button,
} from 'antd';
import { DownloadOutlined, UserAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';

import NewLeadModal from '../leadscontacts/NewLeadModal';
import LeadsContactsTable from '../leadscontacts/LeadsContactsTable';
import { setNav } from '../../store/navSlice';
import { bulkDeleteContacts, getAllContacts } from '../../store/leadsContactsSlice';
import DeleteContactConfirm from '../leadscontacts/DeleteContactConfirm';
import EditLeadModal from '../leadscontacts/EditLeadModal';
import { convertToReadableDate } from '../../utils/dateTimeFormat';

function LeadsContactsPage() {
  const dispatch = useDispatch();
  const { data: contacts } = useSelector((state) => state.contacts);

  const contactsInfo = 'Find all the new contacts/leads added via chatbot here. You can also add new contacts manually here.';

  useEffect(() => {
    dispatch(setNav({ title: 'Contacts', about: contactsInfo }));
    dispatch(getAllContacts());
    return () => {
      dispatch(setNav());
    };
  }, []);

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [onEditContact, setOnEditContact] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deletionContacts, setDeletionContacts] = useState(null);
  const [openNewContactModal, setOpenNewContactModal] = useState(false);

  const confirmDeletion = (contact) => {
    setOpenDeleteConfirm(true);
    setDeletionContacts([...contact]);
  };

  const confirmEdit = (contact) => {
    setOnEditContact(contact);
    setOpenEditModal(true);
  };

  const handleContactDelete = async () => {
    await dispatch(bulkDeleteContacts(deletionContacts));
    setOpenDeleteConfirm(false);
    setDeletionContacts(null);
    setSelectedContacts([]);
    dispatch(getAllContacts());
  };

  const prepCSVData = (allContacts) => {
    const preparedData = allContacts.map((contact) => {
      const {
        name, email, phone, address, tags, notes, source, createdAt,
      } = contact;
      return {
        Name: name, Email: email, Phone: phone, Address: address, Tags: tags ? tags.join(' ') : '', Notes: notes ? notes.replace(/[\n,]/g, ' ') : '', Source: source, 'Created At': convertToReadableDate(createdAt),
      };
    });
    return Papa.unparse(preparedData);
  };

  return (
    <div className="flex flex-col p-2">
      <div className="flex justify-between items-center">
        <div>
          {selectedContacts.length > 0 && (
          <span className="ml-4">
            {`Selected ${selectedContacts.length} contact(s)`}
          </span>
          )}
        </div>
        <div style={{ marginBottom: 16 }} className="flex self-end">
          {selectedContacts.length > 0
          && (
          <>
            <Button danger icon={<DeleteOutlined />} onClick={() => confirmDeletion(selectedContacts)}>
              Delete Selected
            </Button>

            <CSVLink
              data={prepCSVData(contacts.filter((contact) => selectedContacts.includes(contact.id)))}
              filename="tpai-export-selected.csv"
              target="_blank"
            >
              <Button style={{ marginLeft: 8 }} icon={<DownloadOutlined />}>
                Export Selected
              </Button>
            </CSVLink>
          </>
          )}
          <CSVLink
            data={prepCSVData(contacts)}
            filename="tpai-export-all.csv"
            target="_blank"
          >
            <Button style={{ marginLeft: 8 }} icon={<DownloadOutlined />}>
              Export All
            </Button>
          </CSVLink>
          <Button type="primary" style={{ marginLeft: 8 }} onClick={() => setOpenNewContactModal(true)} icon={<UserAddOutlined />}>
            Add New Contact
          </Button>
        </div>
      </div>
      <LeadsContactsTable
        contacts={contacts}
        selectedRowKeys={selectedContacts}
        setSelectedRowKeys={setSelectedContacts}
        confirmDeletion={confirmDeletion}
        confirmEdit={confirmEdit}
      />

      <NewLeadModal modalVisible={openNewContactModal} setModalVisible={setOpenNewContactModal} />
      <EditLeadModal modalVisible={openEditModal} setModalVisible={setOpenEditModal} contactData={onEditContact} />
      <DeleteContactConfirm visible={openDeleteConfirm} setVisible={setOpenDeleteConfirm} setDeletionContact={setDeletionContacts} handleContactDelete={handleContactDelete} />
    </div>
  );
}

export default LeadsContactsPage;
