import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { baseURL } from '../config';

export const getAllQueries = createAsyncThunk('leadsQueries/getAllQueries', async () => {
  try {
    const response = await crudData(baseURL, '/leads-queries/', 'GET');
    const { data } = await response.json();
    return data;
  } catch (error) {
    return message.error(`Failed to load queries, ${error.message}`);
  }
});

export const getArchivedQueries = createAsyncThunk('leadsQueries/getArchivedQueries', async () => {
  try {
    const response = await crudData(baseURL, '/leads-queries/archived', 'GET');
    const { data } = await response.json();
    return data;
  } catch (error) {
    return message.error(`Failed to load archived queries, ${error.message}`);
  }
});

export const updateStarStatus = createAsyncThunk('leadsQueries/updateStarStatus', async ({ queryId, status }) => {
  try {
    const response = await crudData(baseURL, '/leads-queries/star/{queryId}', 'PUT', { queryId }, {}, { star: status });
    const { data } = await response.json();
    return data;
  } catch (error) {
    return message.error(`Failed to update star status, ${error.message}`);
  }
});

export const updateArchiveStatus = createAsyncThunk('leadsQueries/updateArchiveStatus', async ({ queryId, status }) => {
  try {
    const response = await crudData(baseURL, '/leads-queries/archive/{queryId}', 'PUT', { queryId }, {}, { archive: status }).then((res) => res.json());
    if (response.success) {
      message.success(response.message);
    } else {
      message.error(response.message);
    }
    return response.data;
  } catch (error) {
    return message.error(`Failed to update archive status, ${error.message}`);
  }
});

export const deleteQuery = createAsyncThunk('leadsQueries/deleteQuery', async (queryId) => {
  try {
    const response = await crudData(baseURL, '/leads-queries/{queryId}', 'DELETE', { queryId });
    const { success } = await response.json();
    if (success) {
      message.success('Query deleted successfully');
      return queryId;
    }
    return null;
  } catch (error) {
    return message.error(`Failed to delete query, ${error.message}`);
  }
});

const initialState = {
  activeQueries: [],
  archivedQueries: [],
  status: requestStatuses.IDLE,
};

const leadsQueriesSlice = createSlice({
  name: 'leadsQueries',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllQueries.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getAllQueries.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.activeQueries = action.payload;
      })
      .addCase(getAllQueries.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(getArchivedQueries.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getArchivedQueries.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.archivedQueries = action.payload;
      })
      .addCase(getArchivedQueries.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(updateStarStatus.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(updateStarStatus.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.activeQueries = state.activeQueries.map((query) => {
          if (query.id === action.payload.id) {
            query.starred = action.payload.starred;
          }
          return query;
        });
      })
      .addCase(updateStarStatus.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(updateArchiveStatus.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(updateArchiveStatus.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        if (action.payload.archived) {
          state.activeQueries = state.activeQueries.filter((query) => query.id !== action.payload.id);
          state.archivedQueries.push(action.payload);
        } else {
          state.archivedQueries = state.archivedQueries.filter((query) => query.id !== action.payload.id);
          state.activeQueries.push(action.payload);
        }
      })
      .addCase(updateArchiveStatus.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(deleteQuery.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(deleteQuery.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.activeQueries = state.activeQueries.filter((query) => query.id !== action.payload);
      })
      .addCase(deleteQuery.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      });
  },
});

export default leadsQueriesSlice.reducer;
