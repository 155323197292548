import {
  Button, Input, Modal, Tabs,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ResourcesNewCoPilot from './ResourcesNewCoPilot';
import { createCoPilot } from '../../../store/copilotsSlice';

const { TextArea } = Input;

function NewCoPilot({
  visible, setVisible,
}) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const handleCreateDisable = () => {
    if (name && description && selectedDocs.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    handleCreateDisable();
  }, [name, description, selectedDocs]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const createNewCoPilot = async () => {
    setLoading(true);
    await dispatch(createCoPilot({
      name,
      description,
      help_docs: selectedDocs,
    }));
    setLoading(false);
    setVisible(false);
  };

  const docsSelector = (docs) => {
    setSelectedDocs(docs);
  };

  return (
    <Modal
      open={visible}
      title="Create New CoPilot"
      onOk={createNewCoPilot}
      onCancel={handleCancel}
      style={{
        top: 10,
      }}
      width={1600}
      // height={90}
      // footer={null}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={createNewCoPilot} disabled={disabled}>
          Create New CoPilot
        </Button>,
      ]}
    >
      <div className="h-full">
        <div className="flex items-center justify-between px-2">
          <div className="flex items-center w-1/4">
            <div className="mr-2 font-semibold">Name:</div>
            <div className="w-full">
              <Input value={name} onChange={handleNameChange} placeholder="Name of your chatbot" />
            </div>
          </div>
          <div className="flex items-center w-2/3">
            <div className="mr-2 font-semibold">Description:</div>
            <div className="w-2/3">
              <TextArea
                rows={2}
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Description of your chatbot"
                style={{
                  height: 50,
                  resize: 'none',
                }}
                maxLength={100}
              />
            </div>
          </div>
        </div>
        <ResourcesNewCoPilot selectedDocs={selectedDocs} docsSelector={docsSelector} />
        {/* <Tabs
          defaultActiveKey="1"
          type="card"
          // size="large"
          items={tabList}
          tabBarExtraContent={<Button type="primary">Create New CoPilot</Button>}
        /> */}
      </div>
    </Modal>
  );
}

export default NewCoPilot;
