import {
  CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EllipsisOutlined,
} from '@ant-design/icons';
import {
  Button, Card, Divider, Popover, Switch, Tag, Tooltip, Typography,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { messageTimeStampFormat } from '../../utils/dateTimeFormat';
import { changePublishState, changeTrainChatbotState, deleteHelpDoc } from '../../store/helpCMSSlice';
import DeleteConfirmation from './DeleteConfirmation';

function CardOptions({ published, handleDelete, publishStateUpdate }) {
  return (
    <div className="flex flex-col">
      <Button className="text-start" type="text" icon={published ? <CloseCircleOutlined /> : <CheckCircleOutlined />} onClick={publishStateUpdate}>{published ? 'Unpublish' : 'Publish'}</Button>
      <Button danger className="text-start" type="text" icon={<DeleteOutlined />} onClick={handleDelete}>Delete</Button>
    </div>
  );
}

function HelpDocCard({ doc }) {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const deleteDoc = async () => {
    dispatch(deleteHelpDoc(doc.id));
  };

  const changeDocPublishState = async () => {
    dispatch(changePublishState({ id: doc.id, publishState: !doc.published, docType: doc.sourceType.toLowerCase() }));
  };

  const changeBotTrainState = async () => {
    dispatch(changeTrainChatbotState({ id: doc.id, trainChatbot: !doc.trainChatbot }));
  };

  return (
    <>
      <Card
        title={(
          <Text
            style={{ maxWidth: 350 }}
            ellipsis={{ tooltip: doc.sourceType === 'WRITEUP' ? doc.title : doc.aiTitle }}
          >
            {doc.sourceType === 'WRITEUP' ? doc.title : doc.aiTitle}
          </Text>
)}
        className="text-start m-1 shadow-lg"
        style={{ width: 418, height: 320 }}
        extra={(
          <Popover content={<CardOptions published={doc.published} handleDelete={setDeleteConfirmation} publishStateUpdate={changeDocPublishState} />} placement="bottom">
            <EllipsisOutlined key="ellipsis" />
          </Popover>
      )}
        loading={!doc}
      >
        <div className="m-0 flex flex-col justify-between h-56">
          <div>
            <span className="text-gray-600">
              {doc.aiDescription}
            </span>
          </div>
          <div className="text-xs">
            <div className="mb-4 text-gray-500">
              {doc.source && !doc.source.includes('pdf') ? (
                <Tag bordered={false} color="cyan">
                  <Text
                    style={{ maxWidth: 350 }}
                    ellipsis={{ tooltip: doc?.source }}
                  >
                    {doc?.source}
                  </Text>
                </Tag>
              ) : ''}
            </div>
            <div className="flex items-center text-sm mb-2">
              <div>
                <span className="text-gray-600">Doc Type:</span>
                <span className="ml-1 font-semibold text-gray-600">{doc.sourceType}</span>
              </div>
              {/* <div>
              <span>Publicly Visible</span>
              <Switch checked={doc.publiclyVisbile} />
            </div> */}
              <Divider type="vertical" />
              <div>
                <span className="text-gray-600">Train Chatbot:</span>
                <Tooltip title={doc.published ? '' : 'Please publish the help doc first.'}>
                  <Switch disabled={!doc.published} onChange={changeBotTrainState} className="ml-2" size="small" checked={doc.published ? doc.trainChatbot : false} />
                </Tooltip>
              </div>
            </div>
            <div className="flex items-center">
              <div>
                <span className="text-gray-500">
                  Last Updated:
                </span>
                <span className="text-gray-900 ml-1">
                  {messageTimeStampFormat(doc.updatedAt).currentFormat}
                </span>
              </div>
              <Divider type="vertical" />
              <div>
                <Tag bordered={false} color={doc.published ? 'green' : 'gold'}>
                  {doc.published ? 'PUBLISHED' : 'NOT PUBLISHED'}
                </Tag>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <DeleteConfirmation visible={deleteConfirmation} setVisible={setDeleteConfirmation} deleteDoc={deleteDoc} docTitle={doc.sourceType === 'WRITEUP' ? doc.title : doc.aiTitle} description={doc.aiDescription} />
    </>
  );
}

export default HelpDocCard;
