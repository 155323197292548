import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, Form, Input, Select, Typography, Popover, message,
  //  Tooltip, Alert
} from 'antd';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import countriesWithTZCode from '../../constants/countriesWithTZCode.json';
import { useDispatch } from 'react-redux';
import industries from '../../constants/industries';
import { updateCompanyDetails } from '../../store/userSlice';

const { Title, Paragraph } = Typography;
const { Option } = Select;
// const { countries } = countriesWithTZCode;

function Welcome() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const [countryOptions, setCountryOptions] = useState([...countries]);
  const navigate = useNavigate();

  const handleContinue = async (values) => {
    try {
      setLoading(true);
      dispatch(updateCompanyDetails(values));
      setLoading(false);
      navigate('/');
    } catch (error) {
      setLoading(false);
      message.error('Something went wrong. Please try again later.');
    }
  };

  // const handleCountrySearch = (value) => {
  //   const filteredOptions = countries.filter(
  //     (option) => option.name.toLowerCase().includes(value.toLowerCase()),
  //   );
  //   setCountryOptions(filteredOptions);
  // };

  return (
    <div className="flex flex-col items-center justify-center h-screen p-4">
      <Title level={3} className="mb-2">
        Welcome to
        {' '}
        <span style={{ fontFamily: 'Nunito Light, sans-serif' }}>TelePilot AI</span>
        !
      </Title>
      <Paragraph className="text-center mb-8">
        Thank you for choosing
        {' '}
        <span style={{ fontFamily: 'Nunito Bold, sans-serif' }}>TelePilot AI</span>
        {' '}
        as your customer support solution.
      </Paragraph>
      <Paragraph className="text-center">
        {'We\'ll need some additional information to make your account functional and personalized.'}
      </Paragraph>
      <div className="flex border m-4 rounded-lg bg-white w-full p-2 md:w-1/3">
        <Form form={form} onFinish={handleContinue} layout="vertical" className="flex flex-col items-center m-auto w-full">
          <div className="w-full text-start">
            <Form.Item
              name="name"
              label={(
                <span className="flex items-center">
                  Company Name&nbsp;
                  <Popover placement="right" title="To identify your team and manage multiple team members" arrow>
                    <HelpOutlineIcon />
                  </Popover>
                </span>
          )}
              rules={[
                { required: true, message: 'Please enter your company name' },
                { pattern: /^(?=.*[a-zA-Z0-9]).+$/, message: 'Please enter a valid company name' },
              ]}
            >
              <Input.TextArea
                placeholder="Enter your company name"
                autoSize={{ minRows: 1, maxRows: 2 }}
                // style={{ width: '400px' }}
              />
            </Form.Item>
          </div>
          <div className="w-full text-start">
            <Form.Item
              name="industry"
              label="Company Industry"
                // initialValue={user ? user.company.industry : ''}
              rules={[{ required: true, message: 'Please select the industry' }]}
              className="text-start"
            >
              <Select
                placeholder="Select your industry"
                prefix={<LocationOnIcon />}
              >
                {
              industries.map((industry) => (
                <Option key={industry.value} value={industry.value}>{industry.label}</Option>
              ))
            }
              </Select>
            </Form.Item>
          </div>
          {/* <div className="w-full text-start">
            <Form.Item
              name="country"
              label={(
                <span className="flex items-center">
                  Country&nbsp;
                  <Popover className="font-thin" placement="right" title="Adjust reporting to your timezone" arrow>
                    <HelpOutlineIcon />
                  </Popover>
                </span>
          )}
              rules={[{ required: true, message: 'Please select your country' }]}
            >
              <Select
                showSearch
                placeholder="Select your country"
                onSearch={handleCountrySearch}
                filterOption={false}
                // style={{ width: '400px', textAlign: 'left' }}
                className="w-full"
              >
                {countryOptions.map((country) => (
                  <Option className="text-black" key={country.name} value={country.name}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div> */}
          <div className="w-full text-start">
            <Form.Item
              name="website"
              label="Website"
              rules={[
                {
                  pattern: /^(https?:\/\/)?[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
                  message: 'Please enter a valid website URL',
                },
              ]}
            >
              <Input
                placeholder="Enter your website"
                // style={{ width: '400px' }}
              />
            </Form.Item>
          </div>
          <div className="flex">
            <Button type="link" onClick={() => navigate('/')}>
              Skip for now
            </Button>
            <Button loading={loading} type="primary" htmlType="submit">
              Continue
            </Button>
          </div>
        </Form>
      </div>
      {/* <div className="mt-4">
        <Paragraph>
          Completing this step will make your account ready to provide exceptional customer support.
        </Paragraph>
        <Paragraph>
          Let&apos;s get started and revolutionize your customer service experience!
        </Paragraph>
      </div> */}
    </div>
  );
}

export default Welcome;
