import {
  Button, Checkbox, Input, Modal, Tooltip, message,
} from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSubscription } from '../../../store/subscriptionSlice';

const cancelationReasonOptions = [
  {
    id: 1,
    label: 'It\'s too expensive',
    value: 'too_expensive',
  },
  {
    id: 2,
    label: 'Some features are missing',
    value: 'missing_features',
  },
  {
    id: 3,
    label: 'I\'m switching to a different service',
    value: 'switched_service',
  },
  {
    id: 4,
    label: 'I don\'t use the service enough',
    value: 'unused',
  },
  {
    id: 5,
    label: 'Customer service was less than expected',
    value: 'customer_service',
  },
  {
    id: 6,
    label: 'Ease of use was less than expected',
    value: 'too_complex',
  },
  {
    id: 7,
    label: 'Quality was less than expected',
    value: 'low_quality',
  },
  {
    id: 8,
    label: 'Other reason',
    value: 'other',
  },
];

function CancellationConfirm({ visible, setVisible }) {
  // const { updateSubscriptionStatus } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();
  const [cancelComments, setCancelComments] = useState('');
  const [cancelReasons, setCancelReasons] = useState([]);
  const [loading, setLoading] = useState(false);
  const { TextArea } = Input;

  // useState(() => {
  //   if (updateSubscriptionStatus === 'pending') {
  //     setLoading(true);
  //   } else if (updateSubscriptionStatus === 'fulfilled') {
  //     setLoading(false);
  //   }
  // }, []);

  const addCancellationReason = (e) => {
    setCancelReasons(e);
  };
  const handleComments = (e) => {
    const newCancelComment = e.target.value;
    if (newCancelComment.length <= 2000) {
      setCancelComments(newCancelComment);
    }
  };

  const handleSubscriptionCancellation = async () => {
    setLoading(true);
    const cancellationObj = {
      cancel_at_period_end: true,
      cancellation_details: {
        comment: cancelComments,
        feedback: cancelReasons[0],
      },
    };
    await dispatch(updateSubscription(cancellationObj));
    setLoading(false);
    setVisible(false);
    message.success('Subscription set to cancelled at the end of current period.');
  };
  return (
    <Modal
      title="Cancel Subscription"
      centered
      open={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Tooltip className="mx-2" title={cancelReasons.length === 0 && 'Please select reason before cancelling'}>
          <Button danger key="submit" disabled={cancelReasons.length === 0} loading={loading} onClick={handleSubscriptionCancellation}>
            Cancel Subscription
          </Button>
        </Tooltip>,
      ]}
      width={800}
    >
      <p className="mt-8 font-semibold text-base">
        Are you sure you want to cancel your subscription?
      </p>
      <p className="mb-2 text-gray-800">
        Your subscription will remain active until the end of the current billing period, so you can continue to enjoy all its benefits until then.
      </p>
      <p className="mb-2">
        Before you go, we&apos;d like to understand your reasons for canceling. This will help us improve our service.
        {' '}
        <strong>Please select a reason:</strong>
      </p>
      <Checkbox.Group onChange={addCancellationReason}>
        <div className="flex flex-col">
          {cancelationReasonOptions.map((reason) => <Checkbox key={reason.id} className="my-1 text-gray-700 hover:text-gray-900" value={reason.value}>{reason.label}</Checkbox>)}
        </div>
      </Checkbox.Group>
      <p className="text-gray-600">If you have any additional feedback or suggestions, we&apos;d love to hear from you:</p>
      <TextArea
        className="mb-8"
        value={cancelComments}
        onChange={handleComments}
        placeholder="Your feedback or suggestion"
        autoSize={{ minRows: 4, maxRows: 4 }}
      />
    </Modal>
  );
}

export default CancellationConfirm;
