import React from 'react';

import { Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { DeleteFilled } from '@ant-design/icons';
import { deleteQuery } from '../../store/leadsQueriesSlice';

function DeleteQueryConfirm({
  visible, setVisible, deletionQueryId, setDeletionQueryId,
}) {
  const dispatch = useDispatch();
  const handleDelete = async () => {
    await dispatch(deleteQuery(deletionQueryId));
    setVisible(false);
    setDeletionQueryId(null);
  };

  return (
    <Modal
      title="Delete Support Request"
      open={visible} // Set the visibility of the modal accordingly
      onCancel={() => { setVisible(false); }} // Handle cancel logic here
      footer={[
        <Button key="cancel" onClick={() => {}}>Cancel</Button>,
        <Button icon={<DeleteFilled />} danger onClick={handleDelete}>Delete</Button>,
      ]}
      centered
    >
      <p className="my-8">Are you sure you want to delete this support request query?</p>
    </Modal>
  );
}

export default DeleteQueryConfirm;
