import {
  Button, Divider, Input, Radio, Tooltip,
} from 'antd';
import React from 'react';

const { Search } = Input;

function HeaderQueries({
  view, queries, handleViewChange, filterQueries, filterString, setFilterString,
}) {
  return (
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center">
        <span>
          Displaying all
          {' '}
          {filterString ? 'filtered' : view}
          {' '}
          support queries
          {' '}
          {queries.length > 0 && `(${queries.length})`}
        </span>
        {filterString && <Button type="link" onClick={() => setFilterString('')}>Clear Filter</Button>}
      </div>
      <div className="flex items-center">
        <Search
          placeholder="Filter"
          value={filterString}
          allowClear
          onChange={(e) => setFilterString(e.target.value)}
          onSearch={filterQueries}
          style={{
            width: 200,
            marginRight: 10,
          }}
        />
        <Divider type="vertical" />
        <div className="flex items-center">
          <Radio.Group
            // options={viewOptions}
            onChange={handleViewChange}
            value={view}
            optionType="button"
            buttonStyle="solid"
          >
            <Tooltip title="All active support queries" mouseEnterDelay={1} placement="bottomRight">
              <Radio value="active">Active</Radio>
            </Tooltip>
            <Tooltip title="All star marked active support queries" mouseEnterDelay={1} placement="bottom">
              <Radio value="starred">Starred</Radio>
            </Tooltip>
            <Tooltip title="All archived support queries" mouseEnterDelay={1} placement="bottomRight">
              <Radio value="archived">Archived</Radio>
            </Tooltip>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
}

export default HeaderQueries;
