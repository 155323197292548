import { useState } from 'react';
import {
  Modal, Button, Upload, message, Checkbox, Popover,
} from 'antd';
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { uploadHelpFile } from '../../store/helpCMSSlice';

function FileUploadModal({ visible, setVisible, setOpenAddNewModal }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [trainChatbot, setTrainChatBot] = useState(true);
  const [publiclyVisible, setPubliclyVisible] = useState(true);

  const changePublicVisibility = () => {
    const currentState = publiclyVisible;
    setPubliclyVisible(!currentState);
  };

  const changeChatBotTrainable = () => {
    const currentState = trainChatbot;
    setTrainChatBot(!currentState);
  };

  const beforeUpload = (file) => {
    const isAcceptedFileType = file.type === 'application/pdf' || file.type === 'text/plain' || file.name.endsWith('.doc') || file.name.endsWith('.docx');
    if (!isAcceptedFileType) {
      message.error('You can only upload PDF, DOC, or TXT files!');
      return false;
    }
    const isLt10M = file.size / 1024 / 1024 < 10; // 10MB
    if (!isLt10M) {
      message.error('File must be smaller than 10MB!');
      return false;
    }
    // return isAcceptedFileType && isLt10M;

    // Update the file list here
    setFileList([...fileList, file]);

    return false;
  };

  // eslint-disable-next-line no-shadow
  const onUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleUpload = async () => {
    setFileList(fileList);
    setLoading(true);

    const filesToUpload = fileList.map((file) => file.originFileObj);

    try {
      await dispatch(uploadHelpFile({
        files: filesToUpload,
        trainChatbot,
        publiclyVisible,
      }));

      setLoading(false);
      // message.success('Files uploaded successfully!');
      setFileList([]);
      setVisible(false);
      setOpenAddNewModal(false);
    } catch (error) {
      setFileList([]);
      setLoading(false);
      message.error(`Error uploading files: ${error.message}`);
    }
  };

  return (
    <div className="p-8">
      <Modal
        title="Upload Files"
        open={visible}
        onCancel={() => {}}
        footer={[
          <Button key="back" onClick={() => setVisible(false)} disabled={loading}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleUpload}>
            Upload
          </Button>,
        ]}
        closable={false}
        // width={500}
      >
        <p className="text-xs">
          Select files to be uploaded from your machine.
          <br />
          Please ensure files are in PDF, or TXT format only.
        </p>
        <Upload
          accept=".pdf,.txt"
          multiple
          listType="text"
          fileList={fileList}
          beforeUpload={beforeUpload}
          onChange={onUploadChange}
          disabled={loading}
          // customRequest={() => {}}
        >
          <Button icon={<UploadOutlined />} className="w-full" disabled={loading}>
            Select Files
          </Button>
        </Upload>
        <div>
          <p className="text-sm text-gray-500">
            {fileList ? fileList.length : 0}
            {' '}
            files selected
          </p>
        </div>
        <div className="flex flex-col my-8 mx-2">
          {/* <div className="flex items-center">
            <Checkbox className="m-1" checked={publiclyVisible} onChange={changePublicVisibility} disabled={loading}>
              Publicly Visible in your Help Center
            </Checkbox>
            <Popover content="Publicly visible documents will be displayed on your help center page for your customers." placement="right">
              <QuestionCircleOutlined />
            </Popover>
          </div> */}
          {/* <div className="flex items-center">
            <Checkbox className="m-1" checked={trainChatbot} onChange={changeChatBotTrainable} disabled={loading}>
              Use to train TelePilot AI
            </Checkbox>
            <Popover content="TelePilot AI will learn from the content of the document you add for its automated responses." placement="right">
              <QuestionCircleOutlined />
            </Popover>
          </div> */}
        </div>
      </Modal>
    </div>
  );
}

export default FileUploadModal;
