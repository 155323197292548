import { Button, Input } from 'antd';
import MessageBubble from './MessageBubble';

const TelePilotIconBlack = 'https://d2l70220xt99tb.cloudfront.net/static-common-assets/tp_logo_final_black.svg';

function WidgetCard({
  selectedColor, chatHeading, chatSubHeading, welcomeMessage,
}) {
  return (
    <div className="flex flex-col text-black rounded-xl shadow-md justify-around w-5/6 mb-2">
      {/* <div className={`flex flex-col text-white bg-gradient-to-b rounded-t-xl ${selectedColor ?
       `from-[${widgetColors[selectedColor].DEFAULT}]
       to-[${widgetColors[selectedColor].darker}]` : 'from-accent1 to-accent2'} `}> */}
      <div style={{ backgroundColor: selectedColor }} className={`flex flex-col text-white rounded-t-xl ${selectedColor ? '' : 'bg-gradient-to-b from-accent1 to-accent2'} `}>
        <div className="p-4 text-start">
          <h3>{chatHeading}</h3>
          <h3>{chatSubHeading}</h3>
        </div>
      </div>
      <div className="border">
        <div className="h-[23rem] overflow-scroll border p-4">
          <MessageBubble message={welcomeMessage} color={selectedColor} />
        </div>
        <div className="flex justify-between items-center p-2">
          <Input
            placeholder="Please provide your name to continue"
            className="w-full mr-2"
          />
          <Button
            type="primary"
            style={{ backgroundColor: selectedColor }}
          >
            Start chat
          </Button>
        </div>
      </div>
      <div>
        <div style={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}>
          <div className="text-xs self-center flex justify-center opacity-20 hover:opacity-100 items-center">
            <p className="mr-2">AI enabled chats by</p>
            <img src={TelePilotIconBlack} alt="TelePilot AI" width={24} height={24} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WidgetCard;
