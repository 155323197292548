import {
  Button,
  Col, Divider, Row, Typography,
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import WidgetCard from './WidgetCard';
import ColorSelector from './ColorSelector';
import { updateWidgetConfigurations } from '../../store/widgetConfigurationsSlice';

function Style({
  copilotId, chatHeading, chatSubHeading, welcomeMessage, selectedColor, setSelectedColor,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: usageLimit } = useSelector((state) => state.usageLimit);

  const [loading, setLoading] = useState(false);
  const [widgetCustomizable, setWidgetCustomizable] = useState(false);
  const [initialColor, setInitialColor] = useState(selectedColor); // State to track the initial color

  useEffect(() => {
    const isCustomizable = usageLimit && usageLimit.widget_customizable;
    setWidgetCustomizable(!!isCustomizable);
  }, [usageLimit]);

  const handleWidgetColorChange = async () => {
    setLoading(true);
    const data = {
      widget_theme_color: selectedColor || null,
    };
    await dispatch(updateWidgetConfigurations({ id: copilotId, updates: data }));
    setLoading(false);
    setInitialColor(selectedColor);
  };

  const hasChanges = selectedColor !== initialColor;

  return (
    <div className="px-2 m-2 h-full relative">
      <Row className={`h-full ${widgetCustomizable ? 'opacity-100' : 'opacity-20'}`} gutter={16}>
        <Col span={12}>
          <div className="flex flex-col items-start h-full w-full">
            <h2 className="text-2xl">Color</h2>
            <span>Choose a color to customise chat experience for your users.</span>
            <div className="flex flex-col items-start w-full mb-8">
              <ColorSelector selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
              <Button type="dashed" className="m-2" icon={<ReloadOutlined />} onClick={() => setSelectedColor()}>Reset to Default</Button>
            </div>
            {/* <div className="flex"> */}
            <Button type="primary" className="m-2" onClick={handleWidgetColorChange} disabled={!widgetCustomizable || !(hasChanges)} loading={loading}>Save Changes</Button>
            {/* </div> */}
          </div>
        </Col>
        <Col span={2}>
          <Divider className="h-full" type="vertical" />
        </Col>
        <Col span={10}>
          <div className="flex justify-center h-full">
            <WidgetCard
              chatHeading={chatHeading}
              chatSubHeading={chatSubHeading}
              welcomeMessage={welcomeMessage}
              selectedColor={selectedColor}
            />
          </div>
        </Col>
      </Row>
      {!widgetCustomizable && (
      <div className="absolute inset-0 flex items-center justify-center z-10 cursor-default">
        <Typography.Text className="bg-white py-4 px-2 rounded shadow-md">
          Style customizations available from standard plans. Please
          {' '}
          <Button className="m-0 p-0" type="link" onClick={() => navigate('/settings/billing-plans')}>upgrade your plan</Button>
          {' '}
          to continue
        </Typography.Text>
      </div>
      )}
    </div>
  );
}

export default Style;
