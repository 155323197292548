import { useState } from 'react';
import {
  Form, Input, Button, message,
  //  Popover,
} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../../store/userSlice';
// import DeleteAccountModal from './DeleteAccountModal';

function SecuritySettings() {
  const dispatch = useDispatch();
  const { data: user } = useSelector((state) => state.user);
  const { agent } = user;
  const [formPassword] = Form.useForm();
  const [loadingPassword, setLoadingPassword] = useState(false);

  const [hasPasswordChanged, setHasPasswordChanged] = useState(false);
  // const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const handlePasswordSubmit = async (values) => {
    try {
      const { currentPassword, newPassword } = values;
      const data = {
        email: agent.email,
        currentPassword,
        newPassword,
      };
      setLoadingPassword(true);

      await dispatch(changePassword(data));

      setLoadingPassword(false);
    } catch (error) {
      setLoadingPassword(false);
      message.error('Something went wrong. Please try again later.');
    }
  };

  const handleCancelPassword = () => {
    formPassword.resetFields();
    setHasPasswordChanged(false);
  };

  const onPasswordValuesChange = (changedValues, allValues) => {
    // Check if the form has changed from its initial state
    const initialValues = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
    setHasPasswordChanged(JSON.stringify(allValues) !== JSON.stringify(initialValues));
  };

  return (
    <div className="flex flex-col items-start h-full">
      <div className="flex h-full flex-col w-1/2 items-start ml-2">
        {/* Change Password */}
        <p className="font-bold mb-5">Change Password</p>
        <Form
          layout="vertical"
          form={formPassword}
          onFinish={handlePasswordSubmit}
          className="w-full"
          onValuesChange={onPasswordValuesChange}
        >
          <Form.Item
            name="currentPassword"
            label="Current Password"
            rules={[{ required: true, message: 'Please enter your current password' }]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[{ required: true, message: 'Please enter your new password' }]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['newPassword']}
            rules={[
              { required: true, message: 'Please confirm your new password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match'));
                },
              }),
            ]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <div className="flex justify-end mb-8 self-end">
            <Button
              className="mx-2"
              type="primary"
              htmlType="submit"
              loading={loadingPassword}
              disabled={!hasPasswordChanged}
            >
              Change Password
            </Button>
            <Button onClick={handleCancelPassword} disabled={!hasPasswordChanged}>
              Cancel
            </Button>
          </div>
        </Form>
        {/* Delete Account */}
        {/* <div className="border border-dashed border-red-500 rounded-lg my-36 w-full p-4 flex flex-col items-start justify-around"> */}
        {/* <p className="font-bold">Delete Account</p> */}
        {/* <p className="text-sm text-red-600 text-start">
            Deleting your account is an irreversible action. By clicking
            &quot;Delete My Account,&quot;you understand and accept that
            all your data, settings, and access will be permanently removed.
            Please make sure you have reviewed the information provided before proceeding.
          </p> */}
        {/* <p className="text-start">
            If you have any concerns or questions, please contact our
            support team at
            {' '}
            <strong>support@telepilot.ai</strong>
            {' '}
            for assistance before taking this step.
          </p>
          <Button className="w-fit" type="primary" danger onClick={() => setDeleteModalVisible(true)} icon={<WarningOutlined />}>
            Delete My Account
          </Button>
          <DeleteAccountModal visible={deleteModalVisible} setVisible={setDeleteModalVisible} />
        </div> */}
      </div>
    </div>
  );
}

export default SecuritySettings;
