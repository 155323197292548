import {
  Button, Modal,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downgradeSubscription } from '../../../store/subscriptionSlice';

function DowngradeConfirm({ visible, setVisible, downgradeProduct }) {
  const dispatch = useDispatch();
  const { data: currentSubscription } = useSelector((state) => state.subscription);
  const { data: { subscriptionPlans: plans } } = useSelector((state) => state.plans);
  const [loading, setLoading] = useState(false);
  const [creditsApplied, setCreditsApplied] = useState(false);

  const chosenPeriod = downgradeProduct.period === 'month' ? 'monthly' : 'annual';

  const chosenPlan = plans[chosenPeriod].filter((plan) => plan.product_id === downgradeProduct.productId)[0];

  const { Product: { name, features }, Price: { recurring_interval } } = currentSubscription;

  const currentPeriod = recurring_interval === 'month' ? 'monthly' : 'annual';

  const checkIfCreditsApplied = () => {
    // credits will be applied if downgrading to plan within the same billing period
    if (chosenPlan.recurring_interval === recurring_interval) {
      setCreditsApplied(true);
    } else {
      setCreditsApplied(false);
    }
  };

  useEffect(() => {
    checkIfCreditsApplied();
  }, []);

  const handleSubscriptionDowngrade = async () => {
    setLoading(true);
    await dispatch(downgradeSubscription({ ...downgradeProduct }));
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    setLoading(false);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  return (
    <Modal
      title="Downgrade Subscription"
      centered
      open={visible}
      onCancel={handleCancel}
      footer={[
        <Button danger key="submit" loading={loading} onClick={handleSubscriptionDowngrade}>
          Downgrade Subscription
        </Button>,
        <Button key="back" type="primary" onClick={handleCancel}>
          Continue to stay on
          {' '}
          {name}
          {!creditsApplied && (
            ` (${currentPeriod})`
          )}
        </Button>,
      ]}
      width={800}
    >
      <div className="my-8">
        <p>
          You are currently on
          {' '}
          <strong>
            {name}
            {' '}
            plan
            {!creditsApplied && (
              ` (${currentPeriod})`
            )}
          </strong>
          {' '}
          with following benefits:

        </p>
        <div className="flex flex-col">
          {features.map((feature) => (
            <li className="text-gray-700">
              {'- '}
              {feature}
            </li>
          ))}
        </div>
        <p>
          By Downgrading to
          {' '}
          {chosenPlan['Product.name']}
          {' '}
          {chosenPeriod}
          {' '}
          plan you&apos;ll lose access to some of the features above.
        </p>
        {creditsApplied && <span className="text-gray-600">Remaining messages from your current plan will be available as extra credit till the end of its period</span>}
      </div>
    </Modal>
  );
}

export default DowngradeConfirm;
