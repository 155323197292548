/* eslint-disable consistent-return */
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { cookieDomain } from '../config';

const createParameterizedUrl = (url, params) => {
  let newUrl = url;
  Object.keys(params).forEach((param) => {
    const regex = new RegExp(`{${param}}`, 'g');
    newUrl = newUrl.replace(regex, params[param]);
  });
  return newUrl.replace(/\/\//g, '/');
};

const createQueriedUrl = (url, queries) => {
  let newUrl = url;
  Object.keys(queries).forEach((query, index) => {
    newUrl
      += index > 0 ? `&${query}=${queries[query]}` : `?${query}=${queries[query]}`;
  });
  return newUrl;
};

const tokenExpired = (token) => {
  const decodedToken = jwtDecode(token);
  return decodedToken.exp < Date.now() / 1000;
};

const crudData = async (
  serviceBaseURL,
  apiEndpoint,
  method,
  parameterObject, // https://example.com/:parameter
  queryObject,
  payload,
) => {
  const accessToken = Cookies.get('accessToken');
  let modifiedApiEndpoint = apiEndpoint;
  if (parameterObject) {
    modifiedApiEndpoint = createParameterizedUrl(
      modifiedApiEndpoint,
      parameterObject,
    );
  }
  if (queryObject) {
    modifiedApiEndpoint = createQueriedUrl(modifiedApiEndpoint, queryObject);
  }
  try {
    if (tokenExpired(accessToken)) {
      window.location.replace('/');
      Cookies.remove('accessToken', { domain: cookieDomain, path: '/' });
    }
    if (accessToken) {
      const headers = { Authorization: `Bearer ${accessToken}` };
      if (payload instanceof FormData) {
        headers.Accept = 'application/json';
      } else {
        headers['Content-Type'] = 'application/json';
      }
      const res = await fetch(serviceBaseURL + modifiedApiEndpoint, {
        method,
        headers,
        body: payload instanceof FormData ? payload : JSON.stringify(payload),
      })
        .catch((err) => new Error(err));
      return await res;
    }
  } catch (err) {
    // return console.error(err);
    throw new Error(err);
  }
};

export default crudData;
