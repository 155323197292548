import {
  Tour,
} from 'antd';

function GuidedTour({
  refs, openGuidedTour, setOpenGuidedTour,
}) {
  const {
    dashboardRef, cmsRef, copilotsRef, contactsRef, queriesRef, settingsRef,
  } = refs;
  const steps = [
    {
      title: 'Welcome',
      description: `Welcome to TelePilot AI! We've designed a suite of tools to streamline and elevate your customer support experience. 
      Let's take a guided tour to help you get started. Ready? Let's dive in!`,
      target: null,
    },
    {
      title: 'Dashboard',
      description: `Welcome to your Dashboard! Here, you'll gain insights into the pulse of your customer support operations. 
      Track crucial metrics, keep an eye on incoming requests, and understand your support trends. Use these insights to optimize 
      your customer service.`,
      placement: 'right',
      target: () => dashboardRef.current,
    },
    {
      title: 'Documents',
      description: `Welcome to the Documents! This is your knowledge powerhouse. Any help document or user guide 
      you wish to provide for users? Add them here. Our AI chatbot, TelePilot, draws from this section to answer 
      user queries. Decide which documents face your customers and which ones stay internal. Plus, control which 
      resources feed our AI's learning.`,
      placement: 'right',
      target: () => cmsRef.current,
    },
    {
      title: 'CoPilots',
      description: `Make unique CoPilots or chatbots created for specific purposes. 
      Train them on custom data and place on your website directly`,
      placement: 'right',
      target: () => copilotsRef.current,
    },
    {
      title: 'Contacts',
      description: `Find all leads generated by TelePilot AI powered chatbot here.
      You can also add and delete new contacts manually as well as export them from here.`,
      placement: 'right',
      target: () => contactsRef.current,
    },
    {
      title: 'Queries',
      description: 'Find all queries which require additional information as requested by users, which you can manually answer',
      placement: 'right',
      target: () => queriesRef.current,
    },
    {
      title: 'Settings',
      description: `Last but not least, the Settings! Personalization starts here. Update your profile, 
      change your password or email, and manage your device connections. And while you're here, fine-tune your 
      notification preferences and handle your billing details.`,
      placement: 'right',
      target: () => settingsRef.current,
    },
    {
      title: 'Thank You',
      description: `Thank you for joining us! If you have any questions along the way, remember, 
      TelePilot AI is here to help. Dive in and make the most of your TelePilot experience.`,
      target: null,
    },
  ];
  return (
    <Tour open={openGuidedTour} onClose={() => setOpenGuidedTour(false)} steps={steps} />
  );
}
export default GuidedTour;
