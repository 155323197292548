import { Modal } from 'antd';

function DeleteContactConfirm({
  visible, setVisible, handleContactDelete, setDeletionContact,
}) {
  const handleCancel = () => {
    setVisible(false);
    setDeletionContact(null);
  };

  return (
    <Modal
      title="Confirm Deletion"
      open={visible}
      onOk={handleContactDelete}
      onCancel={handleCancel}
      width={600}
      okText="Delete"
      okType="danger"
    >
      <div className="my-8">
        <p className="font-semibold">
          Are you sure you want to delete selected contact(s)?
        </p>
        <p>
          This action cannot be undone
        </p>
      </div>
    </Modal>
  );
}

export default DeleteContactConfirm;
