import { useDispatch, useSelector } from 'react-redux';
import {
  Space, Table,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { messageTimeStampFormat } from '../../../utils/dateTimeFormat';
import { getOrders } from '../../../store/ordersSlice';

const columns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => messageTimeStampFormat(createdAt).currentFormat,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (description) => `${description || 'other purchases'}`,
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: 'Amount',
    dataIndex: 'amount_captured',
    key: 'amount_captured',
    render: (payment) => `${payment}`,
  },
  {
    title: 'Method',
    dataIndex: 'payment_method_details',
    key: 'payment_method_details',
    render: (payment_method_details) => `${payment_method_details.card.brand} - ${payment_method_details.card.last4}`,
  },
  // {
  //   title: 'Receipt #',
  //   dataIndex: 'receipt_number',
  //   key: 'receipt_number',
  // },
  {
    title: 'Receipt',
    dataIndex: 'receipt_url',
    key: 'receipt_url',
    render: (url) => (
      <a href={url} target="_blank" rel="noreferrer">
        <Space>
          <DownloadOutlined className="cursor-pointer text-blue-400 hover:text-blue-900" />
        </Space>
      </a>
    ),
  },
];

function OrderHistoryTable() {
  const { data: orders } = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
  }, []);

  return (
    <div>
      <Table columns={columns} dataSource={orders} />
    </div>
  );
}

export default OrderHistoryTable;
