import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { billingURL } from '../config';

export const getOrders = createAsyncThunk('orders/getOrders', async () => {
  const response = await crudData(billingURL, '/orders', 'GET');
  const { data } = await response.json();
  return data;
});

const initialState = {
  data: [],
  status: requestStatuses.IDLE,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getOrders.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      });
  },
});

export default ordersSlice.reducer;
