import {
  Modal, Input, Form,
} from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Tags from '../common/Tags';
import { createContact } from '../../store/leadsContactsSlice';

function NewLeadModal({ modalVisible, setModalVisible }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);

  const handleSaveLead = () => {
    form.validateFields().then((values) => {
      dispatch(createContact(values));
      form.resetFields();
      setTags([]);
      setModalVisible(false);
    });
  };

  const handleTagsChange = (newTags) => {
    setTags(newTags); // Update the state when tags change
    form.setFieldsValue({ tags: newTags }); // Update the form's tags field
  };

  const handleCancel = () => {
    setTags([]);
    form.resetFields();
    setModalVisible(false);
  };
  return (
    <Modal
      title="Add New Contact"
      open={modalVisible}
      onOk={handleSaveLead}
      onCancel={handleCancel}
      okText="Save"
      cancelText="Cancel"
      width={600}
      height={90}
    >
      <Form form={form} layout="horizontal" className="my-8">

        {/* Name Field */}
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: 'Please input the name!' },
            { max: 50, message: 'Name cannot exceed 50 characters' },
          ]}
          labelCol={{ span: 3 }}
        >
          <Input className="w-80" />
        </Form.Item>

        {/* Email Field */}
        <Form.Item
          name="email"
          label="Email"
          labelCol={{ span: 3 }}
          rules={[
            { required: true, message: 'Please input the email!' },
            { type: 'email', message: 'The input is not a valid email!' },
          ]}
        >
          <Input className="w-80" />
        </Form.Item>

        {/* Phone Field */}
        <Form.Item
          name="phone"
          label="Phone"
          labelCol={{ span: 3 }}
          rules={[
            { pattern: /^\+?\d[\d -]{8,14}\d$/, message: 'Invalid phone number!' },
          ]}
        >
          <Input className="w-80" />
        </Form.Item>

        {/* Address Field */}
        {/* <Form.Item name="address" label="Address" labelCol={{ span: 3 }} rules={[{ required: true }]}>
          <Input className="w-80" />
        </Form.Item> */}

        {/* Tags Field */}
        <Form.Item name="tags" labelCol={{ span: 3 }} label="Tags">
          <Tags tags={tags} onTagsChange={handleTagsChange} />
        </Form.Item>

        {/* Notes Field */}
        <Form.Item name="notes" labelCol={{ span: 3 }} label="Notes" rules={[{ max: 200, message: 'Notes cannot exceed 200 characters' }]}>
          <Input.TextArea
            autoSize={{
              minRows: 2,
              maxRows: 3,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NewLeadModal;
