import React, { useRef, useState } from 'react';
import { Modal } from 'antd';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

function VideoModal({
  visible, setVisible, videoTitle, videoUrl, subtitlesUrl, thumbnaillUrl, subtitlesFile,
}) {
  const plyrRef = useRef(null);

  const handleCancel = () => {
    if (plyrRef.current && plyrRef.current.plyr) {
      plyrRef.current.plyr.stop(); // Use pause() or stop() method to stop the video
    }
    setVisible(false);
  };

  const videoSource = {
    type: 'video',
    title: videoTitle,
    sources: [
      {
        src: videoUrl,
        type: 'video/mp4',
        size: 1080,
      },
    ],
    poster: thumbnaillUrl,
    previewThumbnails: {
      src: thumbnaillUrl,
    },
    tracks: [
      {
        kind: 'captions',
        label: 'English',
        srclang: 'en',
        src: subtitlesUrl || subtitlesFile,
        default: true,
      },
    ],
  };

  return (
    <Modal
      title={videoTitle}
      open={visible}
      onCancel={handleCancel}
      footer={null}
      className="!max-w-none !w-full md:!w-4/5 lg:!w-2/3"
      style={{ top: 50 }}
    >
      <Plyr
        ref={plyrRef}
        source={videoSource}
      />
    </Modal>
  );
}

export default VideoModal;
