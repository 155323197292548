import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { baseURL } from '../config';

export const getUsageCounter = createAsyncThunk('usageCounter/getUsageCounter', async () => {
  const response = await crudData(baseURL, '/accounts/usage-counter', 'GET');
  const { data } = await response.json();
  return data;
});

const initialState = {
  data: {},
  status: requestStatuses.IDLE,
};

const usageCounterSlice = createSlice({
  name: 'usageCounter',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUsageCounter.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getUsageCounter.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getUsageCounter.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      });
  },
});

export default usageCounterSlice.reducer;
