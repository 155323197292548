import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Button, Checkbox, Modal, Popover, message,
} from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { writeHelpDoc } from '../../store/helpCMSSlice';

function SavePublishModal({
  documentTitle, documentContent, visible, setVisible,
}) {
  const dispatch = useDispatch();
  const { writeHelpDocStatus } = useSelector((state) => state.helpCMS);
  const navigate = useNavigate();
  const [trainChatbot, setTrainChatbot] = useState(true);
  const [publiclyVisible, setPubliclyVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  const saveHelpDoc = async () => {
    try {
      setLoading(true);
      await dispatch(
        writeHelpDoc({
          title: documentTitle,
          content: documentContent,
          publiclyVisible,
          trainChatbot,
          publish: true,
        }),
      );
      if (writeHelpDocStatus === 'success') {
        message.success('Document saved successfully');
      } else if (writeHelpDocStatus === 'failed') {
        message.error('Error saving document');
      }
      setTimeout(() => {
        navigate('/documents');
      }, 1000);
      setLoading(false);
      setVisible(false);
    } catch (error) {
      setLoading(false);
      setVisible(false);
      message.error(`Error saving document. ${error.message}`);
    }
  };

  const changePublicVisibility = () => {
    const currentState = publiclyVisible;
    setPubliclyVisible(!currentState);
  };

  const changeChatBotTrainable = () => {
    const currentState = trainChatbot;
    setTrainChatbot(!currentState);
  };
  return (
    <div>
      <Modal
        title={`Save & Publish ${documentTitle}`}
        centered
        open={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={saveHelpDoc} loading={loading}>
            Publish
          </Button>,
        ]}
      >
        {/* <p>Define visibility of the published document</p> */}
        <div className="flex flex-col m-2">
          {/* <div className="flex items-center">
            <Checkbox className="m-1" checked={publiclyVisible} onChange={changePublicVisibility}>
              Publicly Visible in your Help Center
            </Checkbox>
            <Popover content="Publicly visible documents will be displayed on your help center page for your customers." placement="right">
              <QuestionCircleOutlined />
            </Popover>
          </div> */}
          {/* <div className="flex items-center">
            <Checkbox className="m-1" checked={trainChatbot} onChange={changeChatBotTrainable}>
              Use to train TelePilot AI
            </Checkbox>
            <Popover content="TelePilot AI will learn from the content of the document you add for its automated responses." placement="right">
              <QuestionCircleOutlined />
            </Popover>
          </div> */}
        </div>
      </Modal>

    </div>
  );
}

export default SavePublishModal;
