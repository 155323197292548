import {
  Drawer, Divider, Button,
} from 'antd';
import LanguageIcon from '@mui/icons-material/Language';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { cookieDomain } from '../../config';

function AgentProfileDrawer({ visible, onClose }) {
  const { data } = useSelector((state) => state.user);
  const { agent, company } = data;
  const logout = () => {
    Cookies.remove('accessToken', { path: '/', domain: cookieDomain });
    window.location.reload();
  };
  return (
    <Drawer
      open={visible}
      onClose={onClose}
      width={400}
      placement="right"
    >
      <div className="p-4 flex flex-col cursor-default">
        {/* {agent && agent.profilePicture ? (
          <Avatar
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
            }}
            src={agent.profilePicture}
          />
        ) : (
          <Avatar
            style={{
              backgroundColor: '#87d068',
               display: 'flex',
               alignItems: 'center',
                justifyContent: 'center', cursor: 'pointer',
            }}
            icon={<UserOutlined />}
          />
        )} */}
        {agent && (
        <>
          <h3 className="text-xl mt-2">
            {agent.firstName}
            {' '}
            {agent.lastName}
          </h3>
          <span className="text-gray-500 mb-2">{agent.email}</span>
          <Divider />

          {/* Additional company details */}
          <div className="flex flex-col">
            <p>
              <strong>Company Name:</strong>
              {' '}
              {company.name}
            </p>
            <div className="flex items-center">
              <LanguageIcon />
              <strong className="mx-2">Web:</strong>
              <span className="mx-2">
                {company.website}
              </span>
            </div>
            {/* <p>Country: {agent.Company.country}</p>
        <p>Timezone: {agent.Company.timezone}</p> */}
          </div>
        </>
        )}
        <Divider />

        {/* Logout button */}
        <Button
          className="w-full flex items-center justify-center"
          type="primary"
          icon={<LogoutIcon />}
          onClick={() => logout()}
        >
          Logout
        </Button>
      </div>
    </Drawer>
  );
}

export default AgentProfileDrawer;
