import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import requestStatuses from '../constants/requestStatuses';
import { helpURL } from '../config';
import crudData from '../utils/apiService';

export const getAllHelpDocs = createAsyncThunk('helpCMS/getAllHelpDocs', async () => {
  const response = await await crudData(helpURL, '/docs', 'GET');
  const { data } = await response.json();
  return data;
});

export const uploadHelpFile = createAsyncThunk('helpCMS/uploadHelpFile', async ({ files, publiclyVisible, trainChatbot }) => {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('sources', file);
    });
    formData.append('publiclyVisible', publiclyVisible);
    formData.append('trainChatbot', trainChatbot);
    const response = await crudData(helpURL, '/new-upload', 'POST', {}, {}, formData).then((res) => res.json());
    if (!response.success) {
      message.error(response.message);
      throw new Error(response.message);
    }
    message.success(response.message);
    const { data } = await response;
    return data;
  } catch (error) {
    message.error('Error in uploading files. Please try again later.');
    throw new Error('Error in uploading files. Please try again later.');
  }
});

export const addHelpLinks = createAsyncThunk('helpCMS/addHelpLinks', async ({ links, publiclyVisible, trainChatbot }) => {
  const response = await crudData(helpURL, '/new', 'POST', {}, {}, {
    sources: links, publiclyVisible, trainChatbot, sourceType: 'WEB',
  }).then((res) => res.json());
  if (!response.success) {
    message.error(response.message);
    throw new Error(response.message);
  }
  message.success(response.message);
  const { data } = await response;
  return data;
});

export const writeHelpDoc = createAsyncThunk('helpCMS/writeHelpDoc', async ({
  title, content, publiclyVisible, trainChatbot, publish,
}) => {
  try {
    const response = await crudData(helpURL, '/new', 'POST', {}, {}, {
      title, content, publiclyVisible, trainChatbot, sourceType: 'WRITEUP', publish,
    });
    const { success, message: resMessage, data } = await response.json();
    if (!success) {
      throw new Error(resMessage);
    }
    return data;
  } catch (error) {
    return message.error(`Error in writing help doc. ${error} `);
  }
});

export const deleteHelpDoc = createAsyncThunk('helpCMS/deleteHelpDoc', async (id) => {
  try {
    const response = await crudData(helpURL, '/delete-docs', 'DELETE', {}, {}, { docIds: [id] });
    const { success, message: resMessage } = await response.json();
    if (!success) {
      throw new Error(resMessage);
    }
    message.success(resMessage);
    return id;
  } catch (error) {
    return message.error(`Error in deleting help doc. ${error} `);
  }
});

export const changePublishState = createAsyncThunk('helpCMS/changePublishState', async ({ id, publishState, docType }) => {
  try {
    const response = await crudData(helpURL, '/publish', 'PUT', {}, {}, { docIds: [id], publishState, docType });
    const { success, message: resMessage } = await response.json();
    if (!success) {
      throw new Error(resMessage);
    }
    message.success(resMessage);
    return id;
  } catch (error) {
    return message.error(`Error in changing publish state. ${error} `);
  }
});

export const changeTrainChatbotState = createAsyncThunk('helpCMS/changeTrainChatbotState', async ({ id, trainChatbot }) => {
  try {
    const response = await crudData(helpURL, '/privacy', 'PUT', {}, {}, { docIds: [id], docsPrivacy: { trainChatbot } });
    const { success, message: resMessage } = await response.json();
    if (!success) {
      throw new Error(resMessage);
    }
    message.success(resMessage);
    return id;
  } catch (error) {
    return message.error(`Error in changing training state. ${error} `);
  }
});

const initialState = {
  helpDocs: [],
  getAllDocsStatus: requestStatuses.IDLE,
  uploadFileStatus: requestStatuses.IDLE,
  addHelpLinksStatus: requestStatuses.IDLE,
  writeHelpDocStatus: requestStatuses.IDLE,
};

const helpCMSSlice = createSlice({
  name: 'helpCMS',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllHelpDocs.pending, (state) => {
        state.getAllDocsStatus = requestStatuses.LOADING;
      })
      .addCase(getAllHelpDocs.fulfilled, (state, action) => {
        state.getAllDocsStatus = requestStatuses.SUCCESS;
        state.helpDocs = action.payload;
      })
      .addCase(getAllHelpDocs.rejected, (state) => {
        state.getAllDocsStatus = requestStatuses.FAILED;
      })
      .addCase(uploadHelpFile.pending, (state) => {
        state.uploadFileStatus = requestStatuses.LOADING;
      })
      .addCase(uploadHelpFile.fulfilled, (state, action) => {
        state.uploadFileStatus = requestStatuses.SUCCESS;
        state.helpDocs = [...state.helpDocs, ...action.payload];
      })
      .addCase(uploadHelpFile.rejected, (state) => {
        state.uploadFileStatus = requestStatuses.FAILED;
      })
      .addCase(addHelpLinks.pending, (state) => {
        state.addHelpLinksStatus = requestStatuses.LOADING;
      })
      .addCase(addHelpLinks.fulfilled, (state, action) => {
        state.addHelpLinksStatus = requestStatuses.SUCCESS;
        state.helpDocs = [...state.helpDocs, ...action.payload];
      })
      .addCase(addHelpLinks.rejected, (state) => {
        state.addHelpLinksStatus = requestStatuses.FAILED;
      })
      .addCase(writeHelpDoc.pending, (state) => {
        state.writeHelpDocStatus = requestStatuses.LOADING;
      })
      .addCase(writeHelpDoc.fulfilled, (state, action) => {
        state.writeHelpDocStatus = requestStatuses.SUCCESS;
        state.helpDocs = [...state.helpDocs, ...action.payload];
      })
      .addCase(writeHelpDoc.rejected, (state) => {
        state.writeHelpDocStatus = requestStatuses.FAILED;
      })
      .addCase(deleteHelpDoc.pending, (state) => {
        state.deleteHelpDocStatus = requestStatuses.LOADING;
      })
      .addCase(deleteHelpDoc.fulfilled, (state, action) => {
        state.deleteHelpDocStatus = requestStatuses.SUCCESS;
        state.helpDocs = state.helpDocs.filter((doc) => doc.id !== action.payload);
      })
      .addCase(deleteHelpDoc.rejected, (state) => {
        state.deleteHelpDocStatus = requestStatuses.FAILED;
      })
      .addCase(changePublishState.pending, (state) => {
        state.changePublishStateStatus = requestStatuses.LOADING;
      })
      .addCase(changePublishState.fulfilled, (state, action) => {
        state.changePublishStateStatus = requestStatuses.SUCCESS;
        state.helpDocs = state.helpDocs.map((doc) => {
          if (doc.id === action.payload) {
            return { ...doc, published: !doc.published };
          }
          return doc;
        });
      })
      .addCase(changePublishState.rejected, (state) => {
        state.changePublishStateStatus = requestStatuses.FAILED;
      })
      .addCase(changeTrainChatbotState.pending, (state) => {
        state.changeTrainChatbotStateStatus = requestStatuses.LOADING;
      })
      .addCase(changeTrainChatbotState.fulfilled, (state, action) => {
        state.changeTrainChatbotStateStatus = requestStatuses.SUCCESS;
        state.helpDocs = state.helpDocs.map((doc) => {
          if (doc.id === action.payload) {
            return { ...doc, trainChatbot: !doc.trainChatbot };
          }
          return doc;
        });
      })
      .addCase(changeTrainChatbotState.rejected, (state) => {
        state.changeTrainChatbotStateStatus = requestStatuses.FAILED;
      });
  },
});

export default helpCMSSlice.reducer;
