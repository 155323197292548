// import { Input } from 'antd';
import {
  CirclePicker,
} from 'react-color';

function ColorSelector({ selectedColor, setSelectedColor }) {
  // const [color, setColor] = useState('#000000');
  // const [showAdvanced, setShowAdvanced] = useState(false);

  const handleColorChange = (colorObj) => {
    setSelectedColor(colorObj.hex.trim());
  };

  // const handleHexChange = (e) => {
  //   setSelectedColor(`#${e.target.value.trim()}`);
  // };

  // const colorPickerStyle = {
  //   default: {
  //     picker: {
  //       width: '300px', // or any custom width you want
  //       boxSizing: 'initial',
  //     },
  //   },
  // };

  const customColors = [
    '#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3',
    '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39',
    '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#607D8B',
  ];

  return (
    <div className="p-2 my-8 mx-2 w-full">
      <CirclePicker
        color={selectedColor}
        colors={customColors}
        onChangeComplete={handleColorChange}
        className="w-screen"
      />
      {/* <Input
        className="w-full"
        value={selectedColor}
        onChange={handleHexChange}
        addonBefore="Hex #"
      /> */}
      {/* <Collapse className="my-4 w-full" ghost>
        <Collapse.Panel header="Advanced Options" key="1">
          <SketchPicker
            styles={colorPickerStyle}
            color={color}
            onChangeComplete={handleColorChange}
          />
        </Collapse.Panel>
      </Collapse> */}
      {/* <Row>
        <Button className="mr-2 my-4" onClick={() => setShowAdvanced(!showAdvanced)}>
          {showAdvanced ? 'Close Advanced Options' : 'Open Advanced Options'}
        </Button>

        {showAdvanced && (
        <SketchPicker
          color={color}
          onChangeComplete={handleColorChange}
        />
        )}
      </Row> */}
    </div>
  );
}

export default ColorSelector;
