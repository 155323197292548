/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Alert, Button, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Style from '../widget/Style';
import Flow from '../widget/Flow';
import { getWidgetConfigurations, clearWidgetConfigs } from '../../store/widgetConfigurationsSlice';

const widgetProperties = [
  {
    label: 'Style',
    value: 'style',
  },
  {
    label: 'Flow',
    value: 'flow',
  },
];

function WidgetPage({ copilotId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: usageLimit } = useSelector((state) => state.usageLimit);
  const { data: widgetConfigurations } = useSelector((state) => state.widgetConfigurations);

  const [properties, setProperties] = useState(widgetProperties);
  const [widgetCustomizable, setWidgetCustomizable] = useState(false);

  useEffect(() => {
    const isCustomizable = usageLimit && usageLimit.widget_customizable;
    setWidgetCustomizable(!!isCustomizable);
  }, [usageLimit]);

  useEffect(() => {
    dispatch(getWidgetConfigurations(copilotId));
    return () => {
      dispatch(clearWidgetConfigs());
    };
  }, []);

  const [chatHeading, setChatHeading] = useState('Hi there! 👋');
  const [chatSubHeading, setChatSubHeading] = useState('Need help with something?');
  const [welcomeMessage, setWelcomeMessage] = useState('Hello, how can i help you today?');

  const [widgetProperty, setWidgetProperty] = useState('style');
  const [propertyPage, setPropertyPage] = useState(<Style />);

  const [selectedColor, setSelectedColor] = useState();

  const handleWidgetConfigurations = () => {
    if (widgetConfigurations) {
      if (widgetConfigurations.widget_theme_color) {
        setSelectedColor(widgetConfigurations.widget_theme_color);
      }
      if (widgetConfigurations.chat_heading) {
        setChatHeading(widgetConfigurations.chat_heading);
      }
      if (widgetConfigurations.chat_subheading) {
        setChatSubHeading(widgetConfigurations.chat_subheading);
      }
      if (widgetConfigurations.welcome_message) {
        setWelcomeMessage(widgetConfigurations.welcome_message);
      }
    }
  };

  useEffect(() => {
    handleWidgetConfigurations();
  }, [widgetConfigurations]);

  const onChange = (e) => {
    setWidgetProperty(e.target.value);
  };

  useEffect(() => {
    if (widgetProperty === 'style') {
      setPropertyPage(<Style
        copilotId={copilotId}
        chatHeading={chatHeading}
        chatSubHeading={chatSubHeading}
        welcomeMessage={welcomeMessage}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
        widgetCustomizable={widgetCustomizable}
      />);
    } else if (widgetProperty === 'flow') {
      setPropertyPage(<Flow
        copilotId={copilotId}
        chatHeading={chatHeading}
        setChatHeading={setChatHeading}
        chatSubHeading={chatSubHeading}
        setChatSubHeading={setChatSubHeading}
        welcomeMessage={welcomeMessage}
        setWelcomeMessage={setWelcomeMessage}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
        widgetCustomizable={widgetCustomizable}
      />);
    }
    return () => {
      setPropertyPage();
    };
  }, [widgetProperty,
    chatHeading,
    chatSubHeading,
    welcomeMessage,
    setSelectedColor,
    selectedColor]);

  return (
    <div>
      {!widgetCustomizable && (
      <Alert
        className="mb-4"
        message="Consider upgrading your plan! Personalize your chat widget to beautifully resonate with your brand. Enhance your experience today!"
        banner
        type="info"
        closable
        action={(
          <Button size="small" type="primary" onClick={() => navigate('/settings/billing-plans')}>
            Upgrade
          </Button>
          )}
      />
      )}
      <div className="flex flex-col h-5/6 px-4">
        <Radio.Group value={widgetProperty} onChange={onChange} style={{ marginBottom: 16 }}>
          {
                properties.map((property) => (
                  <Radio.Button value={property.value}>{property.label}</Radio.Button>
                ))
            }
        </Radio.Group>
        {propertyPage}
      </div>
    </div>
  );
}

export default WidgetPage;
