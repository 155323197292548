import { Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PlanBillingsLanding from '../settings/PlanBillingsLanding';
import ProfileSettings from '../settings/ProfileSettings/ProfileSettings';
import SecuritySettings from '../settings/SecuritySettings/SecuritySettings';
// import NotificationsSettings from '../settings/NotificationsSettings';
import { setNav } from '../../store/navSlice';

const settingsTabs = [
  {
    key: 'profile',
    label: 'Profile',
    path: '/settings/profile',
    children: <ProfileSettings />,
  },
  {
    key: 'security',
    label: 'Security',
    path: '/settings/security',
    children: <SecuritySettings />,
  },
  // {
  //   key: 'notifications',
  //   label: 'Notifications',
  //   children: <NotificationsSettings />,
  // },
  {
    key: 'billingPlan',
    label: 'Billing and Plans',
    path: '/settings/billing-plans',
    children: <PlanBillingsLanding />,
  },
];

const settingsPageInfo = `You can personalize your profile, change your password, 
update your email address, and manage your connected devices. 
You can also manage your notification and billing preferences here.`;

function SettingsPage() {
  const navigate = useNavigate();
  const location = useLocation();

  // Set active tab key based on the current route
  const activeKey = settingsTabs.find((item) => item.path === location.pathname)?.key || 'billingPlan';

  const handleTabChange = (key) => {
    const newPath = settingsTabs.find((item) => item.key === key)?.path;
    if (newPath) {
      navigate(newPath);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setNav({ title: 'Settings', about: settingsPageInfo }));
    return () => {
      dispatch(setNav());
    };
  }, []);
  return (
    <div className="py-2 px-5">

      <Tabs
        activeKey={activeKey}
        onChange={handleTabChange}
        items={settingsTabs}
      />
    </div>
  );
}

export default SettingsPage;
