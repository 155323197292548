import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { baseURL } from '../config';

export const getWidgetConfigurations = createAsyncThunk('widgetConfigurations/getWidgetConfigurations', async (id) => {
  const response = await crudData(baseURL, '/widget-configurations/{id}', 'GET', { id });
  const { data } = await response.json();
  return data;
});

export const updateWidgetConfigurations = createAsyncThunk('widgetConfigurations/updateWidgetConfigurations', async ({ id, updates }) => {
  const response = await crudData(baseURL, '/widget-configurations/{id}', 'PUT', { id }, {}, { ...updates }).then((res) => res.json());
  if (!response.success) {
    message.error(response.message);
    throw new Error(response.message);
  }
  message.success(response.message);
  const { data } = await response;
  return data;
});

const initialState = {
  data: null,
  status: requestStatuses.IDLE,
};

const widgetConfigurationsSlice = createSlice({
  name: 'widgetConfigurations',
  initialState,
  reducers: {
    clearWidgetConfigs: (state, action) => { state.data = null; },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWidgetConfigurations.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getWidgetConfigurations.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getWidgetConfigurations.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(updateWidgetConfigurations.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(updateWidgetConfigurations.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(updateWidgetConfigurations.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      });
  },
});

export default widgetConfigurationsSlice.reducer;
export const { clearWidgetConfigs } = widgetConfigurationsSlice.actions;
