import { Button, Modal, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import crudData from '../../utils/apiService';
import { baseURL } from '../../config';

function SupportModal({ visible, setVisible }) {
  const { data: userData } = useSelector((state) => state.user);
  const { company } = userData;
  const [loading] = useState(false);
  const [supportQuery, setSupportQuery] = useState('');

  const handleQueryInput = (e) => {
    const newQuery = e.target.value;
    if (newQuery.length <= 2000) {
      setSupportQuery(e.target.value);
    }
  };
  const handleCancel = () => {
    setVisible(false);
    setSupportQuery('');
  };

  const handleSubmit = async () => {
    try {
      const supportReq = await crudData(baseURL, '/support-requests', 'POST', {}, {}, { message: supportQuery })
        .then((res) => res.json())
        .catch((err) => message.error(err));
      if (supportReq.success) {
        message.success(supportReq.message);
      } else {
        message.error(supportReq.message);
      }
    } catch (error) {
      message.error('Error submitting support request');
    }
    setVisible(false);
    setSupportQuery('');
  };

  const handleSendMail = () => {
    setTimeout(() => {
      setVisible(false);
      message.info('Support request opened on mailbox');
    }, 1000);
    const epochTime = Date.now();
    const subject = `TelePilot AI Support [${company?.id}/${epochTime}]`;
    const mailtoString = `mailto:support@telepilot.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(supportQuery)}`;
    window.location.href = mailtoString;
  };

  return (
    <Modal
      title="Request Support"
      centered
      open={visible}
      onCancel={() => {}}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" disabled={supportQuery.length === 0} loading={loading} onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
      closable={false}
      width={700}
    >
      <div className="flex flex-col">
        <span className="my-2 text-gray-600">
          {`Need assistance? We're here to help! Please type in your question or issue below.
         Be as detailed as possible so we can provide the best assistance.`}
        </span>
        <TextArea
          value={supportQuery}
          maxLength={2000}
          className="my-4"
          onChange={handleQueryInput}
          placeholder="I have an issue with ..."
          autoSize={{
            minRows: 7,
            maxRows: 7,
          }}
        />
        <span className="my-2 text-gray-700">
          Prefer Email? Please feel free to drop us a mail at
          {' '}
          <Button className="mx-0 p-0" type="link" onClick={handleSendMail}>
            support@telepilot.ai
          </Button>
        </span>
      </div>
    </Modal>
  );
}

export default SupportModal;
