// import { EditOutlined, LinkOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button, Card, Modal, Tooltip,
} from 'antd';
import Meta from 'antd/es/card/Meta';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LinkIcon from '../../assets/link.svg';
import UploadIcon from '../../assets/upload.svg';
import WriteIcon from '../../assets/write.svg';

function AddNewDocumentModal({
  visible, setVisible, setOpenAddLinkModal, setOpenFileUploadModal,
}) {
  const navigate = useNavigate();
  const { data: usageLimit } = useSelector((state) => state.usageLimit);

  const handleAddLink = () => {
    // setVisible(false);
    setOpenAddLinkModal(true);
  };

  const handleUploadFile = () => {
    setVisible(false);
    setOpenFileUploadModal(true);
  };

  const handleWriteDocument = () => {
    setVisible(false);
    navigate('/documents/new-article');
  };

  return (
    <Modal
      title="Add New Document To Your Help Center"
      centered
      open={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
      ]}
      width={800}
    >
      <div className="flex p-4 m-4 justify-around">
        {usageLimit.doc_via_link && (
        <div className="opacity-90 hover:opacity-100">
          <Card
            hoverable
            title="Add Links"
            style={{ width: 200, height: 230 }}
            onClick={handleAddLink}
            cover={<img alt="add link" src={LinkIcon} className="w-16 h-16 mt-8 mb-2" />}
          >
            <Meta description="You can directly add all the site links" />
          </Card>
        </div>
        )}
        <Tooltip title={usageLimit.doc_via_upload ? 'Add new help doc using file upload' : 'Please upgrade your plan to be able to upload file to create help doc'} placement="bottom">
          <div className={`${usageLimit.doc_via_upload ? 'opacity-90 hover:opacity-100' : 'opacity-50 cursor-default'}`}>
            <Card
              hoverable={usageLimit.doc_via_upload}
              title="Upload File"
              onClick={usageLimit.doc_via_upload ? handleUploadFile : () => {}}
              style={{ width: 200, height: 230 }}
              cover={<img alt="add link" src={UploadIcon} className="w-16 h-16 mt-8 mb-2" />}
            >
              <Meta description="You can upload file from your computer" />
            </Card>
          </div>
        </Tooltip>
        <Tooltip title={usageLimit.doc_via_writeup ? 'Add new help doc using writeup' : 'Please upgrade your plan to be able to write your own help doc'} placement="bottom">
          <div className={`${usageLimit.doc_via_writeup ? 'opacity-90 hover:opacity-100' : 'opacity-50 cursor-default'}`}>
            <Card
              hoverable={usageLimit.doc_via_writeup}
              title="Write Article"
              style={{ width: 200, height: 230 }}
              onClick={usageLimit.doc_via_writeup ? handleWriteDocument : () => {}}
              cover={<img alt="add link" src={WriteIcon} className="w-16 h-16 mt-8 mb-2" />}
            >
              <Meta description="You can also write your own help article here" />
            </Card>
          </div>
        </Tooltip>
      </div>
    </Modal>
  );
}
export default AddNewDocumentModal;
