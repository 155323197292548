import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { baseURL, ssoBackendURL } from '../config';

export const getUserDetails = createAsyncThunk('user/getUserDetails', async () => {
  const response = await crudData(baseURL, '/agents/me', 'GET');
  const { data } = await response.json();
  return data;
});

export const updateUserDetails = createAsyncThunk('user/updateUserDetails', async (agentDetails) => {
  const response = await crudData(baseURL, '/agents/me', 'PUT', {}, {}, { ...agentDetails }).then((res) => res.json());
  if (!response.success) {
    return message.error(response.message);
  }
  message.success(response.message);
  return response.data.agent;
});

export const updateCompanyDetails = createAsyncThunk('user/updateCompanyDetails', async (companyDetails) => {
  const response = await crudData(baseURL, '/accounts/company-details', 'PUT', {}, {}, { ...companyDetails }).then((res) => res.json());
  if (!response.success) {
    return message.error(response.message);
  }
  message.success(response.message);
  return response.data.company;
});

export const changePassword = createAsyncThunk('user/changePassword', async ({ email, currentPassword, newPassword }) => {
  const response = await crudData(ssoBackendURL, '/auth/change-password', 'PUT', {}, {}, { email, currentPassword, newPassword }).then((res) => res.json());
  if (!response.success) {
    return message.error(response.message);
  }
  return message.success(response.message);
});

const initialState = {
  data: [],
  status: requestStatuses.IDLE,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getUserDetails.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(updateUserDetails.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data.agent = action.payload;
      })
      .addCase(updateUserDetails.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(updateCompanyDetails.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(updateCompanyDetails.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data.company = action.payload;
      })
      .addCase(updateCompanyDetails.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(changePassword.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.status = requestStatuses.SUCCESS;
      })
      .addCase(changePassword.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      });
  },
});

export default userSlice.reducer;
