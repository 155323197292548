import { useState, useEffect, useRef } from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
// import WidgetsIcon from '@mui/icons-material/Widgets';
import ContactsIcon from '@mui/icons-material/Contacts';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import SettingsIcon from '@mui/icons-material/Settings';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import TelePilotLogoWithName from '../../assets/logo-with-name-white.png';
import GuidedTour from './GuidedTour';

const { Sider } = Layout;

function SideBar({ openGuidedTour, setOpenGuidedTour }) {
  const location = useLocation();

  const dashboardRef = useRef(null);
  const cmsRef = useRef(null);
  const copilotsRef = useRef(null);
  const contactsRef = useRef(null);
  const queriesRef = useRef(null);
  const settingsRef = useRef(null);

  const sideNavItems = [
    {
      key: 'dashboard',
      icon: <DashboardIcon style={{ fontSize: '1.5rem', color: '#fff' }} />,
      label: 'Dashboard',
      link: '/dashboard',
      ref: dashboardRef,
    },
    {
      key: 'documents',
      icon: <FolderIcon style={{ fontSize: '1.5rem', color: '#fff' }} />,
      label: 'Documents',
      link: '/documents',
      ref: cmsRef,
    },
    // {
    //   key: 'widget',
    //   icon: <WidgetsIcon style={{ fontSize: '1.5rem', color: '#fff' }} />,
    //   label: 'Integrations',
    //   link: '/widget',
    //   ref: widgetRef,
    // },
    {
      key: 'co-pilots',
      icon: <HeadsetMicIcon style={{ fontSize: '1.5rem', color: '#fff' }} />,
      label: 'CoPilots (Bots)',
      link: '/co-pilots',
      ref: copilotsRef,
    },
    {
      key: 'contacts',
      icon: <ContactsIcon style={{ fontSize: '1.5rem', color: '#fff' }} />,
      label: 'Contacts',
      link: '/contacts',
      ref: contactsRef,
    },
    {
      key: 'queries',
      icon: <RecordVoiceOverIcon style={{ fontSize: '1.5rem', color: '#fff' }} />,
      label: 'Queries',
      link: '/queries',
      ref: queriesRef,
    },
    {
      key: 'settings',
      icon: <SettingsIcon style={{ fontSize: '1.5rem', color: '#fff' }} />,
      label: 'Settings',
      link: '/settings/billing-plans',
      ref: settingsRef,
    },
  ];

  const [selectedKey, setSelectedKey] = useState('dashboard');

  useEffect(() => {
    const path = location.pathname;
    const pathKey = path.split('/')[1];
    if (pathKey === '') setSelectedKey('dashboard');
    else setSelectedKey(pathKey);
  }, [location]);

  return (
    <>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          className="p-2 m-2"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            width: '100%',
            // fontFamily: 'OpenSans Regular, sans-serif',
            fontSize: '20px',
            float: 'left',
            color: 'white',
            cursor: 'pointer',
          }}
        >
          <div className="flex flex-row items-center justify-center">
            <img className="h-full w-36" src={TelePilotLogoWithName} alt="logo" />
          </div>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['dashboard']}
          selectedKeys={selectedKey}
        >

          {sideNavItems.map((item) => (
            <Menu.Item ref={item.ref} className="text-white" key={item.key} icon={item.icon}>
              <div ref={item.ref}>
                <Link to={item.link}>
                  {item.label}
                </Link>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <GuidedTour
        refs={{
          dashboardRef, cmsRef, copilotsRef, contactsRef, queriesRef, settingsRef,
        }}
        openGuidedTour={openGuidedTour}
        setOpenGuidedTour={setOpenGuidedTour}
      />
    </>
  );
}

export default SideBar;
