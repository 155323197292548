import {
  formatDistanceToNow, parseISO, format, isBefore, subHours,
} from 'date-fns';

const messageTimeStampFormat = (dateStr) => {
  if (!dateStr) return '';

  // parse the string into a date
  const date = parseISO(dateStr);

  const formattedDate = format(date, 'p, d MMM yyyy');

  // get the current date
  const now = new Date();

  // calculate how long ago the date was
  let distanceToNow = null;
  if (isBefore(date, subHours(now, 24))) {
    // if the date is more than 7 days ago, don't show the distance to now
    distanceToNow = formattedDate;
  } else {
    // otherwise, calculate the distance to now
    distanceToNow = formatDistanceToNow(date, { addSuffix: true });
  }

  // return the string
  return {
    agoFormat: distanceToNow,
    currentFormat: formattedDate,
  };
};

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

function convertToReadableDate(isoString) {
  const date = new Date(isoString);

  // Format: "21 January 2024 10:48 AM"
  return date.toLocaleString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
}

function isInCurrentWeek(date) {
  const now = new Date();
  const firstDayOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 1));
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

// Function to check if a date is in the current month
function isInCurrentMonth(date) {
  const now = new Date();
  return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
}

export {
  messageTimeStampFormat, formatDate, convertToReadableDate, isInCurrentWeek, isInCurrentMonth,
};
