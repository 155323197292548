/* eslint-disable consistent-return */
import { Radio, message } from 'antd';
import { useEffect, useState } from 'react';
// import PricingCard from './PricingCard';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import AddOnCard from './AddOnCard';
import PlanCard from './PlanCard';
import { getPlans } from '../../../store/plansSlice';
import crudData from '../../../utils/apiService';
import { billingURL } from '../../../config';
import BillingPopup from '../BillingPopup';
import DowngradeConfirm from './DowngradeConfirm';

// const currentPlanDetails = subscriptionPlans.find((plan) => plan.planId === currentPlan.planId);

function PlanSettings() {
  const { data: subscriptionData } = useSelector((state) => state.subscription);
  const [selectedPeriod, setSelectedPeriod] = useState(subscriptionData?.Price?.recurring_interval === 'month' ? 'monthly' : 'annual');
  const [openBillingPopup, setOpenBillingPopup] = useState(false);
  const [openDowngradeConfirm, setOpenDowngradeConfirm] = useState(false);
  const [newRequestedProduct, setNewRequestedProduct] = useState(null);
  const [downgradeProduct, setDowngradeProduct] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPlans());
  }, []);
  const { data: plansData } = useSelector((state) => state.plans);
  const { data: billingData } = useSelector((state) => state.billing);
  const { subscriptionPlans, addonPlans } = plansData;

  const initiateSubscriptionPurchase = async (productId, period) => {
    if (!billingData || !Object.keys(billingData)) {
      setOpenBillingPopup(true);
      setNewRequestedProduct({ productId, period });
      return message.info('Please add billing details.');
    }
    const response = await crudData(billingURL, '/checkout/subscription', 'POST', {}, {}, { productId, period }).then((res) => res.json());
    if (!response.success) {
      return message.error(response.message);
    }
    const { sessionURL } = response.data;
    window.location.href = sessionURL;
  };

  const initiateAddonPurchase = async (productId, quantity) => {
    const response = await crudData(billingURL, '/checkout/addon', 'POST', {}, {}, { productId, quantity }).then((res) => res.json());
    if (!response.success) {
      return message.error(response.message);
    }
    const { sessionURL } = response.data;
    window.location.href = sessionURL;
  };

  const downgradeSubsriptionPlan = async (productId, period) => {
    setDowngradeProduct({ productId, period });
    setOpenDowngradeConfirm(true);
  };
  const changePlanPeriod = (e) => {
    setSelectedPeriod(e.target.value);
  };

  const displayPlans = subscriptionPlans?.[selectedPeriod] || [];
  return (
    <>
      <div className="flex flex-col p-2 text-start h-full w-full">
        <div className="w-full flex justify-between px-4">
          <p className="text-2xl font-semibold">Plans</p>
          <Radio.Group value={selectedPeriod} buttonStyle="solid" className="my-4" onChange={changePlanPeriod}>
            <Radio.Button value="monthly">Monthly</Radio.Button>
            <Radio.Button value="annual">Annual</Radio.Button>
          </Radio.Group>
        </div>
        <div className="w-full">
          <div className="flex flex-wrap">
            {
                  subscriptionPlans ? (displayPlans.map((plan) => (
                    <PlanCard
                      key={plan.id}
                      planDetails={plan}
                      selectedPeriod={selectedPeriod}
                      initiatePurchase={initiateSubscriptionPurchase}
                      downgradeSubsriptionPlan={downgradeSubsriptionPlan}
                    />
                  ))) : (
                    <LoadingOutlined
                      style={{
                        fontSize: 50,
                        margin: 100,
                        color: '#1890ff',
                      }}
                      // spin
                    />
                  )
                }
          </div>
        </div>
        <div className="w-full flex flex-col px-4">
          <p className="text-2xl font-semibold">Add-Ons</p>
          {
          addonPlans ? (addonPlans.map((addOnPlan) => (
            <AddOnCard
              key={addOnPlan.id}
              planDetails={addOnPlan}
              initiatePurchase={initiateAddonPurchase}
            />
          ))) : (
            <LoadingOutlined
              style={{
                fontSize: 50,
                margin: 100,
                color: '#1890ff',
              }}
              // spin
            />
          )
        }
        </div>
      </div>
      <BillingPopup visible={openBillingPopup} setVisible={setOpenBillingPopup} newRequestedProduct={newRequestedProduct} />
      {downgradeProduct && <DowngradeConfirm visible={openDowngradeConfirm} setVisible={setOpenDowngradeConfirm} downgradeProduct={downgradeProduct} />}
    </>
  );
}

export default PlanSettings;
