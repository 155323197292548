import {
  Button, Input, Modal,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createCoPilot, updateCoPilot } from '../../../store/copilotsSlice';
import ResourcesEditCoPilot from './ResourcesEditCoPilot';

const { TextArea } = Input;

function EditCoPilot({
  copilotId, copilotName, copilotDescription, copilotDocs, visible, setVisible,
}) {
  const dispatch = useDispatch();
  const [name, setName] = useState(copilotName);
  const [description, setDescription] = useState(copilotDescription);
  const [loading, setLoading] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState(copilotDocs ? [...copilotDocs] : []);
  const [disabled, setDisabled] = useState(true);

  // handle disable such that it is disabled when the name, description, or selectedDocs are not changed are empty
  // const handleEditSaveDisable = () => {
  //   if (name === copilotName
  //     && description === copilotDescription
  //     && selectedDocs.length === copilotDocs.length) {
  //     setDisabled(true);
  //   } else if (name && description && selectedDocs.length > 0) {
  //     setDisabled(true);
  //   } else {
  //     setDisabled(false);
  //   }
  // };

  // useEffect(() => {
  //   handleEditSaveDisable();
  // }, [name, description, selectedDocs]);

  useEffect(() => {
    setName(copilotName);
    setDescription(copilotDescription);
    setSelectedDocs(copilotDocs ? [...copilotDocs] : []);
  }, [copilotName, copilotDescription, copilotDocs]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const SaveEditCoPilot = async () => {
    setLoading(true);
    await dispatch(updateCoPilot({
      id: copilotId,
      name,
      description,
      help_docs: selectedDocs,
    }));
    setLoading(false);
    setVisible(false);
  };

  const docsSelector = (docs) => {
    setSelectedDocs(docs);
  };

  return (
    <Modal
      open={visible}
      title="Edit CoPilot"
      onOk={SaveEditCoPilot}
      onCancel={handleCancel}
      style={{
        top: 10,
      }}
      width={1600}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={SaveEditCoPilot} disabled={disabled}>
          Save Changes
        </Button>,
      ]}
    >
      <div className="h-full">
        <div className="flex items-center justify-between px-2">
          <div className="flex items-center w-1/4">
            <div className="mr-2 font-semibold">Name:</div>
            <div className="w-full">
              <Input value={name} onChange={handleNameChange} placeholder="Name of your chatbot" />
            </div>
          </div>
          <div className="flex items-center w-2/3">
            <div className="mr-2 font-semibold">Description:</div>
            <div className="w-2/3">
              <TextArea
                rows={2}
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Description of your chatbot"
                style={{
                  height: 50,
                  resize: 'none',
                }}
                maxLength={100}
              />
            </div>
          </div>
        </div>
        <ResourcesEditCoPilot selectedDocs={selectedDocs} docsSelector={docsSelector} />
      </div>
    </Modal>
  );
}

export default EditCoPilot;
