import {
  Layout, Dropdown, Avatar, Popover, Button, Badge,
} from 'antd';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useState } from 'react';
// import { ReactComponent as Notifications } from '../../assets/icons/notifications-black.svg';
import { useSelector } from 'react-redux';
import {
  CustomerServiceOutlined, FlagOutlined, InfoCircleOutlined, LikeOutlined, PlayCircleOutlined, RocketOutlined,
} from '@ant-design/icons';
import AgentProfileDrawer from './AgentProfileDrawer';
import FeedbackModal from './FeedbackModal';
import SupportModal from './SupportModal';
import '../../App.css';

const { Header } = Layout;

function HeaderBar({
  setOpenGuidedTour, onboardingComplete, setOpenOnboardingModal, openSupportModal, setOpenSupportModal, setOpenOnboardingVideo,
}) {
  const navData = useSelector((state) => state.nav);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const closeDrawer = () => {
    setOpenDrawer(false);
  };
  const items = [
    // {
    //   key: '1',
    //   label: (
    //     <a target="_blank" rel="noopener noreferrer" href="https://www.telepilot.ai/docs">
    //       Documentation
    //     </a>
    //   ),
    // },
    {
      key: '2',
      label: (
        <Button icon={<CustomerServiceOutlined />} type="link" onClick={() => setOpenSupportModal(true)}>
          Support
        </Button>
      ),
    },
    {
      key: '3',
      label: (
        <Button icon={<LikeOutlined />} type="link" onClick={() => setOpenFeedbackModal(true)}>
          Feedback
        </Button>
      ),
    },
    {
      key: '4',
      label: (
        <Button icon={<FlagOutlined />} type="link" onClick={() => setOpenGuidedTour(true)}>
          Guided Tour
        </Button>
      ),
    },
    {
      key: '5',
      label: (
        <Button icon={<PlayCircleOutlined />} type="link" onClick={() => setOpenOnboardingVideo(true)}>
          Getting Started Tutorial
        </Button>
      ),
    },
  ];

  return (
    <Header
      className="flex items-center justify-between bg-white"
    >
      <div className="flex items-center justify-end cursor-default opacity-60 hover:opacity-80">
        {navData && navData.title && (
        <>
          <span className="text-2xl font-bold">{navData.title}</span>
          <Popover content={navData.about} title={`About ${navData.title}`} placement="bottomLeft" overlayStyle={{ maxWidth: '40%' }}>
            <InfoCircleOutlined className="text-2xl mx-4 mt-2" />
          </Popover>
        </>
        )}
      </div>
      <div className="flex justify-end items-center mx-2">
        {onboardingComplete === false && (
        <div className="text-yellow-300">
          <Badge dot>
            <Button icon={<RocketOutlined />} className="shadow-pulse text-yellow-800" onClick={() => setOpenOnboardingModal(true)}>
              Complete Setup
            </Button>
          </Badge>
        </div>
        )}
        <div className="flex items-center">
          <Dropdown menu={{ items }} placement="bottom" arrow mouseLeaveDelay={0.7}>
            <HelpOutlineIcon className="text-black h-full cursor-pointer mx-8" />
          </Dropdown>
        </div>
        <div className="flex items-center justify-center">
          {/* {agent && agent.profilePicture ? (
            <Avatar
              style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
              }}
              src={agent.profilePicture}
              onClick={showDrawer}
            />
          ) : ( */}
          <Avatar
            className="cursor-pointer items-center justify-center flex bg-accent1"
            icon={<PersonOutlineIcon />}
            onClick={showDrawer}
          />
          {/* )} */}
        </div>
        <AgentProfileDrawer
          visible={openDrawer}
          onClose={closeDrawer}
        />
      </div>
      <FeedbackModal visible={openFeedbackModal} setVisible={setOpenFeedbackModal} />
      <SupportModal visible={openSupportModal} setVisible={setOpenSupportModal} />
    </Header>
  );
}

export default HeaderBar;
