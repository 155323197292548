import {
  Popover, Button, Space, Table, Tag,
} from 'antd';
import { EllipsisOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { messageTimeStampFormat } from '../../utils/dateTimeFormat';

function LeadsContactsTable({
  contacts, selectedRowKeys, setSelectedRowKeys, confirmDeletion, confirmEdit,
}) {
  const [data, setData] = useState(contacts ? [...contacts] : []);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      editable: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      editable: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      editable: true,
    },
    // {
    //   title: 'Address',
    //   dataIndex: 'address',
    //   key: 'address',
    //   editable: true,
    // },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (tags) => (
        <div>
          {tags && tags.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </div>
      ),
      editable: true,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      editable: true,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => messageTimeStampFormat(date).currentFormat,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 90,
      render: (_, record) => (
        <Space>
          <Popover
            content={(
              <div className="flex flex-col">
                <Button type="text" className="flex items-center justify-start" icon={<EditOutlined />} onClick={() => (confirmEdit(record))}>
                  Edit
                </Button>
                <Button danger type="text" icon={<DeleteOutlined />} onClick={() => confirmDeletion([record.id])}>
                  Delete
                </Button>
              </div>
                      )}
            // trigger="click"
            placement="leftTop"
          >
            <Button type="text" icon={<EllipsisOutlined />} />
          </Popover>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setData([...contacts]);
  }, [contacts]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Table
      rowSelection={rowSelection}
      dataSource={data}
      rowKey={(record) => record.id}
      columns={columns}
      bordered
      pagination={false}
      scroll={{
        y: 530,
        x: 1300,
      }}
    />
  );
}

export default LeadsContactsTable;
