import { Button, Card, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO, differenceInMonths } from 'date-fns';
import { updateSubscription } from '../../../store/subscriptionSlice';

function CurrentPlanCard({
  setSelectedTab,
  setOpenCancellationConfirm,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [nextCharge, setNextCharge] = useState('Free Forever');
  const [subscriptionAmount, setSubscriptionAmount] = useState('$0');
  const { data, status, updateSubscriptionStatus } = useSelector((state) => state.subscription);
  const { data: usageLimit } = useSelector((state) => state.usageLimit);

  const disableScheduleDeactivation = async () => {
    setLoading(true);
    const cancellationObj = {
      cancel_at_period_end: false,
    };
    await dispatch(updateSubscription(cancellationObj));
    if (updateSubscriptionStatus === 'success') {
      setLoading(false);
      message.success('Subscription reactivated.');
    }
  };
  const updateChargeDetails = () => {
    let updatedNextCharge = 'Free Forever';
    let amount = '0';
    let currencySymbol = '$';
    if (data && Object.keys(data).length > 0) {
      if (data.Price.currency !== 'USD') {
        currencySymbol = data.Price.currency;
      }
      amount = data.Price.unit_amount;
      if (data.cancel_at_period_end) {
        updatedNextCharge = `None. Current plan ends on ${format(parseISO(data.cancel_at), 'MMMM dd, yyyy')}`;
      } else {
        const nextChargeDate = format(parseISO(data.current_period_end), 'MMMM dd, yyyy');
        const diffValidTillCurrentEnd = differenceInMonths(parseISO(usageLimit.validTill), parseISO(data.current_period_end));
        if (diffValidTillCurrentEnd > 1) {
          updatedNextCharge = `${currencySymbol}0 on ${nextChargeDate}`;
        } else {
          updatedNextCharge = `${currencySymbol}${amount} on ${nextChargeDate}`;
        }
      }
    }
    setNextCharge(updatedNextCharge);
    setSubscriptionAmount(`${currencySymbol} ${amount}`);
  };

  useEffect(() => {
    updateChargeDetails();
  }, [data, usageLimit]);

  return (
    <Card
      style={{
        width: 450, height: 285,
      }}
      className="border border-solid border-gray-100"
      loading={status === 'pending'}
    >
      <div className="flex flex-col justify-between h-56">
        <div className="flex flex-col justify-around h-4/6">
          <div className="flex items-center w-full justify-between">
            <span className="font-semibold">Plan Name:</span>
            <span className="w-3/4">{data ? data.Product?.name : 'Free'}</span>
          </div>
          <div className="flex items-start justify-between">
            <span className="font-semibold">Plan Pricing:</span>
            <div className="flex flex-col h-fit items-start justify-between w-3/4">
              <span>{subscriptionAmount}</span>
              {/*  eslint-disable-next-line no-nested-ternary */}
              {(data && !data.cancel_at_period_end) ? data.Price?.recurring_interval === 'month'
                ? <Button className="p-0" type="link" onClick={() => setSelectedTab('plans')}>switch to annual and save more than 16%</Button>
                : <Button className="p-0" type="link" onClick={() => setSelectedTab('plans')}>switch to monthly plan</Button> : ''}
            </div>
          </div>
          <div className="flex items-start justify-between">
            <span className="font-semibold">Next Charge:</span>
            <div className="flex flex-col h-fit items-start w-3/4">
              {nextCharge}
            </div>
          </div>
        </div>
        <div>
          {/* eslint-disable-next-line no-nested-ternary */}
          {data
            ? data.cancel_at_period_end
              ? <Button loading={loading} onClick={disableScheduleDeactivation}>Disable Scheduled Cancellation</Button>
              : <Button onClick={() => setOpenCancellationConfirm(true)}>Cancel Subscription</Button>
            : 'Subscribe to a paid plan today for getting the best out of TelePilot AI.'}
          <Button type="link" onClick={() => setSelectedTab('plans')}>View all plans</Button>
        </div>

      </div>
    </Card>
  );
}

export default CurrentPlanCard;
