import {
  Button, Input, Result, Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCoPilot } from '../../store/copilotsSlice';
import { getAllHelpDocs } from '../../store/helpCMSSlice';

const { TextArea } = Input;

function OnboardingCreateCoPilot({ copilotCreated, setCopilotCreated }) {
  const dispatch = useDispatch();
  const { data: usageCounter } = useSelector((state) => state.usageCounter);
  const { helpDocs } = useSelector((state) => state.helpCMS);

  useEffect(() => {
    dispatch(getAllHelpDocs());
  }, []);

  useEffect(
    () => {
      if (usageCounter?.copilots > 0) {
        setCopilotCreated(true);
      }
    },
    [usageCounter],
  );

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const createNewCoPilot = async () => {
    const selectedDocs = helpDocs.map((doc) => doc.id);
    await dispatch(createCoPilot({
      name,
      description,
      help_docs: selectedDocs,
    }));
    setCopilotCreated(true);
  };

  return (
    <div className="relative">
      <div className="my-16">
        <span className="text font-semibold">Give a Name and Description to your CoPilot</span>
        <div className="flex items-center justify-between my-8">
          <div className="flex items-center w-1/3 mr-4">
            <div className="mr-2 font-semibold">Name:</div>
            <div className="w-full">
              <Input value={name} onChange={handleNameChange} placeholder="Name of your chatbot" />
            </div>
          </div>
          <div className="flex items-center w-1/2 mr-4">
            <div className="mr-2 font-semibold">Description:</div>
            <div className="w-full">
              <TextArea
                rows={2}
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Description of your chatbot"
                style={{
                  height: 50,
                  resize: 'none',
                }}
                maxLength={100}
              />
            </div>
          </div>
          <Tooltip title={(name && description) ? null : 'Please provide both name and a short description for your new CoPilot'}>
            <Button type="primary" disabled={!name || !description} onClick={createNewCoPilot}>
              Create CoPilot
            </Button>
          </Tooltip>
        </div>
      </div>
      {copilotCreated && (
      <div className="absolute inset-0 flex items-center justify-center z-10 cursor-default bg-white">
        <Result
          status="success"
          title="CoPilot created successfully"
          subTitle="You have created a copilot, goto next step to add it to your website."
        />
      </div>
      )}
    </div>
  );
}

export default OnboardingCreateCoPilot;
