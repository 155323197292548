import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { baseURL } from '../config';

export const getUsageLimit = createAsyncThunk('usageLimit/getUsageLimit', async () => {
  const response = await crudData(baseURL, '/accounts/usage-limits', 'GET');
  const { data } = await response.json();
  return data;
});

const initialState = {
  data: {},
  status: requestStatuses.IDLE,
};

const usageLimitSlice = createSlice({
  name: 'usageLimit',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUsageLimit.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getUsageLimit.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.data = action.payload;
      })
      .addCase(getUsageLimit.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      });
  },
});

export default usageLimitSlice.reducer;
