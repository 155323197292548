/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Input, Typography } from 'antd';
import { useState, useRef, useEffect } from 'react';

function EditableTitle({ articleTitle, setArticleTitle }) {
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef(null);
  const h4Ref = useRef(null);

  const handleClickOutside = (event) => {
    if (h4Ref.current && !h4Ref.current.contains(event.target)) {
      setIsEditing(false);
    }
    if (articleTitle.length === 0) {
      setArticleTitle('Untitled Document');
    }
  };

  useEffect(() => {
    if (isEditing) {
      // If in editing mode, focus the input
      inputRef.current.focus();
    }

    // Add event listener to detect click outside
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup the listener when component is unmounted or if re-rendered
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]);

  return (
    <div className="w-full">
      {isEditing ? (
        <Input
          className="text-lg rounded w-full p-2 h-12"
          ref={inputRef}
          value={articleTitle}
          onChange={(e) => setArticleTitle(e.target.value)}
          onBlur={() => setIsEditing(false)}
        />
      ) : (
        <Typography className="text-lg rounded w-full overflow-scroll p-2 text-start h-12" ref={h4Ref} onClick={() => setIsEditing(true)}>
          {articleTitle}
        </Typography>
      )}
    </div>
  );
}

export default EditableTitle;
