import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'
import MainRoutes from './routes/MainRoutes';
import { environment, rollbarAccessToken, ssoFrontendURL } from './config';
import { getUserDetails } from './store/userSlice';
import { getUsageLimit } from './store/usageLimitSlice';
import { getUsageCounter } from './store/usageCounterSlice';
import { getOnboarding } from './store/onboardingSlice';

const rollbarConfig = {
  accessToken: rollbarAccessToken,
  environment,
};

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    if (!accessToken) {
      window.location.href = `${ssoFrontendURL}/signin`;
    }
    dispatch(getUserDetails());
    dispatch(getUsageLimit());
    dispatch(getUsageCounter());
    dispatch(getOnboarding());
  }, []);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <div className="App">
          <BrowserRouter>
            <MainRoutes />
          </BrowserRouter>
        </div>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
