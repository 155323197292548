import React from 'react';
import { Avatar, Empty, List } from 'antd';
import { convertToReadableDate } from '../../../utils/dateTimeFormat';

function ConversationsList({ conversations, selectedConversationId, handleSelectConversation }) {
  return (
    <div>
      {conversations && conversations.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={conversations}
          renderItem={(item, index) => (
            <List.Item onClick={() => handleSelectConversation(item.id, index)} className="cursor-pointer">
              <List.Item.Meta
                avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                title={(
                  <div className="flex flex-col">
                    {item.leadsContact ? <span className="font-semibold shadow-none">{item.leadsContact.name}</span> : <span className="font-semibold shadow-none">Anonymous</span>}
                    {item.leadsContact && <span className="text-sm font-normal text-gray-700">{item.leadsContact.email}</span>}
                  </div>
            )}
                description={(
                  <div className="flex flex-col">
                    <span>{convertToReadableDate(item.updatedAt)}</span>
                  </div>
                )}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyItems: 'flex-start',
                  padding: '10px',
                  textAlign: 'left',
                  backgroundColor: selectedConversationId === item.id ? '#E6F4FF' : 'white',
                  borderRadius: '5px',
                }}
              />
            </List.Item>
          )}
        />
      )
        : (
          <Empty
            className="flex flex-col items-center justify-center w-full h-full"
            description={(
              <div className="flex flex-col">
                <p>
                  No conversations available.
                </p>
                <p className="text-xs">
                  No conversations for the CoPilot at the moment.
                </p>
              </div>
  )}
          />
        )}

    </div>
  );
}

export default ConversationsList;
