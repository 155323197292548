const industries = [
  { value: 'Accounting', label: 'Accounting' },
  { value: 'Advertising and Marketing', label: 'Advertising and Marketing' },
  { value: 'Aerospace and Defense', label: 'Aerospace and Defense' },
  { value: 'Agriculture and Farming', label: 'Agriculture and Farming' },
  { value: 'Airlines/Aviation', label: 'Airlines/Aviation' },
  { value: 'Art and Design', label: 'Art and Design' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Banking and Financial Services', label: 'Banking and Financial Services' },
  { value: 'Construction and Real Estate', label: 'Construction and Real Estate' },
  { value: 'Education and Training', label: 'Education and Training' },
  { value: 'Energy and Utilities', label: 'Energy and Utilities' },
  { value: 'Engineering and Architecture', label: 'Engineering and Architecture' },
  { value: 'Entertainment and Media', label: 'Entertainment and Media' },
  { value: 'Environmental Services', label: 'Environmental Services' },
  { value: 'Fashion and Apparel', label: 'Fashion and Apparel' },
  { value: 'Food and Beverage', label: 'Food and Beverage' },
  { value: 'Government and Public Administration', label: 'Government and Public Administration' },
  { value: 'Healthcare and Pharmaceuticals', label: 'Healthcare and Pharmaceuticals' },
  { value: 'Hospitality and Tourism', label: 'Hospitality and Tourism' },
  { value: 'Information Technology', label: 'Information Technology' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Manufacturing and Production', label: 'Manufacturing and Production' },
  { value: 'Music and Performing Arts', label: 'Music and Performing Arts' },
  { value: 'Nonprofit and Social Services', label: 'Nonprofit and Social Services' },
  { value: 'Professional Services (e.g., Legal, Accounting, Consulting)', label: 'Professional Services (e.g., Legal, Accounting, Consulting)' },
  { value: 'Retail and E-commerce', label: 'Retail and E-commerce' },
  { value: 'Research and Development', label: 'Research and Development' },
  { value: 'Software Development and Technology', label: 'Software Development and Technology' },
  { value: 'Sports and Recreation', label: 'Sports and Recreation' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Transportation and Logistics', label: 'Transportation and Logistics' },
  { value: 'Other', label: 'Other' },
];

module.exports = industries;
