import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { baseURL } from '../config';

export const getCoPilotDetails = createAsyncThunk('copilots/getCoPilotDetails', async (id) => {
  const response = await crudData(baseURL, '/copilots/{id}', 'GET', { id }).then((res) => res.json());
  const { data } = await response;
  return data;
});

export const getAllCoPilots = createAsyncThunk('copilots/getAllCoPilots', async () => {
  const response = await crudData(baseURL, '/copilots/all', 'GET');
  const { data } = await response.json();
  return data;
});

export const createCoPilot = createAsyncThunk('copilots/createCoPilot', async (dataObj) => {
  const response = await crudData(baseURL, '/copilots/new', 'POST', {}, {}, dataObj).then((res) => res.json());
  if (!response.success) {
    return message.error(response.message);
  }
  message.success('New CoPilot created successfully');
  return response.data;
});

export const updateCoPilot = createAsyncThunk('copilots/updateCoPilot', async (updationObject) => {
  const response = await crudData(baseURL, '/copilots', 'PUT', { id: updationObject.id }, {}, { ...updationObject });
  const { data } = await response.json();
  return data;
});

export const deleteCoPilot = createAsyncThunk('copilots/deleteCoPilot', async (id) => {
  const response = await crudData(baseURL, '/copilots/{id}', 'DELETE', { id }).then((res) => res.json());
  if (!response.success) {
    return message.error(response.message);
  }
  message.success(response.message);
  return response.data;
});

const initialState = {
  selectedCoPilot: {},
  allCoPilots: [],
  status: requestStatuses.IDLE,
};

const copilotsSlice = createSlice({
  name: 'copilots',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCoPilotDetails.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getCoPilotDetails.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.selectedCoPilot = action.payload;
      })
      .addCase(getCoPilotDetails.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(getAllCoPilots.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getAllCoPilots.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.allCoPilots = action.payload;
      })
      .addCase(getAllCoPilots.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      })
      .addCase(createCoPilot.pending, (state) => {
        state.updateSubscriptionStatus = requestStatuses.LOADING;
      })
      .addCase(createCoPilot.fulfilled, (state, action) => {
        state.updateSubscriptionStatus = requestStatuses.SUCCESS;
        state.allCoPilots = [action.payload, ...state.allCoPilots];
      })
      .addCase(createCoPilot.rejected, (state) => {
        state.updateSubscriptionStatus = requestStatuses.FAILED;
      })
      .addCase(updateCoPilot.pending, (state) => {
        state.updateSubscriptionStatus = requestStatuses.LOADING;
      })
      .addCase(updateCoPilot.fulfilled, (state) => {
        state.updateSubscriptionStatus = requestStatuses.SUCCESS;
      })
      .addCase(updateCoPilot.rejected, (state) => {
        state.updateSubscriptionStatus = requestStatuses.FAILED;
      })
      .addCase(deleteCoPilot.pending, (state) => {
        state.updateSubscriptionStatus = requestStatuses.LOADING;
      })
      .addCase(deleteCoPilot.fulfilled, (state) => {
        state.updateSubscriptionStatus = requestStatuses.SUCCESS;
      })
      .addCase(deleteCoPilot.rejected, (state) => {
        state.updateSubscriptionStatus = requestStatuses.FAILED;
      });
  },
});

export default copilotsSlice.reducer;
