import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import requestStatuses from '../constants/requestStatuses';
import crudData from '../utils/apiService';
import { baseURL } from '../config';

export const getAllConversations = createAsyncThunk('conversations/getAllConversations', async (botId) => {
  try {
    const response = await crudData(baseURL, '/conversations/{botId}', 'GET', { botId });
    const { data } = await response.json();
    return data;
  } catch (error) {
    return message.error(`Failed to load conversations, ${error.message}`);
  }
});

const initialState = {
  allConversations: [],
  status: requestStatuses.IDLE,
};

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllConversations.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(getAllConversations.fulfilled, (state, action) => {
        state.status = requestStatuses.SUCCESS;
        state.allConversations = action.payload;
      })
      .addCase(getAllConversations.rejected, (state) => {
        state.status = requestStatuses.FAILED;
      });
  },
});

export default conversationsSlice.reducer;
