import { Route, Routes } from 'react-router-dom';
// import PrivateRoute from '../components/auth/PrivateRoute';
// import PublicRoute from '../components/auth/PublicRoute';
import NotFoundRoute from './NotFoundRoute';
import UserRoutes from './UserRoutes';
import WelcomeCompanyPage from '../components/pages/WelcomeCompanyPage';
import CheckoutStatusPage from '../components/pages/CheckoutStatusPage';

function MainRoutes() {
  return (
    <Routes>
      <Route path="/welcome-company" element={<WelcomeCompanyPage />} />
      <Route path="/payment" element={<CheckoutStatusPage />} />
      <Route path="/*" element={<UserRoutes />} />
      <Route element={<NotFoundRoute />} />
    </Routes>
  );
}

export default MainRoutes;
