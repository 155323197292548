import lightenHexColor from '../../utils/color';

function MessageBubble({ message, color }) {
  return (
    <div>
      <div className="flex justify-start mb-2 cursor-default">
        <div className="flex flex-col">
          <div className="w-fit text-black text-sm rounded-lg px-2 py-1 my-2 text-start rounded-bl-none" style={{ backgroundColor: '#f6f1e7' }}>
            <p className="">{message}</p>
          </div>
          <span className="text-xs text-gray-500 cursor-default text-start">now</span>

        </div>
      </div>
    </div>
  );
}

export default MessageBubble;
