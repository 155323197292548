/* eslint-disable no-unused-vars */
import {
  CloudDownloadOutlined, DeleteOutlined, EditFilled, EllipsisOutlined,
} from '@ant-design/icons';
import {
  Button, Card, Divider, Popover, Tag, Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import { messageTimeStampFormat } from '../../utils/dateTimeFormat';

function ChatbotCard({
  id, name, description, date, confirmDeletion, handleEditCoPilotParams, handleInstallModal,
}) {
  const { Text, Paragraph } = Typography;
  return (
    <Card
      className="text-start m-1 shadow-lg"
      style={{ width: 250, height: 'fit-content', marginRight: '1rem' }}
    >
      <div>
        <div className="flex justify-end">
          {/* <Text
            className="text-red-950 bg-gray-100 px-1"
            style={{ maxWidth: 350 }}
            ellipsis={{ type }}
          >
            {type}
          </Text> */}
          <Popover
            mouseLeaveDelay={0.5}
            className="cursor-pointer"
            content={(
              <div className="flex flex-col">
                <Button className="text-start" type="text" onClick={() => handleInstallModal({ id, name })} icon={<CloudDownloadOutlined />}>Install</Button>
                <Button className="text-start" type="text" onClick={() => handleEditCoPilotParams(id)} icon={<EditFilled />}>Edit</Button>
                <Button danger className="text-start" type="text" onClick={() => confirmDeletion({ id, name })} icon={<DeleteOutlined />}>Delete</Button>
              </div>
          )}
            placement="bottom"
          >
            <EllipsisOutlined key="ellipsis" />
          </Popover>
        </div>

        <Link to={`/co-pilots/${id}`}>
          <div className="flex flex-col">
            <Text
              className="text-3xl"
              style={{ maxWidth: 350 }}
              ellipsis={{ name }}
            >
              {name}
            </Text>
            <Paragraph
              className="my-1 text-gray"
              style={{ maxWidth: 300 }}
              ellipsis={description ? { rows: 2, expandable: false } : false}
            >
              {description}
            </Paragraph>
          </div>
          <div className="flex items-center">
            <div className="text-xs">
              <span className="text-gray-500">
                Last Updated:
              </span>
              <span className="text-gray-900 ml-1">
                {messageTimeStampFormat(date).currentFormat}
              </span>
            </div>
            <Divider type="vertical" />
            <div>
              <Tag bordered={false} color="green">
                ACTIVE
              </Tag>
            </div>
          </div>
        </Link>
      </div>
    </Card>
  );
}

export default ChatbotCard;
